import {
  SCAFFOLD_PLANKORIENTATION,
  SCAFFOLD_PLANKTYPE,
  SCAFFOLD_STANDARDLENGTH,
} from "../../../enums";

export const BAYWIDTHS = {
  // MM250: 0.25,
  // MM330: 0.33,
  MM500: 0.5,
  MM670: 0.67,
  MM750: 0.75,
  MM1000: 1,
  MM1250: 1.25,
  MM1500: 1.5,
  MM1750: 1.75,
  MM2000: 2,
  MM2250: 2.25,
  MM2500: 2.5,
  MM3000: 3.0,
  MM4000: 4.0,
};

export const LOADINGBAYWIDTHS = {
  // MM250: 0.25,
  // MM330: 0.33,
  MM500: 0.5,
  MM670: 0.67,
  MM750: 0.75,
  MM1000: 1,
  MM1250: 1.25,
  MM1500: 1.5,
  MM1750: 1.75,
  MM2000: 2,
  MM2250: 2.25,
  MM2500: 2.5,
  MM3000: 3.0,
  // MM4000: 4.0,
};

export const BAYLENGTHS = {
  MM150: 0.15,
  MM250: 0.25,
  MM330: 0.33,
  MM500: 0.5,
  MM670: 0.67,
  MM750: 0.75,
  MM1000: 1,
  MM1250: 1.25,
  MM1500: 1.5,
  MM1750: 1.75,
  MM2000: 2,
  MM2250: 2.25,
  MM2500: 2.5,
  MM3000: 3.0,
  MM4000: 4.0,
};

export const LOADINGBAYLENGTHS = {
  MM150: 0.15,
  MM250: 0.25,
  MM330: 0.33,
  MM500: 0.5,
  MM670: 0.67,
  MM750: 0.75,
  MM1000: 1,
  MM1250: 1.25,
  MM1500: 1.5,
  MM1750: 1.75,
  MM2000: 2,
  MM2250: 2.25,
  MM2500: 2.5,
  MM3000: 3.0,
  MM4000: 4.0,
};

export const PLANKWIDTHS = {
  MM250: 0.25,
  MM375: 0.375,
};

export const CONSOLEWIDTHS = {
  MM250: 0.25,
  MM375: 0.375,
  MM500: 0.5,
  MM750: 0.75,
};

export const LOADINGBAYLOADCLASSES = {
  LOADCLASS1: 1,
  LOADCLASS2: 2,
  LOADCLASS3: 3,
  LOADCLASS4: 4,
  LOADCLASS5: 5,
  LOADCLASS6: 6,
};

export const STAIRWIDTHS = {
  MM750: 0.75,
  MM1500: 1.5,
};

export const STAIRLENGTHS = {
  MM2500: 2.5,
  MM3000: 3.0,
};
export const STANDARDLENGTHS = {
  M2: SCAFFOLD_STANDARDLENGTH.M2,
  M3: SCAFFOLD_STANDARDLENGTH.M3,
  M4: SCAFFOLD_STANDARDLENGTH.M4,
};

export const PLANKORIENTATIONS = {
  LONGITUDINAL: SCAFFOLD_PLANKORIENTATION.LONGITUDINAL,
};

export const PLANKTYPES = {
  STEEL: SCAFFOLD_PLANKTYPE.STEEL,
};
