import { createSvgIcon } from "@mui/material";

const RealComponentsOffIcon = createSvgIcon(
  <svg viewBox="0 0 24 24" fill="currentColor">
    <path d="M9.6 1C9.6 0.723858 9.37614 0.5 9.1 0.5H8.5C8.22386 0.5 8 0.723858 8 1V3.25887L6.86658 3.67103L13.478 10.2825L14.5 10.7083V11.3044L16.1 12.9044V10.7411L20 9.32295V16.625L19.6924 16.4969L21.6 18.4044V6C21.6 5.72386 21.3761 5.5 21.1 5.5H20.5C20.2239 5.5 20 5.72386 20 6V7.625L9.6 3.29166V1Z" />
    <path d="M21.6 20.95L20 19.35V21C20 21.2761 20.2239 21.5 20.5 21.5H21.1C21.3761 21.5 21.6 21.2761 21.6 21V20.95Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.2468 18.5968L14.5 13.85V14.3334L9.6 12.2917V8.95001L4.99984 4.34984L4.1 4.67706V3.45001L3.14999 2.5H3C2.72386 2.5 2.5 2.72386 2.5 3V18C2.5 18.2761 2.72386 18.5 3 18.5H3.6C3.87614 18.5 4.1 18.2761 4.1 18V15.375L14.5 19.7083V23C14.5 23.2761 14.7239 23.5 15 23.5H15.6C15.8761 23.5 16.1 23.2761 16.1 23V19.7411L19.2468 18.5968ZM8 12.2589L4.1 13.6771V6.375L8 7.99998V12.2589Z"
    />
    <path d="M1.74169 1.06117L0.46891 2.33395L21.6819 23.547L22.9547 22.2742L1.74169 1.06117Z" />
  </svg>,
  "RealComponents"
);

export default RealComponentsOffIcon;
