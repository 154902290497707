import { createSvgIcon } from "@mui/material";

const GableRoofIcon = createSvgIcon(
  <svg viewBox="0 0 24 24" fill="currentColor">
    <polygon
      points="12,3.5 2,12.5 5,12.5 5,20.5 13,20.5 13,18.5 9,18.5 8.4,18.5 7,18.5 7,10.7 12,6.2 17,10.7 17,18.5 15.8,18.5 
	15,18.5 13,18.5 13,20.5 19,20.5 19,12.5 22,12.5 "
    />
  </svg>,
  "GableRoof"
);

export default GableRoofIcon;
