import { createSvgIcon } from "@mui/material";

const OverhangIcon = createSvgIcon(
  <svg viewBox="0 0 24 24" fill="currentColor">
    <path d="M8.6,15.7h-5l2.8,2.8l-1.3,1.3l-5.1-5l5.1-5.1L6.4,11l-2.8,2.8h5V15.7z" />
    <path d="M24,20.2h-7.9v-3.6H11L24,3.8V20.2z M18,18.3h4.1v-10l-6.6,6.5H18V18.3z" />
  </svg>,
  "Overhang"
);

export default OverhangIcon;
