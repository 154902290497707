import { createSvgIcon } from "@mui/material";

const SelectIcon = createSvgIcon(
  <svg viewBox="0 0 24 24" fill="currentColor">
    <path d="M4.5,2l1.6,19.7l1,0.3l4.7-5.8l7.4-0.4l0.3-1L4.5,2z" />
  </svg>,
  "Select"
);

export default SelectIcon;
