import { createSvgIcon } from "@mui/material";

const KeyBIcon = createSvgIcon(
  <svg viewBox="0 0 24 24">
    <path
      d="M20.4211 2H3.57895C2.70692 2 2 2.70692 2 3.57895V20.4211C2 21.2931 2.70692 22 3.57895 22H20.4211C21.2931 22 22 21.2931 22 20.4211V3.57895C22 2.70692 21.2931 2 20.4211 2Z"
      stroke="currentColor"
      fill="none"
    />
    <path
      d="M11.999 12.5447H9.28174L9.26709 11.2043H11.6401C12.0405 11.2043 12.3799 11.1458 12.6582 11.0286C12.9414 10.9065 13.1562 10.7332 13.3027 10.5085C13.4492 10.2791 13.5225 10.0032 13.5225 9.68091C13.5225 9.32446 13.4541 9.03394 13.3174 8.80933C13.1807 8.58472 12.9707 8.42114 12.6875 8.3186C12.4092 8.21606 12.0527 8.16479 11.6182 8.16479H9.83838V17.364H8V6.69995H11.6182C12.2041 6.69995 12.7266 6.7561 13.1855 6.86841C13.6494 6.98071 14.0425 7.15649 14.3647 7.39575C14.6919 7.63013 14.9385 7.92798 15.1045 8.28931C15.2754 8.65063 15.3608 9.08032 15.3608 9.57837C15.3608 10.0178 15.2559 10.4207 15.0459 10.7869C14.8359 11.1482 14.5259 11.4436 14.1157 11.6731C13.7056 11.9026 13.1953 12.0393 12.585 12.0833L11.999 12.5447ZM11.9185 17.364H8.70312L9.53076 15.9065H11.9185C12.3335 15.9065 12.6802 15.8381 12.9585 15.7014C13.2368 15.5598 13.4443 15.3669 13.5811 15.1228C13.7227 14.8738 13.7935 14.5833 13.7935 14.2512C13.7935 13.9045 13.7324 13.6042 13.6104 13.3503C13.4883 13.0916 13.2954 12.8938 13.0317 12.7571C12.7681 12.6155 12.4238 12.5447 11.999 12.5447H9.93359L9.94824 11.2043H12.6362L13.0537 11.7097C13.6396 11.7292 14.1206 11.8586 14.4966 12.0979C14.8774 12.3372 15.1606 12.6472 15.3462 13.0281C15.5317 13.4089 15.6245 13.8191 15.6245 14.2585C15.6245 14.9373 15.4756 15.5061 15.1777 15.9651C14.8848 16.4241 14.4624 16.7732 13.9106 17.0125C13.3589 17.2468 12.6948 17.364 11.9185 17.364Z"
      fill="currentColor"
    />
  </svg>,
  "KeyB"
);

export default KeyBIcon;
