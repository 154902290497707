export enum ROUTE {
  ACTION = "/action",
  HOME = "/home",
  LOGIN = "/login",
  PROFILE = "/profile",
  PROJECT = "/project",
  PROJECTS = "/projects",
  PROJECTS_PROJECTID = "/projects/:projectId",
  PROJECTS_PROJECTID_SCAFFOLDS_SCAFFOLDID = "/projects/:projectId/scaffolds/:scaffoldId",
  PROJECTS_PROJECTID_TRS_TRSID = "/projects/:projectId/trs/:trsId",
  ROOT = "/",
  SCAFFOLD = "/scaffold",
  SCAFFOLD_SCAFFOLDID = "/scaffold/:scaffoldId",
  SHARE_PROJECTS_PROJECTID = "/share/projects/:projectId",
  SHARE_PROJECTS_PROJECTID_SCAFFOLDS_SCAFFOLDID = "/share/projects/:projectId/scaffolds/:scaffoldId",
  SHARE_PROJECTS_PROJECTID_TRS_TRSID = "/share/projects/:projectId/trs/:trsId",
  SUPERADMIN = "/superadmin",
  TRS = "/trs",
  TRS_TRSID = "/trs/:trsId",
  TUTORIAL = "/tutorial",
}
