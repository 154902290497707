import React, { ChangeEvent, useState } from 'react';
import InputField from './InputField';

export interface MultiNumberInputFieldProps {
  placeholder: string;
  onChange: (numbers: number[]) => void;
  value: string;
  suffix?: string;
}

const MultiNumberInputField: React.FC<MultiNumberInputFieldProps> = ({ placeholder, onChange, value, suffix }) => {

    const [inputValue, setInputValue] = useState(value)
  const handleBlur = (value: string | number ) => {

    const numberArray = value.toString().split(',').map(num => parseFloat(num.trim())).filter(num => !isNaN(num));
    onChange(numberArray);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    value = e.target.value
    setInputValue(value)
    
  }

  return (
    <InputField
    type="text"
    placeholder={placeholder}
    onBlur={handleBlur}
    onChange={handleChange}
    value={inputValue}
    />

  );
};

export default MultiNumberInputField;