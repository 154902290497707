import { StateCreator } from "zustand";
import { UserSlice } from "./user.types";

const createUserSlice: StateCreator<UserSlice, [], [], UserSlice> = (set) => ({
  userEmail: "",
  userFirstName: "",
  userLastName: "",
  userUId: "",
  userAccountType: "",
  userRole: "",
  userExpires: null,
  userPreferences: {
    color: "#FB00FF",
    messagesEnabled: false,
    tutorialEnabled: false,
  },
  userAuthorisation: [],
  userAchievements: [],
  userActions: {
    setUserEmail: (email) => set({ userEmail: email }),
    setUserFirstName: (name) => set({ userFirstName: name }),
    setUserLastName: (name) => set({ userLastName: name }),
    setUserId: (id) => set({ userUId: id }),
    setUserPreferences: (preferences) => set({ userPreferences: preferences }),
    setUserAccountType: (accountType) => set({ userAccountType: accountType }),
    setUserExpires: (expires) => set({ userExpires: expires }),
    setUserRole: (role) => set({ userRole: role }),
    setUserAuthorisation: (authorisation) =>
      set({ userAuthorisation: authorisation }),
    setUserAchievements: (achievements) =>
      set({ userAchievements: achievements }),
    clearUser: () =>
      set({
        userFirstName: "",
        userLastName: "",
        userEmail: "",
        userAuthorisation: [],
      }),
  },
});

export default createUserSlice;
