import {
  BAYLENGTHS,
  BAYWIDTHS,
  CONSOLEWIDTHS,
  LOADINGBAYLENGTHS,
  LOADINGBAYLOADCLASSES,
  LOADINGBAYWIDTHS,
  PLANKORIENTATIONS,
  PLANKTYPES,
  PLANKWIDTHS,
  STAIRLENGTHS,
  STAIRWIDTHS,
  STANDARDLENGTHS,
} from "./constants";
import { SCAFFOLDTYPES } from "./enums";
import Supplier from "../Supplier";
import { TFunction } from "i18next";
import {
  SCAFFOLD_ANCHORDENSITY,
  SCAFFOLD_CORNER,
  SCAFFOLD_LOADCLASS,
  SCAFFOLD_PLANKORIENTATION,
  SCAFFOLD_PLANKTYPE,
  SCAFFOLD_STANDARDLENGTH,
} from "../../../enums";

export class Super9 implements Supplier {
  bayLengths: typeof BAYLENGTHS;
  bayWidths: typeof BAYWIDTHS;
  consoleWidths: typeof CONSOLEWIDTHS;
  loadingBayLengths: typeof LOADINGBAYLENGTHS;
  loadingBayWidths: typeof LOADINGBAYWIDTHS;
  plankOrientations: typeof PLANKORIENTATIONS;
  plankTypes: typeof PLANKTYPES;
  plankWidths: typeof PLANKWIDTHS;
  scaffoldType: SCAFFOLDTYPES;
  stairLengths: typeof STAIRLENGTHS;
  stairWidths: typeof STAIRWIDTHS;
  standardLengths: typeof STANDARDLENGTHS;

  constructor(scaffoldType: SCAFFOLDTYPES = SCAFFOLDTYPES.MODULE) {
    this.bayLengths = BAYLENGTHS;
    this.bayWidths = BAYWIDTHS;
    this.consoleWidths = CONSOLEWIDTHS;
    this.loadingBayLengths = LOADINGBAYLENGTHS;
    this.loadingBayWidths = LOADINGBAYWIDTHS;
    this.plankOrientations = PLANKORIENTATIONS;
    this.plankTypes = PLANKTYPES;
    this.plankWidths = PLANKWIDTHS;
    this.scaffoldType = scaffoldType;
    this.stairLengths = STAIRLENGTHS;
    this.stairWidths = STAIRWIDTHS;
    this.standardLengths = STANDARDLENGTHS;
  }

  getBayLengths = (
    plankType: SCAFFOLD_PLANKTYPE,
    loadClass: SCAFFOLD_LOADCLASS,
    plankWidth?: number
  ) => {
    if (
      plankType === this.plankTypes.ALUMINUM &&
      plankWidth === this.plankWidths.MM300
    ) {
      if (loadClass === SCAFFOLD_LOADCLASS.LOADCLASS6) {
        return [
          this.bayLengths.MM700,
          this.bayLengths.MM1000,
          this.bayLengths.MM1250,
          this.bayLengths.MM1500,
          this.bayLengths.MM1750,
          this.bayLengths.MM2000,
          this.bayLengths.MM2500,
        ];
      } else if (loadClass <= SCAFFOLD_LOADCLASS.LOADCLASS5) {
        return [
          this.bayLengths.MM700,
          this.bayLengths.MM1000,
          this.bayLengths.MM1250,
          this.bayLengths.MM1500,
          this.bayLengths.MM1750,
          this.bayLengths.MM2000,
          this.bayLengths.MM2500,
          this.bayLengths.MM3000,
        ];
      } else {
        return [];
      }
    } else if (
      plankType === this.plankTypes.ALUMINUM &&
      plankWidth === this.plankWidths.MM320
    ) {
      if (loadClass <= SCAFFOLD_LOADCLASS.LOADCLASS4) {
        return [
          this.bayLengths.MM700,
          this.bayLengths.MM1050,
          this.bayLengths.MM1500,
          this.bayLengths.MM2000,
          this.bayLengths.MM2500,
          this.bayLengths.MM3000,
        ];
      } else if (loadClass === SCAFFOLD_LOADCLASS.LOADCLASS5) {
        return [
          this.bayLengths.MM700,
          this.bayLengths.MM1050,
          this.bayLengths.MM1500,
          this.bayLengths.MM2000,
          this.bayLengths.MM2500,
        ];
      } else if (loadClass === SCAFFOLD_LOADCLASS.LOADCLASS6) {
        return [
          this.bayLengths.MM700,
          this.bayLengths.MM1050,
          this.bayLengths.MM1500,
          this.bayLengths.MM2000,
        ];
      } else {
        return [];
      }
    } else if (
      plankType === this.plankTypes.ALUMINUM &&
      plankWidth === this.plankWidths.MM600
    ) {
      if (loadClass <= SCAFFOLD_LOADCLASS.LOADCLASS3) {
        return [
          this.bayLengths.MM700,
          this.bayLengths.MM1000,
          this.bayLengths.MM1250,
          this.bayLengths.MM1500,
          this.bayLengths.MM1750,
          this.bayLengths.MM2000,
          this.bayLengths.MM2500,
          this.bayLengths.MM3000,
        ];
      } else {
        return [];
      }
    } else if (
      plankType === this.plankTypes.ALUMINUM &&
      plankWidth === this.plankWidths.MM610
    ) {
      if (loadClass <= SCAFFOLD_LOADCLASS.LOADCLASS3) {
        return [
          this.bayLengths.MM700,
          this.bayLengths.MM1050,
          this.bayLengths.MM1500,
          this.bayLengths.MM2000,
          this.bayLengths.MM2500,
          this.bayLengths.MM3000,
        ];
      } else if (loadClass === SCAFFOLD_LOADCLASS.LOADCLASS4) {
        return [
          this.bayLengths.MM700,
          this.bayLengths.MM1050,
          this.bayLengths.MM1500,
          this.bayLengths.MM2000,
          this.bayLengths.MM2500,
        ];
      } else if (loadClass >= SCAFFOLD_LOADCLASS.LOADCLASS5) {
        return [
          this.bayLengths.MM700,
          this.bayLengths.MM1050,
          this.bayLengths.MM1500,
          this.bayLengths.MM2000,
        ];
      } else {
        return [];
      }
    } else if (plankType === this.plankTypes.STEEL) {
      if (loadClass === SCAFFOLD_LOADCLASS.LOADCLASS6) {
        return [
          this.bayLengths.MM700,
          this.bayLengths.MM1000,
          this.bayLengths.MM1250,
          this.bayLengths.MM1500,
          this.bayLengths.MM1750,
          this.bayLengths.MM2000,
        ];
      } else if (loadClass === SCAFFOLD_LOADCLASS.LOADCLASS5) {
        return [
          this.bayLengths.MM700,
          this.bayLengths.MM1000,
          this.bayLengths.MM1250,
          this.bayLengths.MM1500,
          this.bayLengths.MM1750,
          this.bayLengths.MM2000,
          this.bayLengths.MM2500,
        ];
      } else if (loadClass <= SCAFFOLD_LOADCLASS.LOADCLASS4) {
        return [
          this.bayLengths.MM700,
          this.bayLengths.MM1000,
          this.bayLengths.MM1250,
          this.bayLengths.MM1500,
          this.bayLengths.MM1750,
          this.bayLengths.MM2000,
          this.bayLengths.MM2500,
          this.bayLengths.MM3000,
        ];
      }
      {
        return [];
      }
    } else {
      return [];
    }
  };

  getBayWidths = () => Object.values(this.bayWidths);

  getLoadClasses = () => [
    SCAFFOLD_LOADCLASS.LOADCLASS1,
    SCAFFOLD_LOADCLASS.LOADCLASS2,
    SCAFFOLD_LOADCLASS.LOADCLASS3,
    SCAFFOLD_LOADCLASS.LOADCLASS4,
    SCAFFOLD_LOADCLASS.LOADCLASS5,
    SCAFFOLD_LOADCLASS.LOADCLASS6,
  ];

  getLoadingBayLengths = () => Object.values(LOADINGBAYLENGTHS);

  getLoadingBayWidths = () => Object.values(LOADINGBAYWIDTHS);

  getAnchorDensities = () => [
    SCAFFOLD_ANCHORDENSITY.EVERY_FOUR,
    SCAFFOLD_ANCHORDENSITY.EVERY_THREE,
    SCAFFOLD_ANCHORDENSITY.EVERY_TWO,
  ];

  getStandardLengths = () => [
    SCAFFOLD_STANDARDLENGTH.M4,
    SCAFFOLD_STANDARDLENGTH.M3,
    SCAFFOLD_STANDARDLENGTH.M2,
  ];

  getConsoleWidths = () => Object.values(CONSOLEWIDTHS);

  getLoadingBayLoadClasses = () => Object.values(LOADINGBAYLOADCLASSES);

  getStairWidths = () => Object.values(STAIRWIDTHS);

  getStairLengths = () => Object.values(STAIRLENGTHS);

  getBayPlankConfiguration = () => this.calculatePlankConfiguration();

  getLoadingBayPlankConfiguration = () => this.calculatePlankConfiguration();

  getConsolePlankConfiguration = () => this.calculatePlankConfiguration();

  private calculatePlankConfiguration = () => {
    return [];
  };

  getCornerConfigurations = () => {
    return [
      SCAFFOLD_CORNER.ABOVE_BAY,
      SCAFFOLD_CORNER.ABOVE_BAY_EXTRA,
      SCAFFOLD_CORNER.IN_BAY,
      SCAFFOLD_CORNER.IN_BAY_EXTRA,
    ];
  };

  getPlankingOptions = (t: TFunction) => {
    return [
      {
        value: this.plankTypes.STEEL + this.plankWidths.MM290,
        material: this.plankTypes.STEEL,
        label: t("options.plankingtype.super9.steelplank"),
        width: this.plankWidths.MM290,
        orientation: SCAFFOLD_PLANKORIENTATION.LONGITUDINAL,
      },
      {
        value: this.plankTypes.ALUMINUM + this.plankWidths.MM300,
        material: this.plankTypes.ALUMINUM,
        label: `${t("options.plankingtype.super9.aluminiumplank")} ${
          this.plankWidths.MM300
        }m`,
        width: this.plankWidths.MM300,
        orientation: SCAFFOLD_PLANKORIENTATION.LONGITUDINAL,
      },
      {
        value: this.plankTypes.ALUMINUM + this.plankWidths.MM320,
        material: this.plankTypes.ALUMINUM,
        label: `${t("options.plankingtype.super9.aluminiumplank")} ${
          this.plankWidths.MM320
        }m`,
        width: this.plankWidths.MM320,
        orientation: SCAFFOLD_PLANKORIENTATION.LONGITUDINAL,
      },
      {
        value: this.plankTypes.ALUMINUM + this.plankWidths.MM600,
        material: this.plankTypes.ALUMINUM,
        label: `${t("options.plankingtype.super9.aluminiumplank")} ${
          this.plankWidths.MM600
        }m`,
        width: this.plankWidths.MM600,
        orientation: SCAFFOLD_PLANKORIENTATION.LONGITUDINAL,
      },
      {
        value: this.plankTypes.ALUMINUM + this.plankWidths.MM610,
        material: this.plankTypes.ALUMINUM,
        label: `${t("options.plankingtype.super9.aluminiumplank")} ${
          this.plankWidths.MM610
        }m`,
        width: this.plankWidths.MM610,
        orientation: SCAFFOLD_PLANKORIENTATION.LONGITUDINAL,
      },
    ];
  };
}

export default Super9;
