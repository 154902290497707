import { FC } from "react";
import { Fab, Step, StepButton, Stepper, SxProps } from "@mui/material";

export interface StepperInterface {
  activeStep: number;
  onChangeStep: (step: number) => void;
  steps: {
    title: string;
    index: number;
  }[];
  disableSteps?: number[];
  onClickCheckDisabled?: () => void;
  sx?: SxProps;
}

const CustomStepper: FC<StepperInterface> = (props) => {
  const {
    activeStep,
    onChangeStep,
    steps,
    disableSteps,
    onClickCheckDisabled,
    sx,
  } = props;

  return (
    <Stepper
      alternativeLabel
      activeStep={activeStep}
      nonLinear
      onClick={onClickCheckDisabled}
      sx={{
        width: "100%",
        position: "relative",
        ...sx,
      }}
    >
      {steps.map(({ title, index }) => (
        <Step
          id={index === activeStep ? "active-step" : undefined}
          key={index}
          sx={{
            "&#active-step": {
              paddingInline: 2,
            },
            "& .MuiStepLabel-alternativeLabel ": {
              fontSize: "0.75rem ",
              whiteSpace: "nowrap",
              "&.Mui-active": {
                fontSize: "0.875rem ",
              },
            },
            "& .MuiStepConnector-root ": {
              span: {
                borderColor: index > activeStep ? "divider" : "success.main",
                borderWidth: 2,
                width: 64,
              },
              left: -32,
              right: 32,
              top: 16,
            },
          }}
        >
          <StepButton
            disabled={disableSteps && disableSteps.includes(index)}
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
            }}
            icon={
              <Fab
                id={`step-button-${index + 1}`}
                disabled={disableSteps && disableSteps.includes(index)}
                color={activeStep === index ? "primary" : "secondary"}
                sx={{
                  backgroundColor: index < activeStep ? "#29405B" : undefined,
                  "&.Mui-disabled": {
                    backgroundColor: "secondary.dark",
                    opacity: 1,
                  },
                  "&:hover": {
                    backgroundColor: index < activeStep ? "#273B54" : undefined,
                  },
                  boxShadow: 2,
                  userSelect: "none",
                  width: "32px",
                  height: "32px",
                  minHeight: "32px",
                }}
              >
                {index + 1}
              </Fab>
            }
            onClick={() => onChangeStep(index)}
          >
            {title}
          </StepButton>
        </Step>
      ))}
    </Stepper>
  );
};

export default CustomStepper;
