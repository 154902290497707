import { FC, useCallback, useEffect, useState } from "react";
import VerifyEmailPageProps, { VERIFY_MODES } from "./VerifyEmailPage.types";
import { api, authentication } from "../../services";
import { useLoadingActions, useSnackbarActions } from "../../store";
import { FirebaseError } from "firebase/app";
import { useNavigate } from "react-router-dom";
import { ActionCodeInfo } from "firebase/auth";
import { Button } from "@scaffcalc/packages-ui";
import { Stack, Typography } from "@mui/material";
import { ScaffcalcLogoVertical } from "../../assets/graphics";

/**
 *
 * TODO: if link has expired: generate page with expired message and link to resend email
 * TODO: if link is valid: generate page with success message and navigate to set
 */
const VerifyEmailPage: FC<VerifyEmailPageProps> = (props) => {
  const { actionCode } = props;

  /** Hook: snackbar */
  const { setSnackbarOpen, setSnackbarType, setSnackbarText } =
    useSnackbarActions();

  /** Hook: navigate */
  const navigate = useNavigate();

  /** Hook: store loadingActions */
  const { setLoading } = useLoadingActions();

  /** State: verify mode  */
  const [verifyMode, setVerifyMode] = useState<VERIFY_MODES>(
    VERIFY_MODES.RESEND_LINK
  );

  const handleFirebaseError = useCallback(
    (error: FirebaseError) => {
      setLoading(false);
      if (error.code === "auth/expired-action-code") {
        setSnackbarType("negative");
        setSnackbarText("Verify link has expired");
        setSnackbarOpen(true);
        setVerifyMode(VERIFY_MODES.RESEND_LINK);
      } else {
        setSnackbarType("negative");
        setSnackbarText("Invalid verify email link");
        setSnackbarOpen(true);
        setVerifyMode(VERIFY_MODES.RESEND_LINK);
      }
    },
    [setLoading, setSnackbarOpen, setSnackbarText, setSnackbarType]
  );

  const handleOnClickButton = useCallback(() => {
    window.open("https://www.scaffcalc.com/support", "_blank");
  }, []);

  useEffect(() => {
    setLoading(true);
    authentication
      .checkActionCode(actionCode)
      .then((info: ActionCodeInfo) => {
        setSnackbarOpen(true);
        setSnackbarText("Successfully verified email");
        setSnackbarType("positive");
        const userEmail = info.data.email;
        if (!userEmail) {
          navigate("/login");
          return;
        }
        api.sendPasswordResetLink(userEmail);
        setVerifyMode(VERIFY_MODES.PASSWORD_LINK_SENT);
      })
      .catch((error: FirebaseError) => {
        handleFirebaseError(error);
      });
    setLoading(false);
  }, [
    actionCode,
    handleFirebaseError,
    navigate,
    setLoading,
    setSnackbarOpen,
    setSnackbarText,
    setSnackbarType,
  ]);

  return (
    <Stack gap={3} width={310}>
      <ScaffcalcLogoVertical width={310} style={{ marginBottom: 32 }} />
      {verifyMode === VERIFY_MODES.RESEND_LINK && (
        <>
          <Typography variant="h6" textAlign={"center"}>
            {
              "The verify email link has expired or is invalid. Please contact us"
            }
          </Typography>

          <Button
            text={"Contact us"}
            onClick={handleOnClickButton}
            color="blue"
          />
        </>
      )}
      {verifyMode === VERIFY_MODES.PASSWORD_LINK_SENT && (
        <>
          <Typography variant="h6" textAlign={"center"}>
            {"Success! Your email has been verified ✅ "}
          </Typography>
          <Typography variant="subtitle1" textAlign={"center"}>
            {"Check your inbox for a set password link"}
          </Typography>
        </>
      )}
    </Stack>
  );
};

export default VerifyEmailPage;
