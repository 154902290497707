import { createSvgIcon } from "@mui/material";

const ShedRoofIcon = createSvgIcon(
  <svg viewBox="0 0 24 24" fill="currentColor">
    <path
      id="home-outline-2"
      d="M17,10.6L17,10.6v7.8H7v-6.5l10-4.8 M19,3.6l-14,7v9.8h14v-8l0,0V3.6z"
    />
  </svg>,
  "ShedRoof"
);

export default ShedRoofIcon;
