import { FC, useCallback } from "react";
import { FilterCenterFocus, CameraAlt } from "@mui/icons-material";
import {
  BlueprintIcon,
  ForceAnchorsIcon,
  ForceLoadsIcon,
  GridIcon,
  HouseIcon,
  RealComponentsIcon,
  RoofIcon,
  RulerIcon,
} from "../assets/icons";
import { Stack, ButtonBase, Divider } from "@mui/material";
import ToolTip from "./ToolTip";
import CircularProgress from "./CircularProgress";
import { DRAW_RENDERMODE } from "@scaffcalc/packages-shared";
import { useTranslation } from "react-i18next";

export interface VerticalToolBarProps {
  onClick: (e: string, is: boolean | null) => void;
  grid?: boolean;
  axis?: boolean;
  house?: boolean;
  roof?: boolean;
  roofUnmounted?: boolean;
  blueprint?: boolean;
  dimensions?: boolean;
  snapToGrid?: boolean;
  mode?: "draw" | "view" | "trs";
  enableDebug?: boolean;
  debug?: boolean;
  showAnchorLoads?: boolean;
  showStandardLoads?: boolean;
  realRenderDisabled?: boolean;
  standardAnalysisDisabled?: boolean;
  anchorAnalysisDisabled?: boolean;
  isLoadingAnchorAnalysis?: boolean;
  isLoadingStandardAnalysis?: boolean;
  renderMode?: DRAW_RENDERMODE;
}

const ToolButton: FC<{
  tool: string;
  toolActive: boolean | undefined;
  icon: JSX.Element;
  id?: string;
  handleOnClick: (id: string, is: boolean | null) => void;
  disabled?: boolean;
  isLoading?: boolean;
  tippyText?: string;
}> = (props) => {
  const {
    tool,
    toolActive,
    icon,
    id,
    handleOnClick,
    disabled,
    isLoading,
    tippyText,
  } = props;

  return (
    <ToolTip content={tippyText} placement="left">
      {/* Extra stack because of posibility of button being disabled */}
      <Stack id={id}>
        <ButtonBase
          id={tool}
          onClick={() => handleOnClick(tool, !toolActive)}
          disabled={disabled}
          sx={{
            borderRadius: 1,
            padding: 0.5,
            backgroundColor: toolActive ? "action.selected" : "none",
            "&:hover": {
              backgroundColor: toolActive ? "action.selected" : "action.hover",
            },
            boxShadow: toolActive
              ? "inset 0 0 2px rgba(255, 255, 255, 0.4)"
              : "none",
          }}
        >
          {icon}
          {isLoading && (
            <CircularProgress
              size="20px"
              style={{ color: "text.primary", position: "absolute" }}
            />
          )}
        </ButtonBase>
      </Stack>
    </ToolTip>
  );
};

const SceneIcons = ({
  onClick,
  grid,
  axis,
  house,
  roof,
  roofUnmounted,
  blueprint,
  dimensions,
  mode = "draw",
  enableDebug,
  debug = false,
  realRenderDisabled,
  standardAnalysisDisabled,
  anchorAnalysisDisabled,
  isLoadingAnchorAnalysis,
  isLoadingStandardAnalysis,
  showAnchorLoads,
  showStandardLoads,
  renderMode,
}: VerticalToolBarProps) => {
  const { t } = useTranslation();

  const handleOnClick = useCallback(
    (id: string, is: boolean | null) => {
      if (onClick) onClick(id, is);
    },
    [onClick]
  );

  return (
    <>
      {mode === "draw" && (
        <ToolButton
          tool={"grid"}
          toolActive={grid}
          icon={<GridIcon />}
          handleOnClick={handleOnClick}
          tippyText={t("tools.grid")}
        />
      )}
      {mode !== "trs" && (
        <>
          <ToolButton
            tool={"house"}
            toolActive={house}
            icon={<HouseIcon />}
            handleOnClick={handleOnClick}
            tippyText={t("tools.house")}
          />
          <ToolButton
            tool={"roof"}
            toolActive={roofUnmounted ? false : roof}
            icon={
              <RoofIcon
                sx={{
                  color:
                    !house || roofUnmounted ? "text.disabled" : "text.primary",
                }}
              />
            }
            handleOnClick={handleOnClick}
            disabled={!house || roofUnmounted}
            tippyText={t("tools.roof")}
          />
        </>
      )}
      {mode === "draw" && (
        <ToolButton
          tool={"blueprint"}
          toolActive={blueprint}
          icon={<BlueprintIcon />}
          handleOnClick={handleOnClick}
          tippyText={t("tools.blueprint")}
        />
      )}
      <ToolButton
        tool={"dimensions"}
        toolActive={dimensions}
        icon={<RulerIcon />}
        handleOnClick={handleOnClick}
        tippyText={t("tools.dimensions")}
      />
      {mode !== "trs" && (
        <>
          <Divider
            sx={{ borderColor: "divider", borderWidth: 1, margin: 0.25 }}
          />
          <ToolButton
            id="toggle-real-button"
            tool={"realRender"}
            toolActive={renderMode === DRAW_RENDERMODE.REAL}
            icon={
              <RealComponentsIcon
                sx={{
                  color: realRenderDisabled ? "text.disabled" : "text.primary",
                }}
              />
            }
            handleOnClick={handleOnClick}
            tippyText={t("tools.realrender")}
            disabled={realRenderDisabled}
          />

          <ToolButton
            tool={"standardAnalysis"}
            toolActive={showStandardLoads}
            icon={
              <ForceLoadsIcon
                sx={{
                  color: standardAnalysisDisabled
                    ? "text.disabled"
                    : isLoadingStandardAnalysis
                    ? "text.icon"
                    : "text.primary",
                }}
              />
            }
            handleOnClick={handleOnClick}
            disabled={standardAnalysisDisabled}
            isLoading={isLoadingStandardAnalysis}
            tippyText={t("tools.standardanalysis")}
          />
          <ToolButton
            tool={"anchorAnalysis"}
            toolActive={showAnchorLoads}
            icon={
              <ForceAnchorsIcon
                sx={{
                  color: anchorAnalysisDisabled
                    ? "text.disabled"
                    : isLoadingAnchorAnalysis
                    ? "text.icon"
                    : "text.primary",
                }}
              />
            }
            handleOnClick={handleOnClick}
            disabled={anchorAnalysisDisabled}
            isLoading={isLoadingAnchorAnalysis}
            tippyText={t("tools.anchorforces")}
          />
        </>
      )}
      <Divider sx={{ borderColor: "divider", borderWidth: 1, margin: 0.25 }} />
      <ToolTip content={t("tools.fittoscreen")} placement="left">
        <ButtonBase
          id="fitToScreen"
          onClick={() => handleOnClick("fitToScreen", null)}
          sx={{
            borderRadius: 1,
            padding: 0.5,
            "&:hover": {
              backgroundColor: "action.hover",
            },
          }}
        >
          <FilterCenterFocus sx={{ color: "text.primary" }} />
        </ButtonBase>
      </ToolTip>
      <ToolTip content={t("tools.snapshot")} placement="left">
        <ButtonBase
          id="snapshot"
          onClick={() => handleOnClick("snapshot", null)}
          sx={{
            borderRadius: 1,
            padding: 0.5,
            "&:hover": {
              backgroundColor: "action.hover",
            },
          }}
        >
          <CameraAlt sx={{ color: "text.primary" }} />
        </ButtonBase>
      </ToolTip>
    </>
  );
};

const VerticalToolBar = ({
  onClick,
  grid,
  axis,
  house,
  roof,
  roofUnmounted,
  blueprint,
  dimensions,
  snapToGrid,
  mode = "draw",
  enableDebug = false,
  debug = false,
  realRenderDisabled,
  standardAnalysisDisabled,
  anchorAnalysisDisabled,
  isLoadingAnchorAnalysis,
  isLoadingStandardAnalysis,
  showAnchorLoads,
  showStandardLoads,
  renderMode,
}: VerticalToolBarProps) => {
  return (
    <Stack
      direction="column"
      alignContent={"center"}
      justifyContent={"center"}
      gap={0.5}
      padding={1}
      bgcolor={"secondary.light"}
      borderRadius={2}
      boxShadow={2}
      sx={{
        userSelect: "none",
      }}
      width={"min-content"}
    >
      <SceneIcons
        onClick={onClick}
        grid={grid}
        axis={axis}
        house={house}
        roof={roof}
        roofUnmounted={roofUnmounted}
        blueprint={blueprint}
        dimensions={dimensions}
        snapToGrid={snapToGrid}
        mode={mode}
        enableDebug={enableDebug}
        debug={debug}
        realRenderDisabled={realRenderDisabled}
        standardAnalysisDisabled={standardAnalysisDisabled}
        anchorAnalysisDisabled={anchorAnalysisDisabled}
        isLoadingAnchorAnalysis={isLoadingAnchorAnalysis}
        isLoadingStandardAnalysis={isLoadingStandardAnalysis}
        showAnchorLoads={showAnchorLoads}
        showStandardLoads={showStandardLoads}
        renderMode={renderMode}
      />
    </Stack>
  );
};

VerticalToolBar.defaultProps = {
  onClick: () => {},
  grid: true,
  axis: true,
  house: true,
  roof: true,
  blueprint: true,
  dimensions: true,
  snapToGrid: true,
  mode: "draw",
};

export default VerticalToolBar;
