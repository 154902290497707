import { createSvgIcon } from "@mui/material";

const BoxIcon = createSvgIcon(
  <svg viewBox="0 0 24 24" fill="currentColor">
    <path
      d="M20.5,6.6l-7.9-4.4C12.4,2.1,12.2,2,12,2s-0.4,0.1-0.6,0.2L3.5,6.6C3.2,6.8,3,7.1,3,7.5v9c0,0.4,0.2,0.7,0.5,0.9l7.9,4.4
	c0.2,0.1,0.4,0.2,0.6,0.2s0.4-0.1,0.6-0.2l7.9-4.4c0.3-0.2,0.5-0.5,0.5-0.9v-9C21,7.1,20.8,6.8,20.5,6.6z M11,19.3l-6-3.4V9.2l6,3.4
	V19.3z M12,10.8L6,7.5l2-1.2l0,0l2.8-1.6l0,0L12,4.2l6,3.3l-1.5,0.9l0,0l0,0L16,8.6l0,0l-3,1.7L12,10.8z M19,15.9l-6,3.4v-6.7l6-3.4
	V15.9z"
    />
  </svg>,
  "Box"
);

export default BoxIcon;
