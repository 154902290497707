/** Libraries */
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { ROUTE } from "../../enums/routingEnums";
import { useRoutingRoutes, useUserRole } from "../../store";
import { USER_ROLE } from "@scaffcalc/backends-firebase";
import { FC, Suspense, lazy, useEffect, useState } from "react";
import ActionPage from "../../pages/ActionPage";
import UpdateNotificationOverlay from "../UpdateNotificationOverlay";

/** Components */
import CenterLayout from "../../layouts/CenterLayout";
import LoadingOverlay from "../LoadingOverlay";
import NavLayout from "../../layouts/NavLayout";
import ProtectedRoute from "../ProtectedRoute";
import SessionManager from "../SessionManager";
import SnackbarOverlay from "../SnackbarOverlay";
import TrialLayout from "../../layouts/TrialLayout";

/** Pages */
import { AccountCircle } from "@mui/icons-material";

const LoginPage = lazy(
  () => import(/* webpackChunkName: "LoginPage" */ "../../pages/LoginPage")
);
const HomePage = lazy(
  () => import(/* webpackChunkName: "HomePage" */ "../../pages/HomePage")
);
const ProfilePage = lazy(
  () => import(/* webpackChunkName: "ProfilePage" */ "../../pages/ProfilePage")
);
const ProjectCreatePage = lazy(
  () =>
    import(
      /* webpackChunkName: "ProjectCreatePage" */ "../../pages/ProjectCreatePage"
    )
);
const ProjectPage = lazy(
  () => import(/* webpackChunkName: "ProjectPage" */ "../../pages/ProjectPage")
);
const ProjectsPage = lazy(
  () =>
    import(/* webpackChunkName: "ProjectsPage" */ "../../pages/ProjectsPage")
);
const ScaffoldPage = lazy(
  () =>
    import(/* webpackChunkName: "ScaffoldPage" */ "../../pages/ScaffoldPage")
);
const ScaffoldViewPage = lazy(
  () =>
    import(
      /* webpackChunkName: "ScaffoldViewPage" */ "../../pages/ScaffoldViewPage"
    )
);
const SharedProjectPage = lazy(
  () =>
    import(
      /* webpackChunkName: "SharedProjectPage" */ "../../pages/SharedProjectPage"
    )
);
const SharedScaffoldPage = lazy(
  () =>
    import(
      /* webpackChunkName: "SharedScaffoldPage" */ "../../pages/SharedScaffoldPage"
    )
);
const SharedTrsPage = lazy(
  () =>
    import(/* webpackChunkName: "SharedTrsPage" */ "../../pages/SharedTrsPage")
);
const SuperAdminPage = lazy(
  () =>
    import(
      /* webpackChunkName: "SuperAdminPage" */ "../../pages/SuperAdminPage"
    )
);
const TrsPage = lazy(
  () => import(/* webpackChunkName: "TrsPage" */ "../../pages/TrsPage")
);
const TrsViewPage = lazy(
  () => import(/* webpackChunkName: "TrsViewPage" */ "../../pages/TrsViewPage")
);
const TutorialPage = lazy(
  () =>
    import(/* webpackChunkName: "TutorialPage" */ "../../pages/TutorialPage")
);

const LazyLoadPage: FC<{ page: JSX.Element }> = (props) => {
  const { page } = props;
  return (
    <Suspense fallback={<LoadingOverlay isLoading loadingText="Loading app" />}>
      {page}
    </Suspense>
  );
};

/** RouteManager
 * This component is responsible for managing the routes of the application.
 *
 * @returns JSX.Element
 */

const RouteManager = () => {
  /** Hooks: routing */
  const routingRoutes = useRoutingRoutes();
  const userRole = useUserRole();

  const [routes, setRoutes] = useState(routingRoutes);

  useEffect(() => {
    if (userRole === USER_ROLE.SUPERADMIN) {
      setRoutes([
        ...routingRoutes,
        {
          title: "Super Admin",
          route: ROUTE.SUPERADMIN,
          icon: AccountCircle,
          id: "superadmin",
        },
      ]);
    } else {
      setRoutes(routingRoutes);
    }
  }, [routingRoutes, userRole]);

  /**
   * Temporary fix to redirect users to Scaffcalc.com if using marketing QR code
   * TODO: remove this code after 2024-06-01
   */
  const currentPath = window.location.pathname;
  if (
    currentPath ===
    "/projects/NNDSfxBvyKIBbZnLLn4m/scaffolds/BUns5aT5BF565zME5K06"
  ) {
    /**Redirect user to scaffcalc.com */
    window.location.href = "https://scaffcalc.com";
  }

  /** Return: Router with conditional routes */
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<LoadingOverlay />}>
          <Route element={<SnackbarOverlay />}>
            <Route element={<UpdateNotificationOverlay />}>
              {/* Authorized routes */}
              <Route path="/" element={<SessionManager />}>
                <Route element={<TrialLayout />}>
                  <Route element={<CenterLayout />}>
                    <Route element={<LazyLoadPage page={<LoginPage />} />} />
                    <Route
                      path={ROUTE.LOGIN}
                      element={<LazyLoadPage page={<LoginPage />} />}
                    />
                  </Route>
                  <Route
                    element={<NavLayout navbarProps={{ items: routes }} />}
                  >
                    <Route
                      path={ROUTE.ROOT}
                      element={<Navigate replace to={ROUTE.HOME} />}
                    />
                    <Route
                      path={ROUTE.HOME}
                      element={<LazyLoadPage page={<HomePage />} />}
                    />
                    <Route
                      path={ROUTE.TUTORIAL}
                      element={<LazyLoadPage page={<TutorialPage />} />}
                    />
                    <Route
                      path={ROUTE.TRS}
                      element={<LazyLoadPage page={<TrsPage />} />}
                    />
                    <Route
                      path={ROUTE.TRS_TRSID}
                      element={<LazyLoadPage page={<TrsPage />} />}
                    />
                    <Route
                      path={ROUTE.SCAFFOLD}
                      element={<LazyLoadPage page={<ScaffoldPage />} />}
                    />
                    <Route
                      path={ROUTE.SCAFFOLD_SCAFFOLDID}
                      element={<LazyLoadPage page={<ScaffoldPage />} />}
                    />
                    <Route
                      path={ROUTE.PROFILE}
                      element={<LazyLoadPage page={<ProfilePage />} />}
                    />
                    <Route
                      path={ROUTE.PROJECTS}
                      element={<LazyLoadPage page={<ProjectsPage />} />}
                    />
                    <Route
                      path={ROUTE.PROJECTS_PROJECTID}
                      element={<LazyLoadPage page={<ProjectPage />} />}
                    />
                    <Route
                      path={ROUTE.PROJECTS_PROJECTID_SCAFFOLDS_SCAFFOLDID}
                      element={<LazyLoadPage page={<ScaffoldViewPage />} />}
                    />
                    <Route
                      path={ROUTE.PROJECTS_PROJECTID_TRS_TRSID}
                      element={<LazyLoadPage page={<TrsViewPage />} />}
                    />
                    <Route
                      path={ROUTE.PROJECT}
                      element={<LazyLoadPage page={<ProjectCreatePage />} />}
                    />
                    <Route
                      path={ROUTE.SUPERADMIN}
                      element={
                        <ProtectedRoute
                          redirectTo={ROUTE.HOME}
                          isAllowed={userRole === USER_ROLE.SUPERADMIN}
                        >
                          <LazyLoadPage page={<SuperAdminPage />} />
                        </ProtectedRoute>
                      }
                    />
                  </Route>
                </Route>
              </Route>
            </Route>
            <Route element={<CenterLayout />}>
              <Route
                path={ROUTE.ACTION}
                element={<LazyLoadPage page={<ActionPage />} />}
              />
            </Route>
            {/* Public routes */}
            <Route
              path={ROUTE.SHARE_PROJECTS_PROJECTID}
              element={<LazyLoadPage page={<SharedProjectPage />} />}
            />
            <Route
              path={ROUTE.SHARE_PROJECTS_PROJECTID_SCAFFOLDS_SCAFFOLDID}
              element={<LazyLoadPage page={<SharedScaffoldPage />} />}
            />
            <Route
              path={ROUTE.SHARE_PROJECTS_PROJECTID_TRS_TRSID}
              element={<LazyLoadPage page={<SharedTrsPage />} />}
            />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default RouteManager;
