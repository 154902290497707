export enum APP_LANGUAGECODE {
  EN = "en",
  SE = "se",
  NO = "no",
  DA = "da",
  DE = "de",
}

export enum APP_ANALYSISTYPE {
  ANCHOR = "anchor",
  STANDARD = "standard",
  INFO = "info",
}

export enum APP_UNITSYSTEM {
  METRIC = "metric",
  IMPERIAL = "imperial",
}

export enum APP_WEIGHTUNIT {
  G = "g",
  HG = "hg",
  KG = "kg",
  LB = "lb",
}

export enum APP_LENGTHUNIT {
  CM = "cm",
  DM = "dm",
  M = "m",
  FT = "ft",
  IN = "in",
}
