import { createSvgIcon } from "@mui/material";

const ScaleIcon = createSvgIcon(
  <svg viewBox="0 0 24 24" fill="currentColor">
    <path
      d="M23,15h-2v2h2V15 M23,11h-2v2h2V11 M23,19h-2v2C22,21,23,20,23,19 M15,3h-2v2h2V3 M23,7h-2v2h2V7 M21,3v2h2C23,4,22,3,21,3
	 M3,21h8v-6H1v4C1,20.1,1.9,21,3,21 M3,7H1v2h2V7 M15,19h-2v2h2V19 M19,3h-2v2h2V3 M19,19h-2v2h2V19 M3,3C2,3,1,4,1,5h2V3 M3,11H1v2
	h2V11 M11,3H9v2h2V3 M7,3H5v2h2V3z"
    />
  </svg>,
  "Scale"
);

export default ScaleIcon;
