import { createSvgIcon } from "@mui/material";

const ForceAnchorsIcon = createSvgIcon(
  <svg viewBox="0 0 24 24" fill="currentColor">
    <path d="M10.6768 2L6.46966 6.2071L10.6768 10.4143L12.091 9.00004L10.2981 7.2071H16.8839V5.2071H10.2981L12.091 3.4143L10.6768 2Z" />
    <path d="M19.3535 12.9611V22.9142H17.6377L13.1738 15.7844V22.9142H11.458V12.9611H13.1738L17.6514 20.1047V12.9611H19.3535Z" />
    <path d="M5.6543 12.4142V22.9142H4V12.4142H5.6543ZM10.248 15.5178L7.02832 19.0861L5.26465 20.8908L4.83398 19.4963L6.16699 17.8488L8.25879 15.5178H10.248ZM8.61426 22.9142L6.21484 19.4416L7.25391 18.2863L10.5215 22.9142H8.61426Z" />
  </svg>,
  "ForceAnchors"
);

export default ForceAnchorsIcon;
