import { createSvgIcon } from "@mui/material";

const HeightIcon = createSvgIcon(
  <svg viewBox="0 0 24 24" fill="currentColor">
    <path
      d="M17.5,17.5L12,23l-5.5-5.5L8,16.1l3,3V4.8l-3,3L6.5,6.4L12,1l5.5,5.4L16,7.9l-3-3v14.3l3-3
	L17.5,17.5z"
    />
  </svg>,
  "Height"
);

export default HeightIcon;
