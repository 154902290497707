import { createSvgIcon } from "@mui/material";

const RotateIcon = createSvgIcon(
  <svg viewBox="0 0 24 24" fill="currentColor">
    <path
      id="axis-z-rotate-clockwise"
      d="M14,12l-4,4l4,4v-3.1c4.6-0.5,8-2.5,8-4.9s-3.4-4.4-8-4.9v2c3.5,0.3,6,1.5,6,2.9
	s-2.5,2.6-6,2.9V12 M4,12c0-1.4,2.6-2.6,6-2.9v-2C5.4,7.6,2,9.6,2,12c0,2.2,2.7,4,6.6,4.7L7.9,16l1.2-1.2C6.1,14.4,4,13.3,4,12
	 M13,2h-2v11l2-2V2 M13,22v-1l-2-2v3H13z"
    />
  </svg>,
  "Rotate"
);

export default RotateIcon;
