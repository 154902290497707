import {
  SCAFFOLD_PLANKORIENTATION,
  SCAFFOLD_PLANKTYPE,
  SCAFFOLD_STANDARDLENGTH,
} from "../../../enums";

export const BAYWIDTHS = {
  MM700: 0.7,
  MM1000: 1,
  MM1250: 1.25,
  MM1500: 1.5,
  MM1750: 1.75,
  MM2000: 2,
  MM2500: 2.5,
  MM3000: 3,
  MM3500: 3.5,
};

export const BAYLENGTHS = {
  MM500: 0.5,
  MM700: 0.7,
  MM1000: 1,
  MM1250: 1.25,
  MM1500: 1.5,
  MM1750: 1.75,
  MM2000: 2,
  MM2500: 2.5,
  MM3000: 3,
  MM3500: 3.5,
};

export const STANDARDLENGTHS = {
  M2: SCAFFOLD_STANDARDLENGTH.M2,
  M3: SCAFFOLD_STANDARDLENGTH.M3,
  M4: SCAFFOLD_STANDARDLENGTH.M4,
};

export const LOADINGBAYWIDTHS = {
  MM1000: 1,
  MM1250: 1.25,
  MM1500: 1.5,
  MM1750: 1.75,
  MM2000: 2,
  MM2500: 2.5,
  MM3000: 3,
  MM3500: 3.5,
};

export const LOADINGBAYLENGTHS = {
  MM1000: 1,
  MM1250: 1.25,
  MM1500: 1.5,
  MM1750: 1.75,
  MM2000: 2,
  MM2500: 2.5,
  MM3000: 3,
  MM3500: 3.5,
};

export const LOADINGBAYLOADCLASSES = {
  LOADCLASS1: 1,
  LOADCLASS2: 2,
  LOADCLASS3: 3,
  LOADCLASS4: 4,
  LOADCLASS5: 5,
  LOADCLASS6: 6,
};

export const PLANKWIDTHS = {
  MM300: 0.3,
  MM450: 0.45,
  MM600: 0.6,
};

export const PLANKORIENTATIONS = {
  LONGITUDINAL: SCAFFOLD_PLANKORIENTATION.LONGITUDINAL,
};

export const PLANKTYPES = {
  STEEL: SCAFFOLD_PLANKTYPE.STEEL,
  ALUMINUM: SCAFFOLD_PLANKTYPE.ALUMINUM,
  ALUMINUM_ALUMINUM: SCAFFOLD_PLANKTYPE.ALUMINUM_ALUMINUM,
  ALUMINUM_PLYWOOD: SCAFFOLD_PLANKTYPE.ALUMINUM_PLYWOOD,
};

export const CONSOLEWIDTHS = {
  MM300: 0.3,
  MM450: 0.45,
  MM600: 0.6,
};

export const STAIRWIDTHS = {
  MM700: 0.7,
  MM1250: 1.25,
};

export const STAIRLENGTHS = {
  MM2500: 2.5,
  MM3000: 3,
  MM3500: 3.5,
};
