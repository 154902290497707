import { Stack, SxProps } from "@mui/material";
import { ReactNode, forwardRef } from "react";

export interface ScrollableStackInterface {
  sx?: SxProps;
  children: ReactNode;
}

const ScrollableStack = forwardRef<HTMLDivElement, ScrollableStackInterface>(
  (props, ref) => {
    const { sx, children } = props;

    return (
      <Stack
        sx={{
          overflow: "auto",
          "&::-webkit-scrollbar": {
            webkitAppearance: "none",
            width: "4px",
          },
          "&::-webkit-scrollbar-thumb": {
            borderRadius: "2px",
            backgroundColor: "text.icon",
          },
          ...sx,
        }}
        ref={ref}
      >
        {children}
      </Stack>
    );
  }
);

export default ScrollableStack;
