import { createSvgIcon } from "@mui/material";

const InsertIcon = createSvgIcon(
  <svg viewBox="0 0 24 24" fill="currentColor">
    <path
      d="M18.6819 11.6833L25.9218 15.7156C26.1967 15.8073 26.38 16.1738 26.38 16.4488V24.6967C26.38 25.0633 26.1967 25.3382 25.9218 25.5215L21.7978 27.8126C21.6145 27.9042 21.4312 27.9959 21.2479 27.9959C21.0647 27.9959 20.8814 27.9042 20.6981 27.8126L13.4582 23.7803C13.1833 23.597 13 23.3221 13 22.9555V14.7075C13 14.4326 13.1833 14.066 13.4582 13.9744L17.5822 11.5916C17.7655 11.5 17.9488 11.5 18.1321 11.5C18.3153 11.5 18.4986 11.5 18.6819 11.6833ZM18.1321 13.4245L17.124 13.9744L15.7493 14.7992L21.2479 17.8234L22.256 17.2736L23.6307 16.4488L18.1321 13.4245ZM22.1644 25.6132L24.5471 24.2385V18.0984L22.1644 19.473V25.6132ZM20.3315 19.473L14.8329 16.3571L14.7412 22.4973L20.4232 25.4299L20.3315 19.473Z"
      fill="currentColor"
    />
    <path
      d="M2.68192 3.68329L9.92178 7.71562C10.1967 7.80726 10.38 8.17384 10.38 8.44877V16.6967C10.38 17.0633 10.1967 17.3382 9.92178 17.5215L5.79781 19.8126C5.61452 19.9042 5.43123 19.9959 5.24795 19.9959C5.06466 19.9959 4.88137 19.9042 4.69808 19.8126L-2.54178 15.7803C-2.81671 15.597 -3 15.3221 -3 14.9555V6.70753C-3 6.4326 -2.81671 6.06603 -2.54178 5.97438L1.58219 3.59164C1.76548 3.5 1.94877 3.5 2.13206 3.5C2.31534 3.5 2.49863 3.5 2.68192 3.68329ZM2.13206 5.42452L1.12397 5.97438L-0.250685 6.79918L5.24795 9.82342L6.25603 9.27356L7.63069 8.44877L2.13206 5.42452ZM6.16438 17.6132L8.54712 16.2385V10.0984L6.16438 11.473V17.6132ZM4.33151 11.473L-1.16712 8.35712L-1.25877 14.4973L4.42315 17.4299L4.33151 11.473Z"
      fill="currentColor"
    />
    <path
      d="M20.5 5.5V3.5H17V0H15V3.5H11.5V5.5H15V9H17V5.5H20.5Z"
      fill="currentColor"
    />
  </svg>,
  "Insert"
);

export default InsertIcon;
