import { FC, ReactNode } from "react";
import {
  GridCallbackDetails,
  GridPaginationModel,
  GridSlotsComponentsProps,
  DataGrid as MuiDataGrid,
} from "@mui/x-data-grid";
import { Typography } from "@mui/material";
import Stack from "@mui/material/Stack";

interface DataGridProps {
  rows: any[];
  columns: any[];
  onRowClick?: any;
  onRowDoubleClick?: (row: any) => void;
  noRowsProps?: NoProjectsOverlayProps;
  componentsProps?: GridSlotsComponentsProps;
  pageSizeOptions?: number[];
  rowCount?: number;
  paginationMode?: "server" | "client";
  onPaginationModelChange?: (
    model: GridPaginationModel,
    details: GridCallbackDetails
  ) => void;
  loading?: boolean;
  paginationModel?: { page: number; pageSize: number };
  hideFooterPagination?: boolean;
}

interface NoProjectsOverlayProps {
  text?: string;
  logo?: ReactNode;
}

const NoProjectsOverlay: FC<NoProjectsOverlayProps> = (props) => {
  const { text = "No rows", logo } = props;
  return (
    <Stack
      height="100%"
      alignItems="center"
      justifyContent="center"
      sx={{ userSelect: "none", opacity: 0.6 }}
    >
      {logo}
      <Typography mt="12px" color="text.primary">
        {text}
      </Typography>
    </Stack>
  );
};

/* <ScaffcalcLogo  width="40px"/>  */

const DataGrid: FC<DataGridProps> = (props) => {
  const {
    rows,
    columns,
    onRowClick,
    onRowDoubleClick,
    noRowsProps,
    componentsProps,
    pageSizeOptions,
    rowCount,
    paginationMode,
    onPaginationModelChange,
    paginationModel,
    loading,
    hideFooterPagination,
    ...other
  } = props;

  return (
    <MuiDataGrid
      loading={loading}
      paginationModel={paginationModel}
      pagination
      onPaginationModelChange={onPaginationModelChange}
      disableColumnMenu
      columns={columns}
      density="compact"
      onRowClick={onRowClick ? onRowClick : () => {}}
      onRowDoubleClick={
        onRowDoubleClick
          ? (params) => {
              const { row } = params;
              onRowDoubleClick(row);
            }
          : () => {}
      }
      rows={rows}
      paginationMode={paginationMode ? paginationMode : "client"}
      slots={{
        noRowsOverlay: () => <NoProjectsOverlay {...noRowsProps} />,
      }}
      rowCount={rowCount}
      slotProps={componentsProps}
      pageSizeOptions={pageSizeOptions ? pageSizeOptions : []}
      hideFooter={hideFooterPagination}
      hideFooterPagination={hideFooterPagination}
      sx={{
        height: "100%",
        cursor: "pointer",
        border: "none",
        "& .MuiDataGrid-withBorderColor": {
          borderColor: "text.primary",
          outline: "none !important",
        },
        "& .MuiDataGrid-cell": {
          border: "none !important",
          outline: "none !important",
        },
        "& .MuiDataGrid-cell--editing": {
          color: "red",
        },
        "& .MuiDataGrid-iconSeparator": {
          display: "none",
        },
        "& .MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
          outline: "none !important",
        },
        "& .MuiDataGrid-virtualScroller": {
          overflow: "auto",
          minHeight: 120,
          "&::-webkit-scrollbar": {
            webkitAppearance: "none",
            width: "4px",
          },
          "&::-webkit-scrollbar:horizontal": {
            webkitAppearance: "none",
            height: "4px",
          },
          "&::-webkit-scrollbar-thumb": {
            borderRadius: "2px",
            backgroundColor: "text.icon",
          },
          "&::-webkit-scrollbar-corner": {
            webkitAppearance: "none",
          },
        },
      }}
      {...other}
    />
  );
};

export default DataGrid;
