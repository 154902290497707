import { StateCreator } from "zustand";
import { SnackbarSlice } from "./snackbar.types";

const createSnackbarSlice: StateCreator<SnackbarSlice, [], [], SnackbarSlice> =
  (set) => ({
    snackbarChildren: null,
    snackbarDuration: 5000,
    snackbarHorizontal: "right",
    snackbarOnClose: () => {},
    snackbarOpen: false,
    snackbarText: "",
    snackbarType: null,
    snackbarVertical: "bottom",
    snackbarActions: {
      setSnackbarChildren: (snackbarChildren) => set({ snackbarChildren }),
      setSnackbarDuration: (snackbarDuration) => set({ snackbarDuration }),
      setSnackbarHorizontal: (snackbarHorizontal) =>
        set({ snackbarHorizontal }),
      setSnackbarOnClose: (snackbarOnClose) => set({ snackbarOnClose }),
      setSnackbarOpen: (snackbarOpen) => set({ snackbarOpen }),
      setSnackbarText: (snackbarText) => set({ snackbarText }),
      setSnackbarType: (snackbarType) => set({ snackbarType }),
      setSnackbarVertical: (snackbarVertical) => set({ snackbarVertical }),
    },
  });

export default createSnackbarSlice;
