import { PeriUp } from "@scaffcalc/backends-firebase";

export const STANDARD_BOTTOM_OFFSET = 0.1;
export const STANDARD_BOTTOMFRAME_OFFSET = 0.4;
export const HEIGHT_BETWEEN_FRAMES = 2;
export const ANCHOR_Y_OFFSET = 0.15;
export const SHORTESTTOEBOARDLENGTH = new PeriUp().bayLengths.MM250;
export const COUPLERREPLACEDISTANCE = new PeriUp().bayLengths.MM150;
export const MINSLIDINGGATELENGTH = 2.57;
export const MINSLIDINGGATEHEIGHT = 1.5;
