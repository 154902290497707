import {
  SCAFFOLD_ANCHORDENSITY,
  SCAFFOLD_CORNER,
  SCAFFOLD_LOADCLASS,
  SCAFFOLD_PLANKORIENTATION,
  SCAFFOLD_PLANKTYPE,
  SCAFFOLD_STANDARDLENGTH,
} from "../../../enums";
import Supplier from "../Supplier";
import { TFunction } from "i18next";

import {
  BAYLENGTHS,
  BAYWIDTHS,
  PLANKWIDTHS,
  PLANKTYPES,
  LOADINGBAYLENGTHS,
  LOADINGBAYWIDTHS,
  CONSOLEWIDTHS,
  LOADINGBAYLOADCLASSES,
  STAIRWIDTHS,
  STAIRLENGTHS,
  PLANKORIENTATIONS,
  STANDARDLENGTHS,
} from "./constants";
import { SCAFFOLDTYPES } from "./enums";

export class Haki implements Supplier {
  bayLengths: typeof BAYLENGTHS;
  bayWidths: typeof BAYWIDTHS;
  consoleWidths: typeof CONSOLEWIDTHS;
  loadingBayLengths: typeof LOADINGBAYLENGTHS;
  loadingBayWidths: typeof LOADINGBAYWIDTHS;
  plankOrientations: typeof PLANKORIENTATIONS;
  plankTypes: typeof PLANKTYPES;
  plankWidths: typeof PLANKWIDTHS;
  scaffoldType: SCAFFOLDTYPES;
  stairLengths: typeof STAIRLENGTHS;
  stairWidths: typeof STAIRWIDTHS;
  standardLengths: typeof STANDARDLENGTHS;

  constructor(scaffoldType: SCAFFOLDTYPES = SCAFFOLDTYPES.MODULE) {
    this.bayLengths = BAYLENGTHS;
    this.bayWidths = BAYWIDTHS;
    this.consoleWidths = CONSOLEWIDTHS;
    this.loadingBayLengths = LOADINGBAYLENGTHS;
    this.loadingBayWidths = LOADINGBAYWIDTHS;
    this.plankOrientations = PLANKORIENTATIONS;
    this.plankTypes = PLANKTYPES;
    this.plankWidths = PLANKWIDTHS;
    this.scaffoldType = scaffoldType;
    this.stairLengths = STAIRLENGTHS;
    this.stairWidths = STAIRWIDTHS;
    this.standardLengths = STANDARDLENGTHS;
  }

  public getBayLengths = (
    plankType: SCAFFOLD_PLANKTYPE,
    loadClass: SCAFFOLD_LOADCLASS
  ) => {
    if (plankType === PLANKTYPES.STEEL || plankType === PLANKTYPES.ALUMINUM) {
      const initLengths = [
        BAYLENGTHS.MM700,
        BAYLENGTHS.MM770,
        BAYLENGTHS.MM1050,
        BAYLENGTHS.MM1250,
        BAYLENGTHS.MM1655,
        BAYLENGTHS.MM2050,
        BAYLENGTHS.MM2500,
        BAYLENGTHS.MM2550,
      ];
      if (loadClass <= SCAFFOLD_LOADCLASS.LOADCLASS5) {
        return [...initLengths, BAYLENGTHS.MM3050];
      } else {
        return initLengths;
      }
    } else if (plankType === PLANKTYPES.HOOKPLANE) {
      const initLengths = [
        BAYLENGTHS.MM1050,
        BAYLENGTHS.MM1250,
        BAYLENGTHS.MM1550,
        BAYLENGTHS.MM1655,
        BAYLENGTHS.MM1964,
        BAYLENGTHS.MM2050,
        BAYLENGTHS.MM2500,
        BAYLENGTHS.MM2550,
        BAYLENGTHS.MM3050,
      ];
      if (loadClass <= SCAFFOLD_LOADCLASS.LOADCLASS3) {
        return initLengths;
      } else {
        return [];
      }
    } else {
      const initLengths = [
        BAYLENGTHS.MM700,
        BAYLENGTHS.MM770,
        BAYLENGTHS.MM1050,
        BAYLENGTHS.MM1250,
        BAYLENGTHS.MM1655,
        BAYLENGTHS.MM2050,
        BAYLENGTHS.MM2550,
        BAYLENGTHS.MM3050,
      ];

      return initLengths;
    }
  };

  getBayWidths = (
    plankType: SCAFFOLD_PLANKTYPE,
    loadClass: SCAFFOLD_LOADCLASS
  ) => {
    if (plankType === PLANKTYPES.STEEL_DUCKBOARD) {
      if (loadClass <= SCAFFOLD_LOADCLASS.LOADCLASS6) {
        return [
          this.bayWidths.MM1050,
          this.bayWidths.MM1250,
          this.bayWidths.MM1655,
        ];
      } else {
        return [];
      }
    } else if (plankType == PLANKTYPES.ALUMINUM_DUCKBOARD) {
      if (loadClass <= SCAFFOLD_LOADCLASS.LOADCLASS6) {
        return [this.bayWidths.MM1250, this.bayWidths.MM1655];
      } else {
        return [];
      }
    } else if (plankType === PLANKTYPES.WOOD_DUCKBOARD) {
      if (loadClass <= SCAFFOLD_LOADCLASS.LOADCLASS5) {
        return [this.bayWidths.MM1250, this.bayWidths.MM1655];
      } else {
        return [];
      }
    } else if (plankType === PLANKTYPES.WOOD_SOUTH_DUCKBOARD) {
      if (loadClass === SCAFFOLD_LOADCLASS.LOADCLASS5) {
        return [this.bayWidths.MM1250];
      } else if (loadClass <= SCAFFOLD_LOADCLASS.LOADCLASS4) {
        return [this.bayWidths.MM1250, this.bayWidths.MM1655];
      } else {
        return [];
      }
    } else if (plankType === PLANKTYPES.COMPOSITE_DUCKBOARD) {
      if (loadClass <= SCAFFOLD_LOADCLASS.LOADCLASS6) {
        return [this.bayWidths.MM1250, this.bayWidths.MM1655];
      } else {
        return [];
      }
    } else {
      return Object.values(this.bayWidths);
    }
  };

  getLoadClasses = (plankType: SCAFFOLD_PLANKTYPE, plankWidth?: number) => {
    if (
      plankType === this.plankTypes.ALUMINUM ||
      plankType === this.plankTypes.ALUMINUM_DUCKBOARD ||
      plankType === this.plankTypes.COMPOSITE_DUCKBOARD ||
      plankType === this.plankTypes.STEEL ||
      plankType === this.plankTypes.STEEL_DUCKBOARD ||
      plankType === this.plankTypes.WOOD_DUCKBOARD
    ) {
      return [
        SCAFFOLD_LOADCLASS.LOADCLASS1,
        SCAFFOLD_LOADCLASS.LOADCLASS2,
        SCAFFOLD_LOADCLASS.LOADCLASS3,
        SCAFFOLD_LOADCLASS.LOADCLASS4,
        SCAFFOLD_LOADCLASS.LOADCLASS5,
        SCAFFOLD_LOADCLASS.LOADCLASS6,
      ];
    } else if (plankType === this.plankTypes.WOOD_SOUTH_DUCKBOARD) {
      return [
        SCAFFOLD_LOADCLASS.LOADCLASS1,
        SCAFFOLD_LOADCLASS.LOADCLASS2,
        SCAFFOLD_LOADCLASS.LOADCLASS3,
        SCAFFOLD_LOADCLASS.LOADCLASS4,
        SCAFFOLD_LOADCLASS.LOADCLASS5,
      ];
    } else if (plankType === this.plankTypes.HOOKPLANE) {
      /** THIS SHOULD ONLY BE SET FOR PLANEWIDTH === 600MM */
      if (plankWidth === this.plankWidths.MM600) {
        return [
          SCAFFOLD_LOADCLASS.LOADCLASS1,
          SCAFFOLD_LOADCLASS.LOADCLASS2,
          SCAFFOLD_LOADCLASS.LOADCLASS3,
        ];
      } else {
        return [
          SCAFFOLD_LOADCLASS.LOADCLASS1,
          SCAFFOLD_LOADCLASS.LOADCLASS2,
          SCAFFOLD_LOADCLASS.LOADCLASS3,
          SCAFFOLD_LOADCLASS.LOADCLASS4,
          SCAFFOLD_LOADCLASS.LOADCLASS5,
          SCAFFOLD_LOADCLASS.LOADCLASS6,
        ];
      }
    } else {
      return [];
    }
  };

  getLoadingBayLengths = () => Object.values(LOADINGBAYLENGTHS);

  getLoadingBayWidths = () => Object.values(LOADINGBAYWIDTHS);

  getAnchorDensities = () => [
    SCAFFOLD_ANCHORDENSITY.EVERY_FOUR,
    SCAFFOLD_ANCHORDENSITY.EVERY_THREE,
    SCAFFOLD_ANCHORDENSITY.EVERY_TWO,
  ];

  getStandardLengths = () => [
    SCAFFOLD_STANDARDLENGTH.M4,
    SCAFFOLD_STANDARDLENGTH.M3,
    SCAFFOLD_STANDARDLENGTH.M2,
  ];

  getConsoleWidths = () => Object.values(CONSOLEWIDTHS);

  getLoadingBayLoadClasses = () => Object.values(LOADINGBAYLOADCLASSES);

  getStairWidths = () => Object.values(STAIRWIDTHS);

  getStairLengths = () => Object.values(STAIRLENGTHS);

  getCornerConfigurations = () => {
    return [
      SCAFFOLD_CORNER.ABOVE_BAY,
      SCAFFOLD_CORNER.ABOVE_BAY_EXTRA,
      SCAFFOLD_CORNER.IN_BAY,
      SCAFFOLD_CORNER.IN_BAY_EXTRA,
    ];
  };

  getBayPlankConfiguration = (
    bayWidth: number,
    orientation?: SCAFFOLD_PLANKORIENTATION
  ) => this.calculatePlankConfiguration(bayWidth, orientation);

  getLoadingBayPlankConfiguration = (
    loadingBayWidth: number,
    orientation?: SCAFFOLD_PLANKORIENTATION
  ) => this.calculatePlankConfiguration(loadingBayWidth, orientation);

  getConsolePlankConfiguration = (
    consoleWidth: number,
    orientation?: SCAFFOLD_PLANKORIENTATION
  ) => this.calculatePlankConfiguration(consoleWidth, orientation);

  private calculatePlankConfiguration = (
    bayWidth: number,
    orientation?: SCAFFOLD_PLANKORIENTATION
  ) => {
    let calcPlankWidth = this.plankWidths.MM230;

    if (orientation === SCAFFOLD_PLANKORIENTATION.TRANSVERSE) {
      calcPlankWidth = this.plankWidths.MM495;
    }

    const nPlanks = Math.floor(bayWidth / calcPlankWidth);

    if (Object.values(this.bayWidths).includes(bayWidth)) {
      const A = Array(nPlanks).fill(calcPlankWidth);
      return [A];
    } else {
      return [];
    }
  };

  getPlankingOptions = (t: TFunction) => {
    return [
      {
        value: this.plankTypes.STEEL + this.plankWidths.MM230,
        material: this.plankTypes.STEEL,
        label: t("options.plankingtype.haki.steelplank"),
        width: this.plankWidths.MM230,
        orientation: SCAFFOLD_PLANKORIENTATION.LONGITUDINAL,
      },
      {
        value: this.plankTypes.ALUMINUM + this.plankWidths.MM230,
        material: this.plankTypes.ALUMINUM,
        label: t("options.plankingtype.haki.aluminiumplank"),
        width: this.plankWidths.MM230,
        orientation: SCAFFOLD_PLANKORIENTATION.LONGITUDINAL,
      },
      {
        value: this.plankTypes.ALUMINUM + this.plankWidths.MM320,
        material: this.plankTypes.ALUMINUM,
        label: t("options.plankingtype.haki.aluminiumplank320"),
        width: this.plankWidths.MM320,
        orientation: SCAFFOLD_PLANKORIENTATION.LONGITUDINAL,
      },
      {
        value: this.plankTypes.WOOD_DUCKBOARD + this.plankWidths.MM495,
        material: this.plankTypes.WOOD_DUCKBOARD,
        label: t("options.plankingtype.haki.woodenplankframe"),
        width: this.plankWidths.MM495,
        orientation: SCAFFOLD_PLANKORIENTATION.TRANSVERSE,
      },
      {
        value: this.plankTypes.ALUMINUM_DUCKBOARD + this.plankWidths.MM495,
        material: this.plankTypes.ALUMINUM_DUCKBOARD,
        label: t("options.plankingtype.haki.aluminiumduckboard"),
        width: this.plankWidths.MM495,
        orientation: SCAFFOLD_PLANKORIENTATION.TRANSVERSE,
      },
      {
        value: this.plankTypes.COMPOSITE_DUCKBOARD + this.plankWidths.MM495,
        material: this.plankTypes.COMPOSITE_DUCKBOARD,
        label: t("options.plankingtype.haki.compositeduckboard"),
        width: this.plankWidths.MM495,
        orientation: SCAFFOLD_PLANKORIENTATION.TRANSVERSE,
      },
      {
        value: this.plankTypes.STEEL_DUCKBOARD + this.plankWidths.MM495,
        material: this.plankTypes.STEEL_DUCKBOARD,
        label: t("options.plankingtype.haki.steelduckboard"),
        width: this.plankWidths.MM495,
        orientation: SCAFFOLD_PLANKORIENTATION.TRANSVERSE,
      },
      {
        value: this.plankTypes.WOOD_SOUTH_DUCKBOARD + this.plankWidths.MM495,
        material: this.plankTypes.WOOD_SOUTH_DUCKBOARD,
        label: t("options.plankingtype.sydtrall.woodenplank"),
        width: this.plankWidths.MM495,
        orientation: SCAFFOLD_PLANKORIENTATION.TRANSVERSE,
      },
      {
        value: this.plankTypes.HOOKPLANE + this.plankWidths.MM400,
        material: this.plankTypes.HOOKPLANE,
        label: t("options.plankingtype.haki.hookplane400"),
        width: this.plankWidths.MM400,
        orientation: SCAFFOLD_PLANKORIENTATION.LONGITUDINAL,
      },
      {
        value: this.plankTypes.HOOKPLANE + this.plankWidths.MM600,
        material: this.plankTypes.HOOKPLANE,
        label: t("options.plankingtype.haki.hookplane600"),
        width: this.plankWidths.MM600,
        orientation: SCAFFOLD_PLANKORIENTATION.LONGITUDINAL,
      },
    ];
  };
}

export default Haki;
