import { createTheme, responsiveFontSizes } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface Palette {
    navbar: {
      main: string;
    };
  }
  interface PaletteOptions {
    navbar?: {
      main?: string;
    };
  }
}

let darkTheme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "#151D2C",
      paper: "#000F24",
    },
    primary: {
      main: "#216794",
    },
    secondary: {
      main: "#232E42",
    },
    error: {
      main: "#EA1026",
    },
    warning: {
      main: "#E37E2A",
    },
    success: {
      main: "#228743",
    },
    info: {
      main: "#ffffff",
    },
    grey: {
      50: "#edeef4",
      100: "#d1d6e0",
      200: "#b4bac9",
      300: "#979fb2",
      400: "#818aa0",
      500: "#6b778f",
      600: "#5d687e",
      700: "#4c5567",
      800: "#3c4351",
      900: "#292e3a",
      A100: "#d1d6e0",
      A200: "#b4bac9",
      A400: "#818aa0",
      A700: "#4c5567",
    },
    contrastThreshold: 4.5,
    tonalOffset: 0.1,
    navbar: { main: "#1F2D46" },
  },
  typography: {
    h4: {
      fontWeight: 500,
    },
    h5: {
      fontWeight: 500,
    },
    subtitle1: {
      fontWeight: 500,
    },
  },
});

darkTheme = responsiveFontSizes(darkTheme);

export default darkTheme;
