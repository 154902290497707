import { createSvgIcon } from "@mui/material";

const SplitIcon = createSvgIcon(
  <svg viewBox="0 0 24 24" fill="currentColor">
    <path d="M20.8171 13.1794L13.7303 17.1265C13.4612 17.2162 13.2817 17.575 13.2817 17.8442V25.9177C13.2817 26.2766 13.4612 26.5457 13.7303 26.7251L17.7671 28.9678C17.9465 29.0575 18.1259 29.1472 18.3053 29.1472C18.4847 29.1472 18.6641 29.0575 18.8436 28.9678L25.9304 25.0207C26.1995 24.8413 26.3789 24.5721 26.3789 24.2133V16.1397C26.3789 15.8706 26.1995 15.5118 25.9304 15.4221L21.8936 13.0897C21.7142 13 21.5348 13 21.3553 13C21.1759 13 20.9965 13 20.8171 13.1794ZM21.3553 14.8838L22.3421 15.4221L23.6877 16.2294L18.3053 19.1898L17.3185 18.6515L15.9729 17.8442L21.3553 14.8838ZM17.4082 26.8148L15.0759 25.4692V19.4589L17.4082 20.8045V26.8148ZM19.2024 20.8045L24.5848 17.7544L24.6745 23.7648L19.1127 26.6354L19.2024 20.8045Z" />
    <path d="M3.17899 13.1794L10.2658 17.1265C10.5349 17.2162 10.7143 17.575 10.7143 17.8442V25.9177C10.7143 26.2766 10.5349 26.5457 10.2658 26.7251L6.22902 28.9678C6.0496 29.0575 5.87019 29.1472 5.69078 29.1472C5.51136 29.1472 5.33195 29.0575 5.15254 28.9678L-1.93428 25.0207C-2.2034 24.8413 -2.38281 24.5721 -2.38281 24.2133V16.1397C-2.38281 15.8706 -2.2034 15.5118 -1.93428 15.4221L2.10252 13.0897C2.28193 13 2.46134 13 2.64076 13C2.82017 13 2.99958 13 3.17899 13.1794ZM2.64076 14.8838L1.65398 15.4221L0.308384 16.2294L5.69078 19.1898L6.67755 18.6515L8.02315 17.8442L2.64076 14.8838ZM6.58784 26.8148L8.92021 25.4692V19.4589L6.58784 20.8045V26.8148ZM4.79371 20.8045L-0.588681 17.7544L-0.678388 23.7648L4.88342 26.6354L4.79371 20.8045Z" />
    <path d="M19.797 9.41426L24.0041 5.20716L19.797 1L18.3827 2.41422L20.1756 4.20716H13.5898V6.20716H20.1756L18.3827 7.99996L19.797 9.41426Z" />
    <path d="M4.2071 1L0 5.2071L4.2071 9.41426L5.62132 8.00004L3.82842 6.2071H10.4142V4.2071H3.82842L5.62132 2.4143L4.2071 1Z" />
  </svg>,
  "Split"
);

export default SplitIcon;
