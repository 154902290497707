import { FC, useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Banner, Button } from "@scaffcalc/packages-ui";
import { USER_ACCOUNTTYPE } from "@scaffcalc/backends-firebase";
import { useUserAccountType, useUserExpires } from "../../store";
import Backdrop from "@mui/material/Backdrop";
import TrialOverlayProps from "./TrialOverlay.types";
import { useNavigate } from "react-router-dom";

const TrialOverlay: FC<TrialOverlayProps> = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  /** Hook: Check if user is on mobile */

  const userAccountType = useUserAccountType();

  const userExpires = useUserExpires();

  const daysToExpiration = useMemo(() => {
    let daysToExpiration = null;

    if (userAccountType !== USER_ACCOUNTTYPE.TRIAL || userExpires === null)
      return daysToExpiration;

    let today: number = new Date().getTime();
    let expirationDate: number = userExpires.getTime();

    daysToExpiration = Math.ceil((expirationDate - today) / (1000 * 3600 * 24));

    return daysToExpiration;
  }, [userAccountType, userExpires]);

  const isExpired = useMemo(
    () => daysToExpiration !== null && daysToExpiration < 1,
    [daysToExpiration]
  );

  const bannerText = useMemo(() => {
    if (userAccountType !== USER_ACCOUNTTYPE.TRIAL || daysToExpiration === null)
      return "";

    if (isExpired) return t("notes.trialexpiredtext");

    return ` ${t("notes.trialtext")} ${
      daysToExpiration !== null && daysToExpiration.toString()
    } ${t("notes.trialtextdays")}`;
  }, [daysToExpiration, t, userAccountType, isExpired]);

  const buttonText = useMemo(() => {
    if (isExpired) return t("actions.contact");

    return t("actions.upgrade");
  }, [t, isExpired]);

  const handleOnClickButton = useCallback(() => {
    window.open("https://scaffcalc.com/support", "_blank");
  }, []);

  useEffect(() => {
    if (isExpired) {
      navigate("/home");
    }
  }, [isExpired, navigate]);

  return (
    <>
      {userAccountType === USER_ACCOUNTTYPE.TRIAL && (
        <>
          <Backdrop
            open={isExpired}
            sx={{
              zIndex: 9999,
            }}
          />
          <Banner
            header={bannerText}
            actions={
              <Button
                text={buttonText}
                color={"blue"}
                onClick={handleOnClickButton}
              />
            }
          />
        </>
      )}
    </>
  );
};

export default TrialOverlay;
