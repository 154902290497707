import {
  BAYLENGTHS,
  BAYWIDTHS,
  CONSOLEWIDTHS,
  LOADINGBAYLENGTHS,
  LOADINGBAYLOADCLASSES,
  LOADINGBAYWIDTHS,
  PLANKORIENTATIONS,
  PLANKTYPES,
  PLANKWIDTHS,
  STAIRLENGTHS,
  STAIRWIDTHS,
  STANDARDLENGTHS,
} from "./constants";
import { SCAFFOLDTYPES } from "./enums";
import Supplier from "../Supplier";
import { TFunction } from "i18next";
import {
  SCAFFOLD_ANCHORDENSITY,
  SCAFFOLD_CORNER,
  SCAFFOLD_LOADCLASS,
  SCAFFOLD_PLANKORIENTATION,
  SCAFFOLD_PLANKTYPE,
  SCAFFOLD_STANDARDLENGTH,
} from "../../../enums";

export class Pluseight implements Supplier {
  bayLengths: typeof BAYLENGTHS;
  bayWidths: typeof BAYWIDTHS;
  consoleWidths: typeof CONSOLEWIDTHS;
  loadingBayLengths: typeof LOADINGBAYLENGTHS;
  loadingBayWidths: typeof LOADINGBAYWIDTHS;
  plankOrientations: typeof PLANKORIENTATIONS;
  plankTypes: typeof PLANKTYPES;
  plankWidths: typeof PLANKWIDTHS;
  scaffoldType: SCAFFOLDTYPES;
  stairLengths: typeof STAIRLENGTHS;
  stairWidths: typeof STAIRWIDTHS;
  standardLengths: typeof STANDARDLENGTHS;

  constructor(scaffoldType: SCAFFOLDTYPES = SCAFFOLDTYPES.MODULE) {
    this.bayLengths = BAYLENGTHS;
    this.bayWidths = BAYWIDTHS;
    this.consoleWidths = CONSOLEWIDTHS;
    this.loadingBayLengths = LOADINGBAYLENGTHS;
    this.loadingBayWidths = LOADINGBAYWIDTHS;
    this.plankOrientations = PLANKORIENTATIONS;
    this.plankTypes = PLANKTYPES;
    this.plankWidths = PLANKWIDTHS;
    this.scaffoldType = scaffoldType;
    this.stairLengths = STAIRLENGTHS;
    this.stairWidths = STAIRWIDTHS;
    this.standardLengths = STANDARDLENGTHS;
  }

  getBayLengths = (
    plankType: SCAFFOLD_PLANKTYPE,
    loadClass: SCAFFOLD_LOADCLASS
  ) => {
    if (
      plankType === this.plankTypes.ALUMINUM ||
      plankType === this.plankTypes.ALUMINUM_PLYWOOD
    ) {
      if (loadClass === SCAFFOLD_LOADCLASS.LOADCLASS6) {
        return [
          this.bayLengths.MM500,
          this.bayLengths.MM700,
          this.bayLengths.MM1000,
          this.bayLengths.MM1250,
          this.bayLengths.MM1500,
          this.bayLengths.MM1750,
          this.bayLengths.MM2000,
          this.bayLengths.MM2500,
        ];
      } else if (loadClass === SCAFFOLD_LOADCLASS.LOADCLASS5) {
        return [
          this.bayLengths.MM500,
          this.bayLengths.MM700,
          this.bayLengths.MM1000,
          this.bayLengths.MM1250,
          this.bayLengths.MM1500,
          this.bayLengths.MM1750,
          this.bayLengths.MM2000,
          this.bayLengths.MM2500,
          this.bayLengths.MM3000,
        ];
      } else if (loadClass <= SCAFFOLD_LOADCLASS.LOADCLASS4) {
        return [
          this.bayLengths.MM500,
          this.bayLengths.MM700,
          this.bayLengths.MM1000,
          this.bayLengths.MM1250,
          this.bayLengths.MM1500,
          this.bayLengths.MM1750,
          this.bayLengths.MM2000,
          this.bayLengths.MM2500,
          this.bayLengths.MM3000,
          this.bayLengths.MM3500,
        ];
      } else {
        return [];
      }
    } else if (
      plankType === this.plankTypes.ALUMINUM_ALUMINUM ||
      plankType === this.plankTypes.STEEL
    ) {
      if (loadClass === SCAFFOLD_LOADCLASS.LOADCLASS6) {
        return [
          this.bayLengths.MM500,
          this.bayLengths.MM700,
          this.bayLengths.MM1000,
          this.bayLengths.MM1250,
          this.bayLengths.MM1500,
          this.bayLengths.MM1750,
          this.bayLengths.MM2000,
          this.bayLengths.MM2500,
          this.bayLengths.MM3000,
        ];
      } else if (loadClass <= SCAFFOLD_LOADCLASS.LOADCLASS5) {
        return [
          this.bayLengths.MM500,
          this.bayLengths.MM700,
          this.bayLengths.MM1000,
          this.bayLengths.MM1250,
          this.bayLengths.MM1500,
          this.bayLengths.MM1750,
          this.bayLengths.MM2000,
          this.bayLengths.MM2500,
          this.bayLengths.MM3000,
          this.bayLengths.MM3500,
        ];
      } else {
        return [];
      }
    } else {
      return [];
    }
  };

  getBayWidths = () => Object.values(this.bayWidths);

  getLoadClasses = () => [
    SCAFFOLD_LOADCLASS.LOADCLASS1,
    SCAFFOLD_LOADCLASS.LOADCLASS2,
    SCAFFOLD_LOADCLASS.LOADCLASS3,
    SCAFFOLD_LOADCLASS.LOADCLASS4,
    SCAFFOLD_LOADCLASS.LOADCLASS5,
    SCAFFOLD_LOADCLASS.LOADCLASS6,
  ];

  getLoadingBayLengths = () => Object.values(LOADINGBAYLENGTHS);

  getLoadingBayWidths = () => Object.values(LOADINGBAYWIDTHS);

  getAnchorDensities = () => [
    SCAFFOLD_ANCHORDENSITY.EVERY_FOUR,
    SCAFFOLD_ANCHORDENSITY.EVERY_THREE,
    SCAFFOLD_ANCHORDENSITY.EVERY_TWO,
  ];

  getStandardLengths = () => [
    SCAFFOLD_STANDARDLENGTH.M4,
    SCAFFOLD_STANDARDLENGTH.M3,
    SCAFFOLD_STANDARDLENGTH.M2,
  ];

  getConsoleWidths = () => Object.values(CONSOLEWIDTHS);

  getLoadingBayLoadClasses = () => Object.values(LOADINGBAYLOADCLASSES);

  getStairWidths = () => Object.values(STAIRWIDTHS);

  getStairLengths = () => Object.values(STAIRLENGTHS);

  getBayPlankConfiguration = (bayWidth: number) =>
    this.calculatePlankConfiguration(bayWidth);

  getLoadingBayPlankConfiguration = (loadingBayWidth: number) =>
    this.calculatePlankConfiguration(loadingBayWidth);

  getConsolePlankConfiguration = (consoleWidth: number) =>
    this.calculatePlankConfiguration(consoleWidth);

  private calculatePlankConfiguration = (bayWidth: number) => {
    if (bayWidth === this.bayWidths.MM700) {
      const A = Array(2).fill(this.plankWidths.MM300);
      const B = [this.plankWidths.MM600];
      return [A, B];
    } else if (bayWidth === this.bayWidths.MM1000) {
      const A = Array(3).fill(this.plankWidths.MM300);
      const B = [this.plankWidths.MM600, this.plankWidths.MM300];
      const C = Array(2).fill(this.plankWidths.MM450);
      return [A, B, C];
    } else if (bayWidth === this.bayWidths.MM1250) {
      const A = Array(4).fill(this.plankWidths.MM300);
      const B = Array(2).fill(this.plankWidths.MM600);
      const C = Array(2)
        .fill(this.plankWidths.MM450)
        .concat([this.plankWidths.MM300]);
      return [A, B, C];
    } else if (bayWidth === this.bayWidths.MM1500) {
      const A = Array(5).fill(this.plankWidths.MM300);
      const B = Array(2)
        .fill(this.plankWidths.MM600)
        .concat([this.plankWidths.MM300]);
      const C = Array(3)
        .fill(this.plankWidths.MM450)
        .concat([this.plankWidths.MM300]);
      return [A, B, C];
    } else if (bayWidth === this.bayWidths.MM1750) {
      const A = Array(2)
        .fill(this.plankWidths.MM600)
        .concat([this.plankWidths.MM300]);
      const B = Array(3)
        .fill(this.plankWidths.MM450)
        .concat([this.plankWidths.MM300]);
      return [A, B];
    } else {
      return [];
    }
  };

  getCornerConfigurations = () => {
    return [
      SCAFFOLD_CORNER.ABOVE_BAY,
      SCAFFOLD_CORNER.ABOVE_BAY_EXTRA,
      SCAFFOLD_CORNER.IN_BAY,
      SCAFFOLD_CORNER.IN_BAY_EXTRA,
    ];
  };

  getPlankingOptions = (t: TFunction) => {
    return [
      {
        value: this.plankTypes.STEEL + this.plankWidths.MM300,
        material: this.plankTypes.STEEL,
        label: t("options.plankingtype.pluseight.steelplank"),
        width: this.plankWidths.MM300,
        orientation: SCAFFOLD_PLANKORIENTATION.LONGITUDINAL,
      },
      {
        value: this.plankTypes.ALUMINUM + this.plankWidths.MM300,
        material: this.plankTypes.ALUMINUM,
        label: t("options.plankingtype.pluseight.aluminiumplank"),
        width: this.plankWidths.MM300,
        orientation: SCAFFOLD_PLANKORIENTATION.LONGITUDINAL,
      },
      {
        value: this.plankTypes.ALUMINUM_ALUMINUM + this.plankWidths.MM300,
        material: this.plankTypes.ALUMINUM_ALUMINUM,
        label: t("options.plankingtype.pluseight.aluminiumaluminiumplank"),
        width: this.plankWidths.MM300,
        orientation: SCAFFOLD_PLANKORIENTATION.LONGITUDINAL,
      },
      {
        value: this.plankTypes.ALUMINUM_PLYWOOD + this.plankWidths.MM300,
        material: this.plankTypes.ALUMINUM_PLYWOOD,
        label: t("options.plankingtype.pluseight.aluminiumplywoodplank"),
        width: this.plankWidths.MM300,
        orientation: SCAFFOLD_PLANKORIENTATION.LONGITUDINAL,
      },
    ];
  };
}

export default Pluseight;
