import {
  BAYLENGTHS,
  BAYWIDTHS,
  CONSOLEWIDTHS,
  LOADINGBAYLENGTHS,
  LOADINGBAYLOADCLASSES,
  LOADINGBAYWIDTHS,
  PLANKORIENTATIONS,
  PLANKTYPES,
  PLANKWIDTHS,
  STAIRLENGTHS,
  STAIRWIDTHS,
  STANDARDLENGTHS,
} from "./constants";
import { SCAFFOLDTYPES } from "./enums";
import Supplier from "../Supplier";
import { TFunction } from "i18next";
import {
  SCAFFOLD_ANCHORDENSITY,
  SCAFFOLD_CORNER,
  SCAFFOLD_LOADCLASS,
  SCAFFOLD_PLANKORIENTATION,
  SCAFFOLD_PLANKTYPE,
  SCAFFOLD_STANDARDLENGTH,
} from "../../../enums";

export class Alfix implements Supplier {
  bayLengths: typeof BAYLENGTHS;
  bayWidths: typeof BAYWIDTHS;
  consoleWidths: typeof CONSOLEWIDTHS;
  loadingBayLengths: typeof LOADINGBAYLENGTHS;
  loadingBayWidths: typeof LOADINGBAYWIDTHS;
  plankOrientations: typeof PLANKORIENTATIONS;
  plankTypes: typeof PLANKTYPES;
  plankWidths: typeof PLANKWIDTHS;
  scaffoldType: SCAFFOLDTYPES;
  stairLengths: typeof STAIRLENGTHS;
  stairWidths: typeof STAIRWIDTHS;
  standardLengths: typeof STANDARDLENGTHS;

  constructor(scaffoldType: SCAFFOLDTYPES = SCAFFOLDTYPES.MODULE) {
    this.bayLengths = BAYLENGTHS;
    this.bayWidths = BAYWIDTHS;
    this.consoleWidths = CONSOLEWIDTHS;
    this.loadingBayLengths = LOADINGBAYLENGTHS;
    this.loadingBayWidths = LOADINGBAYWIDTHS;
    this.plankOrientations = PLANKORIENTATIONS;
    this.plankTypes = PLANKTYPES;
    this.plankWidths = PLANKWIDTHS;
    this.scaffoldType = scaffoldType;
    this.stairLengths = STAIRLENGTHS;
    this.stairWidths = STAIRWIDTHS;
    this.standardLengths = STANDARDLENGTHS;
  }

  getBayLengths = (
    plankType: SCAFFOLD_PLANKTYPE,
    loadClass: SCAFFOLD_LOADCLASS,
    plankWidth?: number
  ) => {
    if (plankType === this.plankTypes.STEEL) {
      if (loadClass === SCAFFOLD_LOADCLASS.LOADCLASS6) {
        return [
          this.bayLengths.MM150,
          this.bayLengths.MM390,
          this.bayLengths.MM450,
          this.bayLengths.MM730,
          this.bayLengths.MM1090,
          this.bayLengths.MM1400,
          this.bayLengths.MM1570,
          this.bayLengths.MM2070,
        ];
      } else if (loadClass === SCAFFOLD_LOADCLASS.LOADCLASS5) {
        return [
          this.bayLengths.MM150,
          this.bayLengths.MM390,
          this.bayLengths.MM450,
          this.bayLengths.MM730,
          this.bayLengths.MM1090,
          this.bayLengths.MM1400,
          this.bayLengths.MM1570,
          this.bayLengths.MM2070,
          this.bayLengths.MM2570,
        ];
      } else if (loadClass === SCAFFOLD_LOADCLASS.LOADCLASS4) {
        return [
          this.bayLengths.MM150,
          this.bayLengths.MM390,
          this.bayLengths.MM450,
          this.bayLengths.MM730,
          this.bayLengths.MM1090,
          this.bayLengths.MM1400,
          this.bayLengths.MM1570,
          this.bayLengths.MM2070,
          this.bayLengths.MM2570,
          this.bayLengths.MM3070,
        ];
      } else if (loadClass <= SCAFFOLD_LOADCLASS.LOADCLASS3) {
        return [
          this.bayLengths.MM150,
          this.bayLengths.MM390,
          this.bayLengths.MM450,
          this.bayLengths.MM730,
          this.bayLengths.MM1090,
          this.bayLengths.MM1400,
          this.bayLengths.MM1570,
          this.bayLengths.MM2070,
          this.bayLengths.MM2570,
          this.bayLengths.MM3070,
          this.bayLengths.MM4140,
        ];
      } else {
        return [];
      }
    } else if (plankType === this.plankTypes.ALUMINUM) {
      if (plankWidth === this.plankWidths.MM320) {
        if (loadClass === SCAFFOLD_LOADCLASS.LOADCLASS6) {
          return [
            this.bayLengths.MM450,
            this.bayLengths.MM730,
            this.bayLengths.MM1090,
            this.bayLengths.MM1570,
            this.bayLengths.MM2070,
          ];
        } else if (loadClass === SCAFFOLD_LOADCLASS.LOADCLASS5) {
          return [
            this.bayLengths.MM450,
            this.bayLengths.MM730,
            this.bayLengths.MM1090,
            this.bayLengths.MM1570,
            this.bayLengths.MM2070,
            this.bayLengths.MM2570,
          ];
        } else if (loadClass <= SCAFFOLD_LOADCLASS.LOADCLASS4) {
          return [
            this.bayLengths.MM450,
            this.bayLengths.MM730,
            this.bayLengths.MM1090,
            this.bayLengths.MM1570,
            this.bayLengths.MM2070,
            this.bayLengths.MM2570,
            this.bayLengths.MM3070,
          ];
        } else {
          return [];
        }
      } else if (plankWidth === this.plankWidths.MM600) {
        if (loadClass === SCAFFOLD_LOADCLASS.LOADCLASS4) {
          return [
            this.bayLengths.MM450,
            this.bayLengths.MM730,
            this.bayLengths.MM1090,
            this.bayLengths.MM1570,
            this.bayLengths.MM2070,
            this.bayLengths.MM2570,
          ];
        } else if (loadClass <= SCAFFOLD_LOADCLASS.LOADCLASS3) {
          return [
            this.bayLengths.MM450,
            this.bayLengths.MM730,
            this.bayLengths.MM1090,
            this.bayLengths.MM1570,
            this.bayLengths.MM2070,
            this.bayLengths.MM2570,
            this.bayLengths.MM3070,
          ];
        } else {
          return [];
        }
      } else {
        return [];
      }
    } else if (plankType === this.plankTypes.ROBUST) {
      if (loadClass <= SCAFFOLD_LOADCLASS.LOADCLASS3) {
        return [
          this.bayLengths.MM150,
          this.bayLengths.MM390,
          this.bayLengths.MM450,
          this.bayLengths.MM730,
          this.bayLengths.MM1090,
          this.bayLengths.MM1570,
          this.bayLengths.MM2070,
          this.bayLengths.MM2570,
          this.bayLengths.MM3070,
        ];
      } else {
        return [];
      }
    } else if (plankType === this.plankTypes.WOOD) {
      if (loadClass === SCAFFOLD_LOADCLASS.LOADCLASS6) {
        return [
          this.bayLengths.MM150,
          this.bayLengths.MM390,
          this.bayLengths.MM450,
          this.bayLengths.MM730,
          this.bayLengths.MM1090,
          this.bayLengths.MM1570,
        ];
      } else if (loadClass === SCAFFOLD_LOADCLASS.LOADCLASS5) {
        return [
          this.bayLengths.MM150,
          this.bayLengths.MM390,
          this.bayLengths.MM450,
          this.bayLengths.MM730,
          this.bayLengths.MM1090,
          this.bayLengths.MM1570,
          this.bayLengths.MM2070,
        ];
      } else if (loadClass === SCAFFOLD_LOADCLASS.LOADCLASS4) {
        return [
          this.bayLengths.MM150,
          this.bayLengths.MM390,
          this.bayLengths.MM450,
          this.bayLengths.MM730,
          this.bayLengths.MM1090,
          this.bayLengths.MM1570,
          this.bayLengths.MM2070,
          this.bayLengths.MM2570,
        ];
      } else if (loadClass === SCAFFOLD_LOADCLASS.LOADCLASS3) {
        return [
          this.bayLengths.MM150,
          this.bayLengths.MM390,
          this.bayLengths.MM450,
          this.bayLengths.MM730,
          this.bayLengths.MM1090,
          this.bayLengths.MM1570,
          this.bayLengths.MM2070,
          this.bayLengths.MM2570,
          this.bayLengths.MM3070,
        ];
      } else {
        return [];
      }
    } else {
      return [];
    }
  };

  getBayWidths = () => {
    return Object.values(this.bayWidths);
  };

  getLoadClasses = (plankType: SCAFFOLD_PLANKTYPE) => {
    if (
      plankType === this.plankTypes.STEEL ||
      plankType === this.plankTypes.ALUMINUM
    ) {
      return [
        SCAFFOLD_LOADCLASS.LOADCLASS1,
        SCAFFOLD_LOADCLASS.LOADCLASS2,
        SCAFFOLD_LOADCLASS.LOADCLASS3,
        SCAFFOLD_LOADCLASS.LOADCLASS4,
        SCAFFOLD_LOADCLASS.LOADCLASS5,
        SCAFFOLD_LOADCLASS.LOADCLASS6,
      ];
    } else if (plankType === this.plankTypes.ROBUST) {
      return [
        SCAFFOLD_LOADCLASS.LOADCLASS1,
        SCAFFOLD_LOADCLASS.LOADCLASS2,
        SCAFFOLD_LOADCLASS.LOADCLASS3,
      ];
    } else {
      return [];
    }
  };

  getLoadingBayLengths = () => Object.values(LOADINGBAYLENGTHS);

  getLoadingBayWidths = () => Object.values(LOADINGBAYWIDTHS);

  getAnchorDensities = () => [
    SCAFFOLD_ANCHORDENSITY.EVERY_FOUR,
    SCAFFOLD_ANCHORDENSITY.EVERY_THREE,
    SCAFFOLD_ANCHORDENSITY.EVERY_TWO,
  ];

  getStandardLengths = () => [
    SCAFFOLD_STANDARDLENGTH.M4,
    SCAFFOLD_STANDARDLENGTH.M3,
    SCAFFOLD_STANDARDLENGTH.M2,
  ];

  getConsoleWidths = () => Object.values(CONSOLEWIDTHS);

  getLoadingBayLoadClasses = () => Object.values(LOADINGBAYLOADCLASSES);

  getStairWidths = () => Object.values(STAIRWIDTHS);

  getStairLengths = () => Object.values(STAIRLENGTHS);

  getBayPlankConfiguration = (bayWidth: number) => {
    // if (bayWidth === this.bayWidths.MM450) {
    //   const A = [this.plankWidths.MM320];
    //   return [A];
    if (bayWidth === this.bayWidths.MM730) {
      const A = Array(2).fill(this.plankWidths.MM320);
      const B = [this.plankWidths.MM600];
      return [A, B];
    } else if (bayWidth === this.bayWidths.MM1090) {
      const A = Array(3).fill(this.plankWidths.MM320);
      const B = [this.plankWidths.MM320, this.plankWidths.MM600];
      return [A, B];
    } else if (bayWidth === this.bayWidths.MM1400) {
      const A = Array(4).fill(this.plankWidths.MM320);
      const B = [this.plankWidths.MM600, this.plankWidths.MM600];
      return [A, B];
    } else if (bayWidth === this.bayWidths.MM1570) {
      const A = [this.plankWidths.MM190].concat(
        Array(4).fill(this.plankWidths.MM320)
      );
      return [A];
    } else if (bayWidth === this.bayWidths.MM2070) {
      const A = Array(6).fill(this.plankWidths.MM320);
      return [A];
    } else if (bayWidth === this.bayWidths.MM2570) {
      const A = [this.plankWidths.MM190].concat(
        Array(7).fill(this.plankWidths.MM320)
      );
      return [A];
    } else if (bayWidth === this.bayWidths.MM3070) {
      const A = Array(9).fill(this.plankWidths.MM320);
      return [A];
    } else {
      return [];
    }
  };

  getLoadingBayPlankConfiguration = (loadingBayWidth: number) => {
    if (loadingBayWidth === this.loadingBayWidths.MM730) {
      const A = Array(2).fill(this.plankWidths.MM320);
      const B = [this.plankWidths.MM600];
      return [A, B];
    } else if (loadingBayWidth === this.loadingBayWidths.MM1090) {
      const A = Array(3).fill(this.plankWidths.MM320);
      const B = [this.plankWidths.MM320, this.plankWidths.MM600];
      return [A, B];
    } else if (loadingBayWidth === this.loadingBayWidths.MM1400) {
      const A = Array(4).fill(this.plankWidths.MM320);
      const B = [this.plankWidths.MM600, this.plankWidths.MM600];
      return [A, B];
    } else if (loadingBayWidth === this.loadingBayWidths.MM1570) {
      const A = [this.plankWidths.MM190].concat(
        Array(4).fill(this.plankWidths.MM320)
      );
      return [A];
    } else if (loadingBayWidth === this.loadingBayWidths.MM2070) {
      const A = Array(6).fill(this.plankWidths.MM320);
      return [A];
    } else if (loadingBayWidth === this.loadingBayWidths.MM2570) {
      const A = [this.plankWidths.MM190].concat(
        Array(7).fill(this.plankWidths.MM320)
      );
      return [A];
    } else if (loadingBayWidth === this.loadingBayWidths.MM3070) {
      const A = Array(9).fill(this.plankWidths.MM320);
      return [A];
    } else {
      return [];
    }
  };

  getConsolePlankConfiguration = (consoleWidth: number) => {
    if (consoleWidth === this.consoleWidths.MM280) {
      const A = [this.plankWidths.MM190];
      return [A];
    } else if (consoleWidth === this.consoleWidths.MM390) {
      const A = [this.plankWidths.MM320];
      return [A];
    } else if (consoleWidth === this.consoleWidths.MM590) {
      const A = [this.plankWidths.MM320, this.plankWidths.MM190];
      return [A];
    } else if (consoleWidth === this.consoleWidths.MM730) {
      const A = Array(2).fill(this.plankWidths.MM320);
      return [A];
    } else {
      return [];
    }
  };

  getCornerConfigurations = () => {
    return [
      SCAFFOLD_CORNER.ABOVE_BAY,
      SCAFFOLD_CORNER.ABOVE_BAY_EXTRA,
      SCAFFOLD_CORNER.IN_BAY,
      SCAFFOLD_CORNER.IN_BAY_EXTRA,
    ];
  };

  getPlankingOptions = (t: TFunction) => {
    return [
      {
        value: this.plankTypes.STEEL + this.plankWidths.MM320,
        material: this.plankTypes.STEEL,
        label: t("options.plankingtype.alfix.steelplank"),
        width: this.plankWidths.MM320,
        orientation: SCAFFOLD_PLANKORIENTATION.LONGITUDINAL,
      },
      {
        value: this.plankTypes.ROBUST + this.plankWidths.MM600,
        material: this.plankTypes.ROBUST,
        label: t("options.plankingtype.alfix.robustplank"),
        width: this.plankWidths.MM600,
        orientation: SCAFFOLD_PLANKORIENTATION.LONGITUDINAL,
      },
      {
        value: this.plankTypes.ALUMINUM + this.plankWidths.MM320,
        material: this.plankTypes.ALUMINUM,
        label: t("options.plankingtype.alfix.aluminiumplank320"),
        width: this.plankWidths.MM320,
        orientation: SCAFFOLD_PLANKORIENTATION.LONGITUDINAL,
      },
      {
        value: this.plankTypes.ALUMINUM + this.plankWidths.MM600,
        material: this.plankTypes.ALUMINUM,
        label: t("options.plankingtype.alfix.aluminiumplank600"),
        width: this.plankWidths.MM600,
        orientation: SCAFFOLD_PLANKORIENTATION.LONGITUDINAL,
      },
      {
        value: this.plankTypes.WOOD + this.plankWidths.MM320,
        material: this.plankTypes.WOOD,
        label: t("options.plankingtype.alfix.woodplank"),
        width: this.plankWidths.MM320,
        orientation: SCAFFOLD_PLANKORIENTATION.LONGITUDINAL,
      },
    ];
  };
}

export default Alfix;
