import { createSvgIcon } from "@mui/material";

const BlueprintIcon = createSvgIcon(
  <svg viewBox="0 0 24 24" fill="currentColor">
    <path
      d="M15.7,18.3l-6-2.1V4.3l6,2.1 M21.2,2.3c-0.1,0-0.1,0-0.2,0l-5.3,2.1l-6-2.1L4,4.2C3.8,4.3,3.7,4.5,3.7,4.7v15.1
	c0,0.3,0.2,0.5,0.5,0.5c0,0,0.1,0,0.2,0l5.3-2.1l6,2.1l5.6-1.9c0.2-0.1,0.4-0.2,0.4-0.5V2.8C21.7,2.6,21.4,2.3,21.2,2.3z"
    />
  </svg>,
  "Blueprint"
);

export default BlueprintIcon;
