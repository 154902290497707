import { createSvgIcon } from "@mui/material";

const ForceLoadsOffIcon = createSvgIcon(
  <svg viewBox="0 0 24 24" fill="currentColor">
    <path d="M7.37295 7.2071L11.58 11.4142L11.7148 11.2794L7.50774 7.07232L7.37295 7.2071Z" />
    <path d="M16.7012 16.2657V21.006L12.8633 14.8829H11.3926V23.4142H12.8633V17.3029L16.6895 23.4142H18.1602V17.7247L16.7012 16.2657Z" />
    <path d="M18.1602 14.8963L18.1468 14.8829H18.1602V14.8963Z" />
    <path d="M13.129 9.86522L10.58 7.31622V1H12.58V7.58578L14.373 5.79288L15.7872 7.2071L13.129 9.86522Z" />
    <path d="M6.41797 19.6155V14.4142H5V23.4142H6.41797V21.3381L7.0639 20.6771L8.95508 23.4142H10.5898L7.97573 19.7118L10.3555 17.0744H8.65039L6.85742 19.0724L6.41797 19.6155Z" />
    <path d="M3.6142 3.15038L2.2 4.56458L19.9482 22.3128L21.3624 20.8986L3.6142 3.15038Z" />
  </svg>,
  "ForceLoads"
);

export default ForceLoadsOffIcon;
