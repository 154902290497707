import { createSvgIcon } from "@mui/material";

const ForceAnchorsOffIcon = createSvgIcon(
  <svg viewBox="0 0 24 24" fill="currentColor">
    <path d="M6.41018 6.1699L6.37298 6.2071L10.5801 10.4143L10.6173 10.377L6.41018 6.1699Z" />
    <path d="M7.82438 4.7557L10.2758 7.2071H16.7872V5.2071H10.2014L11.9943 3.4143L10.5801 2L7.82438 4.7557Z" />
    <path d="M16.7012 16.4609V19.0061L12.8633 12.883H11.3926V21.4143H12.8633V15.3029L16.6895 21.4143H18.1602V17.9199L16.7012 16.4609Z" />
    <path d="M18.1602 15.0915L16.7012 13.6325V12.883H18.1602V15.0915Z" />
    <path d="M6.41797 17.6156V12.4143H5V21.4143H6.41797V19.3381L7.0639 18.6772L8.95508 21.4143H10.5898L7.97573 17.7118L10.3555 15.0744H8.65039L6.85742 17.0725L6.41797 17.6156Z" />
    <path d="M3.4142 3.15038L2 4.56458L19.7482 22.3128L21.1624 20.8986L3.4142 3.15038Z" />
  </svg>,
  "ForceAnchors"
);

export default ForceAnchorsOffIcon;
