/** Calculates the number of days passed since the given date.
 *
 * @param {Date} from - The date to calculate from.
 * @param {Date} to - The date to calculate to. Defaults to now.
 * @returns {number} The number of days passed since the given date.
 * @example daysPassedSince({ from: new Date('2023-01-01'), to: new Date('2023-01-02') }) // 1
 */
export const daysPassedSince = (props: { from: Date; to?: Date }) => {
  const { from, to } = props;
  const now = to || new Date();
  const diff = now.getTime() - from.getTime();

  return Math.ceil(diff / (1000 * 3600 * 24));
};

/** Validates if the given date is a valid date.
 *
 * @param {Date | null} date - The date to validate.
 * @returns {boolean} True if the given date is a valid date, false otherwise.
 * @example isValidateDate(new Date('2023-01-01')) // true
 */
export const isValidDate = (date: Date | null) => {
  return date instanceof Date && !isNaN(date.getTime());
};

export const isValidStartAndEndDate = (props: {
  startDate: Date | null;
  endDate: Date | null;
}) => {
  const { startDate, endDate } = props;
  if (!startDate || !endDate) return false;

  if (!isValidDate(startDate) || !isValidDate(endDate)) return false;

  if (startDate.getTime() > endDate.getTime()) return false;

  return true;
};
