import { FC, useState } from "react";
import HelpProps from "./Help.types";
import {
  ClickAwayListener,
  Fade,
  IconButton,
  Paper,
  Popper,
  Stack,
  Typography,
} from "@mui/material";
import { ContentCopy, HelpOutline } from "@mui/icons-material";
import { ToolTip } from "@scaffcalc/packages-ui";
import { useTranslation } from "react-i18next";

const Help: FC<HelpProps> = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpen((prev) => !prev);
    setAnchorEl(event.currentTarget);
  };

  const handleOnClickCopyLink = async () => {
    /** Copy link to clipboard */
    await navigator.clipboard.writeText("support@scaffcalc.com");
  };

  return (
    <Stack direction={"row"}>
      <IconButton size="large" onClick={handleOnClick}>
        <HelpOutline
          sx={{
            width: "28px",
            height: "28px",
          }}
        />
      </IconButton>
      <Popper open={open} anchorEl={anchorEl} placement={"right"} transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps}>
            <Paper sx={{ marginLeft: "17px" }}>
              <ClickAwayListener onClickAway={() => setOpen(false)}>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  p={1}
                  gap={1}
                  bgcolor={"background.paper"}
                  borderRadius={1}
                  sx={{
                    backgroundImage:
                      "linear-gradient(rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16))",
                  }}
                >
                  <Typography variant="body1" ml={1}>
                    support@scaffcalc.com
                  </Typography>
                  <ToolTip content={t("actions.copyemail")} placement={"top"}>
                    <IconButton onClick={handleOnClickCopyLink}>
                      <ContentCopy />
                    </IconButton>
                  </ToolTip>
                </Stack>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </Stack>
  );
};

export default Help;
