import { createSvgIcon } from "@mui/material";

const StairIcon = createSvgIcon(
  <svg viewBox="0 0 24 24" fill="currentColor">
    <path
      d="M18.8,7.6l-7.9-4.4C10.7,3,10.5,3,10.3,3c-0.2,0-0.4,0-0.6,0.1L5.2,5.7C4.9,5.8,4.7,6.2,4.7,6.5v9c0,0.4,0.2,0.7,0.5,0.9
	l7.9,4.4c0.2,0.1,0.4,0.2,0.6,0.2s0.4-0.1,0.6-0.2l4.5-2.5c0.3-0.2,0.5-0.5,0.5-0.9v-9C19.3,8.1,19.1,7.7,18.8,7.6z M9.2,5.7
	l1.1-0.6l6,3.3l-1.5,0.9l-1.1,0.6l-6-3.3L9.2,5.7z M6.7,8.3l6,3.4v0.8l-2.6-1.4v1.8v1.2l-2.7-1.4v2.7L6.6,15L6.7,8.3L6.7,8.3z
	 M9,16.2v-1.1l2.7,1.4v-1.8v-1.2l1.1,0.6v4.1L9,16.2z M17.3,16.9l-2.6,1.5v-6.7l2.6-1.5V16.9z"
    />
  </svg>,
  "Stair"
);

export default StairIcon;
