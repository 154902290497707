import { createSvgIcon } from "@mui/material";

const KeyIIcon = createSvgIcon(
  <svg viewBox="0 0 24 24">
    <path
      d="M20.4211 2H3.57895C2.70692 2 2 2.70692 2 3.57895V20.4211C2 21.2931 2.70692 22 3.57895 22H20.4211C21.2931 22 22 21.2931 22 20.4211V3.57895C22 2.70692 21.2931 2 20.4211 2Z"
      stroke="currentColor"
      fill="none"
    />
    <path
      d="M12.9384 6.69995V17.364H11.1V6.69995H12.9384Z"
      fill="currentColor"
    />
  </svg>,
  "KeyI"
);

export default KeyIIcon;
