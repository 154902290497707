import {
  ArrayBayInterface,
  ArrayConsoleInterface,
  ArrayElevatorInterface,
  ArrayElevatorTieInterface,
  ArrayLoadingbayInterface,
  ArrayStairInterface,
  Bay,
  Console,
  Elevator,
  ElevatorTie,
  ELEVATOR_MANUFACTURER,
  ELEVATOR_MODEL,
  LoadingBay,
  SCAFFOLD_LOADCLASS,
  StairTower,
  StandardPositions,
  Vector3Arr,
  ArrayScaffoldInterface,
} from "@scaffcalc/backends-firebase";
import { getAddonStandardPositions, half, plus, round } from "../math";
import { Vector3 } from "three";
import { getHighestAnchorHeights } from "@scaffcalc/packages-draw/src/materials/lines/lines.utils";

/** Returns formatted bays and addons where positions are rounded to 3 decimals */
export const getFormattedBaysAndAddons = (params: {
  bays: ArrayBayInterface[];
  loadingBays: ArrayLoadingbayInterface[];
  stairs: ArrayStairInterface[];
  elevators: ArrayElevatorInterface[];
  elevatorTies: ArrayElevatorTieInterface[];
  consoles: ArrayConsoleInterface[];
  scaffolds: ArrayScaffoldInterface[];
}) => {
  let {
    bays,
    loadingBays,
    stairs,
    elevators,
    elevatorTies,
    consoles,
    scaffolds,
  } = params;

  /** Calculate world positions for standard and anchor positions, for all bays */
  const extendedBays: {
    bayId: string;
    standardPositions: {
      innerLeft: Vector3Arr;
      innerRight: Vector3Arr;
      outerLeft: Vector3Arr;
      outerRight: Vector3Arr;
    };
    anchorPositions: { left: Vector3Arr[]; right: Vector3Arr[] };
    startHeight?: number;
  }[] = [];

  scaffolds.forEach((s) => {
    const { position: swp, rotation: swr, anchorDensity } = s;

    const scaffoldBays = bays.filter((b) => b.scaffoldId === s.id);

    if (scaffoldBays.length <= 0) return;

    const sdc = new Vector3(0, 0, 1).applyAxisAngle(
      new Vector3(0, 1, 0),
      swr[1]
    );

    let accBayLength = 0;
    let prevOL = new Vector3();
    let prevIL = new Vector3();

    scaffoldBays.forEach((sb, idx) => {
      const { scale, id } = sb;

      let OR = new Vector3();
      let IR = new Vector3();

      let anchorHeights = getHighestAnchorHeights(
        undefined,
        scale[1],
        anchorDensity
      );

      if (idx > 0) {
        const prevBay = scaffoldBays[idx - 1];
        const { scale: prevScale } = prevBay;

        accBayLength = plus(accBayLength, half(prevScale[2]), half(scale[2]));
        anchorHeights = getHighestAnchorHeights(
          prevScale[1],
          scale[1],
          anchorDensity
        );
      }

      const scaffoldDirection = new Vector3(0, 0, 1).applyAxisAngle(
        new Vector3(0, 1, 0),
        swr[1]
      );
      const scaffoldDirectionInv = scaffoldDirection.clone().multiplyScalar(-1);

      const scaffoldPerpendicular = new Vector3(0, 1, 0).cross(
        scaffoldDirection
      );
      const scaffoldPerpendicularInv = scaffoldPerpendicular
        .clone()
        .multiplyScalar(-1);

      scaffoldDirection.multiplyScalar(half(scale[2]));
      scaffoldDirectionInv.multiplyScalar(half(scale[2]));
      scaffoldPerpendicular.multiplyScalar(half(scale[0]));
      scaffoldPerpendicularInv.multiplyScalar(half(scale[0]));

      const OL = scaffoldDirection
        .clone()
        .add(scaffoldPerpendicular)
        .add(sdc.clone().multiplyScalar(accBayLength))
        .add(new Vector3().fromArray(swp));
      const IL = scaffoldDirection
        .clone()
        .add(scaffoldPerpendicularInv)
        .add(sdc.clone().multiplyScalar(accBayLength))
        .add(new Vector3().fromArray(swp));

      if (idx > 0) {
        OR = prevOL.clone();
        IR = prevIL.clone();
      } else if (idx <= 0) {
        OR = scaffoldDirectionInv
          .clone()
          .add(scaffoldPerpendicular)
          .add(sdc.clone().multiplyScalar(accBayLength))
          .add(new Vector3().fromArray(swp));
        IR = scaffoldDirectionInv
          .clone()
          .add(scaffoldPerpendicularInv)
          .add(sdc.clone().multiplyScalar(accBayLength))
          .add(new Vector3().fromArray(swp));
      }

      prevOL = OL;
      prevIL = IL;

      extendedBays.push({
        bayId: id,
        startHeight: swp[1],
        anchorPositions: {
          left: anchorHeights.map(
            (a) =>
              IL.clone()
                .add(new Vector3(0, a, 0))
                .toArray()
                .map((v) => round(v, 3)) as Vector3Arr
          ),
          right: anchorHeights.map(
            (a) =>
              IR.clone()
                .add(new Vector3(0, a, 0))
                .toArray()
                .map((v) => round(v, 3)) as Vector3Arr
          ),
        },
        standardPositions: {
          innerLeft: IL.clone()
            .toArray()
            .map((v) => round(v, 3)) as Vector3Arr,
          innerRight: IR.clone()
            .toArray()
            .map((v) => round(v, 3)) as Vector3Arr,
          outerLeft: OL.clone()
            .toArray()
            .map((v) => round(v, 3)) as Vector3Arr,
          outerRight: OR.clone()
            .toArray()
            .map((v) => round(v, 3)) as Vector3Arr,
        },
      });
    });
  });

  /** Format bays */
  const baysAnalysis: Bay[] = bays.map((bay) => {
    const extBay = extendedBays.find((eb) => eb.bayId === bay.id);

    const anchorPositions = extBay?.anchorPositions ?? {
      left: [[0, 0, 0]],
      right: [[0, 0, 0]],
    };
    const highestAnchorPoint = Math.max(
      ...anchorPositions.right.map((a) => a[1]),
      ...anchorPositions.left.map((a) => a[1])
    );

    return {
      anchorPositions: extBay?.anchorPositions ?? {
        left: [],
        right: [],
      },
      bayHeight: bay.scale[1],
      bayLength: bay.scale[2],
      bayWidth: bay.scale[0],
      corners: bay.corners,
      highestAnchorPoint,
      id: bay.id,
      startHeight: extBay?.startHeight,
      standardPositions: extBay?.standardPositions ?? {
        innerLeft: [0, 0, 0],
        innerRight: [0, 0, 0],
        outerLeft: [0, 0, 0],
        outerRight: [0, 0, 0],
      },
      workingDecks: bay.platforms.length,
      fallProtectionHeight: bay.fallProtectionHeight,
      fallProtectionNet: bay.fallProtectionNet,
    };
  });

  const addons = [...stairs, ...loadingBays, ...elevators];

  /** Format loadingbays */
  const loadingBaysAnalysis: LoadingBay[] = loadingBays.map((loadingbay) => {
    const bayAddons = addons.filter(
      (addon) => addon.bayId === loadingbay.bayId
    );
    const depthPosition =
      bayAddons
        .sort((a, b) => a.position[0] - b.position[0])
        .findIndex((addon) => addon.id === loadingbay.id) + 1;

    const innerBay = baysAnalysis.find((bay) => bay.id === loadingbay.bayId);

    let addonStandardPositions: StandardPositions = {};

    if (innerBay) {
      // we need to calculate the standard positions
      addonStandardPositions = getAddonStandardPositions({
        width: loadingbay.scale[0],
        attachedBayId: innerBay.id,
        attachedBayStandardPositions: innerBay.standardPositions,
        loadingBays,
        stairs,
        addonDepthPos: depthPosition,
      });
    }

    return {
      bayHeight: loadingbay.scale[1],
      bayLength: loadingbay.scale[2],
      bayWidth: loadingbay.scale[0],
      workingDecks: loadingbay.platforms.length,
      loadClass: loadingbay.loadClass
        ? loadingbay.loadClass
        : SCAFFOLD_LOADCLASS.LOADCLASS2,
      burdenedWorkingDecks: loadingbay.burdenedWorkingDecks,
      bayId: loadingbay.bayId,
      depthPosition,
      standardPositions: addonStandardPositions,
    };
  });

  /** Format stair towers */
  const stairTowersAnalysis: StairTower[] = stairs.map((stair) => {
    const bayAddons = addons.filter((addon) => addon.bayId === stair.bayId);
    const depthPosition =
      bayAddons
        .sort((a, b) => a.position[0] - b.position[0])
        .findIndex((addon) => addon.id === stair.id) + 1;

    const innerBay = baysAnalysis.find((bay) => bay.id === stair.bayId);

    let addonStandardPositions: StandardPositions = {};

    if (innerBay) {
      // we need to calculate the standard positions
      addonStandardPositions = getAddonStandardPositions({
        width: stair.scale[0],
        attachedBayId: innerBay.id,
        attachedBayStandardPositions: innerBay.standardPositions,
        loadingBays,
        stairs,
        addonDepthPos: depthPosition,
      });
    }

    return {
      height: stair.scale[1],
      width: stair.scale[0],
      bayId: stair.bayId,
      length: stair.scale[2],
      depthPosition,
      standardPositions: addonStandardPositions,
    };
  });

  /** Format consoles */
  const consolesAnalysis: Console[] = consoles.map((console) => ({
    bayId: console.bayId,
    value: console.scale[0],
  }));

  /** Format Elevators */
  const elevatorsAnalysis: Elevator[] = elevators.map((elevator) => ({
    bayId: elevator.bayId,
    id: elevator.id,
    manufacturer: elevator.manufacturer,
    model: elevator.model,
    depthPosition:
      addons
        .filter((addon) => addon.bayId === elevator.bayId)
        .sort((a, b) => a.position[0] - b.position[0])
        .findIndex((addon) => addon.id === elevator.id) + 1,
  }));

  /** Format elevator ties */
  const elevatorTiesAnalysis: ElevatorTie[] = elevatorTies.map(
    (elevatorTie) => ({
      bayId: elevatorTie.bayId,
      elevatorId: elevatorTie.elevatorId,
      manufacturer:
        elevators.find((elevator) => elevator.id === elevatorTie.elevatorId)
          ?.manufacturer ?? ELEVATOR_MANUFACTURER.ALIMAK,
      model:
        elevators.find((elevator) => elevator.id === elevatorTie.elevatorId)
          ?.model ?? ELEVATOR_MODEL.ALIMAK_TPL1000,
      offset: elevatorTie.offset,
      orientation: elevatorTie.orientation,
      mastHeight: elevatorTie.mastHeight,
      anchors: elevatorTie.tieHeights,
      tiesHeight: Math.max(...elevatorTie.tieHeights),
    })
  );

  return {
    bays: baysAnalysis,
    consoles: consolesAnalysis,
    elevators: elevatorsAnalysis,
    elevatorTies: elevatorTiesAnalysis,
    loadingBays: loadingBaysAnalysis,
    stairTowers: stairTowersAnalysis,
  };
};
