import { createSvgIcon } from "@mui/material";

const ConsoleIcon = createSvgIcon(
  <svg viewBox="0 0 24 24" fill="currentColor">
    <path
      d="M18.8,10.6l-7.9-4.4C10.7,6,10.5,6,10.3,6c-0.2,0-0.4,0-0.6,0.1L5.2,8.7C4.9,8.8,4.7,9.2,4.7,9.5v3c0,0.4,0.2,0.7,0.5,0.9
	l7.9,4.4c0.2,0.1,0.4,0.2,0.6,0.2s0.4-0.1,0.6-0.2l4.5-2.5c0.3-0.2,0.5-0.5,0.5-0.9v-3C19.3,11.1,19.1,10.7,18.8,10.6z M12.7,15.3
	l-6-3.4v-0.7l6,3.4V15.3z M13.7,12.9l-6-3.3l2-1.2l0,0L9.2,8.7l0,0l1.1-0.6l6,3.3l-1.5,0.9l0,0l0,0l-0.4,0.2l0,0l0.4-0.2L13.7,12.9z
	 M17.3,13.9l-2.6,1.5v-0.7l2.6-1.5V13.9z"
    />
  </svg>,
  "Console"
);

export default ConsoleIcon;
