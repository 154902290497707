import { create } from "zustand";
import createUserSlice, { UserSlice } from "./user";
import createCompanySlice, { CompanySlice } from "./company";
import createLoadingSlice, { LoadingSlice } from "./loading";
import createSnackbarSlice, { SnackbarSlice } from "./snackbar";
import createRoutingSlice, { RoutingSlice } from "./routing";

/** Merge slice interfaces */
type MergedSlice = UserSlice &
  CompanySlice &
  LoadingSlice &
  RoutingSlice &
  SnackbarSlice;

const useStore = create<MergedSlice>()((...a) => ({
  ...createUserSlice(...a),
  ...createCompanySlice(...a),
  ...createLoadingSlice(...a),
  ...createRoutingSlice(...a),
  ...createSnackbarSlice(...a),
}));

/** Define selectors for export */
/** User */
export const useUserEmail = () => useStore((state) => state.userEmail);
export const useUserFirstName = () => useStore((state) => state.userFirstName);
export const useUserLastName = () => useStore((state) => state.userLastName);
export const useUserId = () => useStore((state) => state.userUId);
export const useUserPreferences = () =>
  useStore((state) => state.userPreferences);
export const useUserAuthorisation = () =>
  useStore((state) => state.userAuthorisation);
export const useUserAccountType = () =>
  useStore((state) => state.userAccountType);
export const useUserExpires = () => useStore((state) => state.userExpires);
export const useUserActions = () => useStore((state) => state.userActions);
export const useUserRole = () => useStore((state) => state.userRole);
export const useUserAchievements = () =>
  useStore((state) => state.userAchievements);

/** Company */
export const useCompanyName = () => useStore((state) => state.companyName);
export const useCompanyId = () => useStore((state) => state.companyId);
export const useCompanyUsers = () => useStore((state) => state.companyUsers);
export const useCompanyLogoUrl = () =>
  useStore((state) => state.companyLogoUrl);
export const useCompanyActions = () =>
  useStore((state) => state.companyActions);

/** Loading */
export const useLoading = () => useStore((state) => state.loading);
export const useLoadingText = () => useStore((state) => state.loadingText);
export const useLoadingActions = () =>
  useStore((state) => state.loadingActions);

/** Routing */
export const useRoutingRoutes = () => useStore((state) => state.routingRoutes);
export const useRoutingActiveRoute = () =>
  useStore((state) => state.routingActiveRoute);
export const useRoutingActions = () =>
  useStore((state) => state.routingActions);
export const useRequestedRedirectPath = () =>
  useStore((state) => state.requestedRedirectPath);
export const useRedirectRequestActions = () =>
  useStore((state) => state.redirectRequestActions);

/** Snackbar */
export const useSnackbarChildren = () =>
  useStore((state) => state.snackbarChildren);
export const useSnackbarDuration = () =>
  useStore((state) => state.snackbarDuration);
export const useSnackbarHorizontal = () =>
  useStore((state) => state.snackbarHorizontal);
export const useSnackbarOnClose = () =>
  useStore((state) => state.snackbarOnClose);
export const useSnackbarOpen = () => useStore((state) => state.snackbarOpen);
export const useSnackbarText = () => useStore((state) => state.snackbarText);
export const useSnackbarType = () => useStore((state) => state.snackbarType);
export const useSnackbarVertical = () =>
  useStore((state) => state.snackbarVertical);
export const useSnackbarActions = () =>
  useStore((state) => state.snackbarActions);
