import {
  KeyboardEventHandler,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { InputAdornment, Stack, SxProps, TextField } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import ToolTip from "./ToolTip";
import Switch from "./Switch";

export interface InputFieldProps {
  autoFocus?: boolean;
  disabled?: boolean;
  error?: boolean | undefined;
  helperText?: string;
  id?: string;
  multiline?: boolean;
  rows?: number;
  onBlur?: (value: string | number) => void;
  onChange?: any;
  onClickInfo?: any;
  onFocus?: any;
  placeholder?: string | ReactNode;
  required?: boolean;
  sx?: SxProps;
  suffix?: string;
  theme?: "light" | "dark" | null | undefined;
  type?: string | undefined;
  value?: string | number;
  defaultValue?: string | number;
  tippyText?: string;
  onClickSwitch?: () => void;
  switchChecked?: boolean;
  placeholderHint?: string;
}

const InputField = ({
  autoFocus,
  disabled,
  error,
  helperText,
  id,
  onChange,
  onClickInfo,
  onFocus,
  onBlur,
  placeholder,
  placeholderHint,
  multiline,
  rows,
  required,
  sx,
  type,
  value,
  theme,
  suffix,
  defaultValue,
  tippyText,
  onClickSwitch,
  switchChecked,
  ...other
}: InputFieldProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleOnKeyDown = useCallback<KeyboardEventHandler<HTMLInputElement>>(
    (e) => {
      if (e.key === "Enter") {
        if (onBlur && value && inputRef.current) {
          onBlur(value);
          inputRef.current.blur();
        }
      }
    },
    [onBlur, value]
  );

  const label = useMemo(() => {
    if (tippyText && value)
      return (
        <ToolTip content={tippyText} placement="right">
          <Stack direction={"row"} alignContent={"center"} flexWrap={"wrap"}>
            {placeholder}
            <InfoOutlined
              sx={{
                fill: "text.secondary",
                color: "text.secondary",
                cursor: "pointer",
                width: "20px",
                height: "20px",
                marginLeft: "8px",
              }}
            />
          </Stack>
        </ToolTip>
      );
    else return placeholder;
  }, [placeholder, tippyText, value]);

  return (
    <Stack width={"100%"} position={"relative"}>
      <TextField
        defaultValue={defaultValue}
        inputRef={inputRef}
        autoFocus={autoFocus}
        disabled={disabled}
        error={error}
        fullWidth
        helperText={helperText}
        id={id}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">{suffix}</InputAdornment>
          ),
          sx: {
            backgroundColor: "secondary.dark",
          },
        }}
        InputLabelProps={{
          sx: {
            width: "100%",
            userSelect: "none",
            "&.Mui-focused": {
              color: "text.secondary",
            },
            "&.Mui-disabled": {
              color: !switchChecked ? "text.secondary" : undefined,
            },
          },
        }}
        FormHelperTextProps={{
          style: { color: "error.main", userSelect: "none" },
        }}
        label={label}
        placeholder={placeholderHint}
        margin="dense"
        onChange={onChange}
        onFocus={onFocus}
        onKeyDown={handleOnKeyDown}
        onBlur={(e) => {
          onBlur && onBlur(value || "");
        }}
        required={required}
        autoComplete={type === "password" ? "password" : "off"}
        size="small"
        sx={{
          ...sx,
          "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          "& .MuiInputAdornment-root": {
            userSelect: "none",
          },
          "& .MuiInputAdornment-positionEnd": {
            position: "relative",
            right: onClickSwitch ? 50 : 0,
          },
        }}
        type={type}
        value={value}
        multiline={multiline ? true : false}
        rows={rows}
        {...other}
      />
      {onClickSwitch && (
        <Switch
          disabled={false}
          onChange={onClickSwitch}
          checked={switchChecked ? switchChecked : false}
          sx={{
            position: "absolute",
            right: 2,
            top: 9,
            "& .MuiFormControlLabel-root": {
              marginRight: 0,
            },
          }}
        />
      )}
      {!value && tippyText && (
        <ToolTip content={tippyText} placement="right">
          <InfoOutlined
            sx={{
              fill: "text.secondary",
              color: "text.secondary",
              cursor: "pointer",
              width: "20px",
              height: "20px",
              position: "absolute",
              right: "36px",
              top: "18px",
            }}
          />
        </ToolTip>
      )}
    </Stack>
  );
};

InputField.defaultProps = {};

export default InputField;
