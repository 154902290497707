import { createSvgIcon } from "@mui/material";

const LoadingBayIcon = createSvgIcon(
  <svg viewBox="0 0 24 24" fill="currentColor">
    <path
      d="M19.3,12.7V5c0-0.3-0.2-0.6-0.5-0.7l-7.9-3.8c-0.2-0.2-0.4-0.2-0.6-0.2s-0.4,0-0.6,0.1L5.2,2.7C4.9,2.8,4.7,3.1,4.7,3.4v7.7
	l0,0v7.8c0,0.3,0.2,0.6,0.5,0.8l7.9,3.8c0.2,0.1,0.4,0.2,0.6,0.2s0.4-0.1,0.6-0.2l4.5-2.2c0.3-0.2,0.5-0.4,0.5-0.8L19.3,12.7
	L19.3,12.7z M7.8,11.2l4.9-2.3v4.8L7.8,11.2z M7.8,13.2l3.9,1.9l-4.9,2.3v-4.8L7.8,13.2z M17.3,12.4l-2.6,1.3V7.9l2.6-1.3V12.4z
	 M9.2,2.7l1.1-0.5l6,2.9l-1.5,0.8l-1.1,0.5l-6-2.9L9.2,2.7z M11.6,7.4L6.7,9.7V4.9L11.6,7.4z M7.8,18.9l4.9-2.3v4.8L7.8,18.9z
	 M14.7,21.4v-5.8l2.2-1.1l0.4-0.2v5.8L14.7,21.4z"
    />
  </svg>,
  "LoadingBay"
);

export default LoadingBayIcon;
