import { createSvgIcon } from "@mui/material";

const BlueprintOffIcon = createSvgIcon(
  <svg viewBox="0 0 24 24" fill="currentColor">
    <rect
      x="10.8"
      y="-2.7"
      transform="matrix(0.7071 -0.7071 0.7071 0.7071 -5.2586 11.8798)"
      width="1.8"
      height="30"
    />
    <path
      d="M9.7,6.4V4.3l6,2.1v6l5.8,5.8c0.1-0.1,0.2-0.2,0.2-0.3V2.8c0-0.3-0.2-0.5-0.5-0.5c-0.1,0-0.1,0-0.2,0l-5.3,2.1l-6-2.1
			l-3.1,1L9.7,6.4z"
    />
    <path
      d="M15.7,15v3.4l-6-2.1V9l-5-5L4,4.2C3.8,4.3,3.7,4.5,3.7,4.7v15.1c0,0.3,0.2,0.5,0.5,0.5c0,0,0.1,0,0.2,0l5.3-2.1l6,2.1
			l4-1.3L15.7,15z"
    />
  </svg>,
  "BlueprintOff"
);

export default BlueprintOffIcon;
