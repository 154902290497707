import { createSvgIcon } from "@mui/material";

const GridOffIcon = createSvgIcon(
  <svg viewBox="0 0 24 24" fill="currentColor">
    <path
      d="M0,2.8l1.3-1.3l21.2,21.2L21.2,24l-2-2H4c-1.1,0-2-0.9-2-2V4.8L0,2.8 M10,4v3.7l-2-2V4H6.3l-2-2H20c1.1,0,2,0.9,2,2v15.7
	l-2-2V16h-1.7l-2-2H20v-4h-4v3.7l-2-2V10h-1.7l-2-2H14V4H10 M16,4v4h4V4H16 M16,20h1.2L16,18.8V20 M4,8h1.2L4,6.8V8 M10,14h1.2
	L10,12.8V14 M14,20v-3.2L13.2,16H10v4H14 M8,20v-4H4v4H8 M8,14v-3.2L7.2,10H4v4H8z"
    />
  </svg>,
  "GridOff"
);

export default GridOffIcon;
