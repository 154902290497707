import {
  FirebaseApp,
  FirebaseError,
  FirebaseOptions,
  getApp,
  getApps,
  initializeApp,
} from "firebase/app";
import { StorageService } from "../../storage/storage.interface";
import {
  connectStorageEmulator,
  getStorage,
  FirebaseStorage as fbStorage,
  ref,
  uploadBytes,
  uploadBytesResumable,
  deleteObject,
  getDownloadURL,
  getBlob,
} from "firebase/storage";
import { nanoid } from "nanoid";

class FirebaseStorage implements StorageService {
  private app: FirebaseApp;
  private storage: fbStorage;

  /** Initialize a Firebase Storage object
   *
   * @param props
   */

  constructor(props: {
    config: FirebaseOptions & { functionsLink: string };
    isLocalDev?: boolean;
  }) {
    const { config, isLocalDev } = props;

    this.app = !getApps().length ? initializeApp(config) : getApp();
    this.storage = getStorage(this.app);

    /** Setup local emulator */
    if (isLocalDev) this.setupLocalEmulator();
  }

  public uploadBytes = (props: {
    data: ArrayBuffer | Blob | File;
    path: string;
  }) => {
    const { data, path } = props;

    const newLogoStorageRef = ref(this.storage, path);

    return uploadBytes(newLogoStorageRef, data);
  };

  public uploadBytesResumable = (props: {
    data: ArrayBuffer | Blob | File;
    path: string;
  }) => {
    const { data, path } = props;

    const newLogoStorageRef = ref(this.storage, path);

    return {
      id: nanoid(),
      uploadTask: uploadBytesResumable(newLogoStorageRef, data),
      link: newLogoStorageRef.fullPath,
    };
  };

  public deleteObject = (props: { path: string }): Promise<void> => {
    const { path } = props;
    /** Prevents CTO from deleting entire bucket */
    if (path === "" || path === "/")
      return new Promise((resolve, reject) => {
        reject(
          new FirebaseError("storage/invalid-argument", "Path cannot be empty")
        );
      });

    const logoStorageRef = ref(this.storage, path);

    return deleteObject(logoStorageRef);
  };

  public getDownloadUrl = (props: {
    path: string;
    onSuccess: (url: string) => void;
    onError: (error: FirebaseError) => void;
  }) => {
    const { onSuccess, onError, path } = props;
    const logoStorageRef = ref(this.storage, path);
    getDownloadURL(logoStorageRef)
      .then((url) => {
        onSuccess(url);
      })
      .catch((error) => {
        onError(error);
      });
  };

  public downloadFileBlob = (props: { path: string }) => {
    const { path } = props;

    const pathref = ref(this.storage, path);

    return getBlob(pathref);
  };

  /** Setup local emulator */
  setupLocalEmulator = () => {
    connectStorageEmulator(this.storage, "localhost", 9199);
  };
}

export default FirebaseStorage;
