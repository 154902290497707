import {
  FC,
  useState,
  useMemo,
  useEffect,
  useRef,
  useCallback,
  CSSProperties,
} from "react";
import { Autocomplete } from "@scaffcalc/packages-ui";
import throttle from "lodash/throttle";

const GOOGLE_API_ID = "google-api";
const GOOGLE_API_KEY = "AIzaSyC4PmVbLIwLIwXDC8dq5CEi1Lg2qTVou48"; // This should be fetched from env
const GOOGLE_API_LIBRARIES = "places";

interface GooglePlacesAutocompleteProps {
  helperText: string;
  onChange: (value?: any) => void;
  onSelect: (value?: {
    address: string;
    latlng: { lat: number; lng: number };
    place_id: string;
    countryCode: string;
  }) => void;
  onClose?: () => void;
  placeholder: string;
  value: string;
  onSelectError?: (address: any) => void;
  error?: boolean;
  required?: boolean;
  style?: CSSProperties;
}

const GooglePlacesAutocomplete: FC<GooglePlacesAutocompleteProps> = (props) => {
  const {
    placeholder,
    value,
    onChange,
    onSelect,
    onClose,
    helperText,
    onSelectError,
    error = false,
    required,
    style,
  } = props;

  // Internal state for options in select dropdown
  const [options, setOptions] = useState<any>([]);

  // Refs
  const autocompleteService = useRef<any>(null);
  const geoCodeService = useRef<any>(null);
  const autocompleteRef = useRef<any>(null);

  const fetchSuggestions = useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(
          {
            ...request,
          },
          callback
        );
      }, 500),
    []
  );

  // Help function to get lat/lng
  const getLatLng = useCallback((result: any) => {
    const latLng = {
      lat: result.geometry.location.lat(),
      lng: result.geometry.location.lng(),
    };
    return latLng;
  }, []);

  const getCountryCode = useCallback((result: any) => {
    const countryCode = result.address_components.find((x: any) =>
      x.types.includes("country")
    );
    return countryCode.short_name as string;
  }, []);

  // Function to get Geocode for an adress
  const geocodeByAddress = useCallback(
    (value: { label: string; id: string }) => {
      const OK = window.google.maps.GeocoderStatus.OK;
      if (!geoCodeService.current) {
        return;
      }

      geoCodeService.current.geocode(
        { address: value.label },
        (results: any, status: any) => {
          if (status !== OK) {
            onSelect(undefined);
            return null;
          }

          if (results.length === 1) {
            const latlng = getLatLng(results[0]);
            const countryCode = getCountryCode(results[0]);

            onSelect({
              address: value.label,
              latlng,
              place_id: results[0].place_id,
              countryCode: countryCode,
            });
          } else {
            onSelect(undefined);

            if (onSelectError) {
              onSelectError(value.label);
            }
          }
        }
      );
    },
    [onSelect, getLatLng, getCountryCode, onSelectError]
  );

  // Handle on selection of value
  const handleOnSelect = useCallback(
    (val: any) => {
      // Dont return anything
      if (!geoCodeService.current) {
        return;
      }

      if (val !== null) {
        geocodeByAddress(val);
      }
    },
    [geocodeByAddress]
  );

  // Function to inject gmaps api in scripts tag
  const loadGMapsApi = useCallback(
    (
      api_id: string,
      api_key: string,
      api_libraries: string,
      callback: () => void
    ) => {
      const existingScript = document.getElementById(api_id);

      if (!existingScript) {
        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=${api_key}&libraries=${api_libraries}`;
        script.id = api_id;
        document.body.appendChild(script);
        script.onload = () => {
          if (callback) callback();
        };
      }

      if (existingScript && callback) callback();
    },
    []
  );

  // Handle callback for loaded gmaps in scripts
  const handleOnLoadGMaps = useCallback(() => {
    if (window.google) {
      // Update the autocomplete service
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();

      // Update the geocode service
      geoCodeService.current = new window.google.maps.Geocoder();
    }
  }, []);

  // Effect for retrieving new suggestions
  useEffect(() => {
    // Dont return anything
    if (!autocompleteService.current) {
      return undefined;
    }

    // Setup throttled function
    fetchSuggestions({ input: value }, (results: any) => {
      if (results) {
        const newOptions = results.map((x: any) => {
          return { label: x.description, id: x.place_id };
        });
        setOptions(newOptions);
      } else {
        setOptions([]);
      }
    });
  }, [value, fetchSuggestions, onChange]);

  // Effect for injecting gmaps api
  useEffect(() => {
    loadGMapsApi(
      GOOGLE_API_ID,
      GOOGLE_API_KEY,
      GOOGLE_API_LIBRARIES,
      handleOnLoadGMaps
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Autocomplete
      style={style}
      error={error}
      helperText={helperText}
      inputValue={value}
      onChange={handleOnSelect}
      onInputChange={onChange}
      onClose={onClose}
      options={options}
      placeholder={placeholder}
      ref={autocompleteRef}
      value={value}
      required={required}
    />
  );
};

export default GooglePlacesAutocomplete;
