import { createSvgIcon } from "@mui/material";

const KeyKcon = createSvgIcon(
  <svg viewBox="0 0 24 24">
    <path
      d="M20.4211 2H3.57895C2.70692 2 2 2.70692 2 3.57895V20.4211C2 21.2931 2.70692 22 3.57895 22H20.4211C21.2931 22 22 21.2931 22 20.4211V3.57895C22 2.70692 21.2931 2 20.4211 2Z"
      stroke="currentColor"
      fill="none"
    />
    <path
      d="M9.83838 6.70001V17.3641H8V6.70001H9.83838ZM16.2471 6.70001L11.9258 11.8343L9.46484 14.4417L9.14258 12.618L10.9004 10.45L14.0059 6.70001H16.2471ZM14.2695 17.3641L10.7612 12.3104L12.0283 11.0579L16.4521 17.3641H14.2695Z"
      fill="currentColor"
    />
  </svg>,
  "KeyK"
);

export default KeyKcon;
