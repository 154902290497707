import { createSvgIcon } from "@mui/material";

const KeyPIcon = createSvgIcon(
  <svg viewBox="0 0 24 24">
    <path
      d="M20.4211 2H3.57895C2.70692 2 2 2.70692 2 3.57895V20.4211C2 21.2931 2.70692 22 3.57895 22H20.4211C21.2931 22 22 21.2931 22 20.4211V3.57895C22 2.70692 21.2931 2 20.4211 2Z"
      stroke="currentColor"
      fill="none"
    />
    <path
      d="M12.0503 13.387H9.27441V11.9295H12.0503C12.5337 11.9295 12.9243 11.8514 13.2222 11.6951C13.52 11.5389 13.7373 11.324 13.874 11.0506C14.0156 10.7723 14.0864 10.4549 14.0864 10.0984C14.0864 9.76152 14.0156 9.44658 13.874 9.15361C13.7373 8.85576 13.52 8.6165 13.2222 8.43584C12.9243 8.25517 12.5337 8.16484 12.0503 8.16484H9.83838V17.3641H8V6.7H12.0503C12.8755 6.7 13.5762 6.84648 14.1523 7.13945C14.7334 7.42754 15.1753 7.82793 15.478 8.34062C15.7808 8.84843 15.9321 9.42949 15.9321 10.0838C15.9321 10.7723 15.7808 11.3631 15.478 11.8562C15.1753 12.3494 14.7334 12.7278 14.1523 12.9915C13.5762 13.2552 12.8755 13.387 12.0503 13.387Z"
      fill="currentColor"
    />
  </svg>,
  "KeyPIcon"
);

export default KeyPIcon;
