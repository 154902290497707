import { createSvgIcon } from "@mui/material";

const RulerOffIcon = createSvgIcon(
  <svg viewBox="0 0 24 24" fill="currentColor">
    <rect
      x="10.3"
      y="0.2"
      transform="matrix(0.7071 -0.7071 0.7071 0.7071 -5.6794 11.7063)"
      width="2"
      height="25.1"
    />

    <path
      d="M14.8,14.8l-6.4,6.4l-5.7-5.7l1.4-1.4l2.8,2.8l1.4-1.4l-2.8-2.8l1.4-1.4l2.8,2.8l1.4-1.4L8.5,9.9l0.7-0.7L7.8,7.8l-6.4,6.4
		c-0.8,0.8-0.8,2.1,0,2.8l5.7,5.7c0.8,0.8,2.1,0.8,2.8,0l6.4-6.4L14.8,14.8z"
    />
    <path
      d="M22.6,7.1l-5.7-5.7c-0.8-0.8-2.1-0.8-2.8,0L9.2,6.3l4.2,4.2l0.7-0.7l-2.8-2.8l1.4-1.4l2.8,2.8l1.4-1.4l-2.8-2.8l1.4-1.4
		l5.7,5.7l-4.9,4.9l1.4,1.4l4.9-4.9C23.4,9.1,23.4,7.8,22.6,7.1z"
    />
  </svg>,
  "RulerOff"
);

export default RulerOffIcon;
