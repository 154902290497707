import { FC } from "react";
import ResetPasswordProps from "./ResetPassword.types";
import { Stack, Typography } from "@mui/material";
import { Button, InputField } from "@scaffcalc/packages-ui";

const ResetPassword: FC<ResetPasswordProps> = (props) => {
  const { onConfirm, newPassword, repeatPassword, title } = props;

  /** Return: Conditional render of a reset password component */
  return (
    <>
      <Typography variant="h6" textAlign={"center"}>
        {title}
      </Typography>
      <Stack>
        <InputField
          error={newPassword.error}
          helperText={newPassword.error ? "Please provide new password" : ""}
          id="newPassword"
          onChange={newPassword.onChange}
          placeholder="New password"
          required={true}
          type="password"
          value={newPassword.value}
        />
        <InputField
          error={repeatPassword.error}
          helperText={repeatPassword.error ? "Please repeat password" : ""}
          id="repeatPassword"
          onChange={repeatPassword.onChange}
          placeholder="Repeat password"
          required={true}
          type="password"
          value={repeatPassword.value}
        />
        {newPassword.value !== repeatPassword.value && (
          <Typography color={"error.main"} my={1}>
            {"Passwords do not match"}
          </Typography>
        )}
        <Button
          color={"green"}
          fullWidth={true}
          onClick={onConfirm}
          text="Confirm"
          sx={{ mt: 2 }}
        />
      </Stack>
    </>
  );
};

export default ResetPassword;
