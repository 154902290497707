import { FC, useEffect, useMemo } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { ACTION } from "../../enums/actionEnums";
import ActionManagerProps from "./ActionManager.types";
import ResetPasswordPage from "../../pages/ResetPasswordPage";
import VerifyEmailPage from "../../pages/VerifyEmailPage";

const ActionManager: FC<ActionManagerProps> = () => {
  /** Hook: Navigation */
  const navigate = useNavigate();
  /** Hook: URL Search */
  const [searchParams] = useSearchParams();

  /** Memo: Search for "mode" in URL params */
  const mode = useMemo(() => searchParams.get("mode"), [searchParams]);
  /** Memo: Search for "oobCode" in URL params */
  const oobCode = useMemo(() => searchParams.get("oobCode"), [searchParams]);
  /** Memo: Search for "continueUrl" in URL params
   * - Enable by forwarding it to the mounted component
   */

  /** Effect: Navigate to "/" if not both mode or oobCode is available */
  useEffect(() => {
    if (!mode || !oobCode) {
      navigate("/");
    }
  }, [mode, oobCode, navigate]);

  /** Conditional return */

  return (
    <>
      {oobCode && mode === ACTION.RESET_PASSWORD && (
        <ResetPasswordPage actionCode={oobCode} />
      )}
      {oobCode && mode === ACTION.VERIFY_EMAIL && (
        <VerifyEmailPage actionCode={oobCode} />
      )}
    </>
  );
};

export default ActionManager;
