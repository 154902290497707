import { createSvgIcon } from "@mui/material";

const HouseIcon = createSvgIcon(
  <svg viewBox="0 0 24 24" fill="currentColor">
    <path
      id="home-outline"
      d="M12.7,5.5l5,4.5v7.8h-10V10L12.7,5.5 M12.7,2.8l-10,9h3v8h14v-8h3L12.7,2.8z"
    />
  </svg>,
  "House"
);

export default HouseIcon;
