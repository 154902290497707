import { createSvgIcon } from "@mui/material";

const KeyMIcon = createSvgIcon(
  <svg viewBox="0 0 24 24">
    <path
      d="M20.4211 2H3.57895C2.70692 2 2 2.70692 2 3.57895V20.4211C2 21.2931 2.70692 22 3.57895 22H20.4211C21.2931 22 22 21.2931 22 20.4211V3.57895C22 2.70692 21.2931 2 20.4211 2Z"
      stroke="currentColor"
      fill="none"
    />
    <path
      d="M7.27832 6.7H8.91895L12.0024 14.9251L15.0786 6.7H16.7192L12.647 17.3641H11.3433L7.27832 6.7ZM6.53125 6.7H8.09131L8.3623 13.8191V17.3641H6.53125V6.7ZM15.9062 6.7H17.4736V17.3641H15.6353V13.8191L15.9062 6.7Z"
      fill="currentColor"
    />
  </svg>,
  "KeyMIcon"
);

export default KeyMIcon;
