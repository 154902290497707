import { ChangeEvent, FC, useState, useCallback, useEffect } from "react";
import ResetPasswordPageProps, { RESET_MODES } from "./ResetPasswordPage.types";
import ResetPassword from "../../components/ResetPassword";
import { authentication } from "../../services";
import { useNavigate } from "react-router-dom";
import { useLoadingActions, useSnackbarActions } from "../../store";
import { Button, InputField } from "@scaffcalc/packages-ui";
import isEmail from "validator/lib/isEmail";

import { Stack, Typography } from "@mui/material";
import { ScaffcalcLogoVertical } from "../../assets/graphics";

const ResetPasswordPage: FC<ResetPasswordPageProps> = (props) => {
  const { actionCode } = props;

  /** Hook: navigate */
  const navigate = useNavigate();

  /** Hook: snackbar */
  const { setSnackbarOpen, setSnackbarType, setSnackbarText } =
    useSnackbarActions();

  /** Hook: store loadingActions */
  const { setLoading } = useLoadingActions();

  /** State: title with default value */
  const [title, setTitle] = useState("Please set your new password below");
  /** State: resetMode  */
  const [resetMode, setResetMode] = useState(RESET_MODES.SEND_NEW_LINK);
  /** State: password with default value */
  const [newPassword, setNewPassword] = useState("");
  /** State: email with default value */
  const [email, setEmail] = useState<string>();
  /** State: password2 with default value */
  const [repeatPassword, setRepeatPassword] = useState("");
  /** State: entered email */
  const [enteredEmail, setEnteredEmail] = useState<string>();
  /** State: error with default value */
  const [error, setError] = useState({
    newPassword: false,
    repeatPassword: false,
    enteredEmail: false,
  });

  const onChangeNewPassword = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setNewPassword(e.target.value);
      if (e.target.value.length > 0) {
        setError({ ...error, newPassword: false });
      }
    },
    [error]
  );

  const onChangeRepeatPassword = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setRepeatPassword(e.target.value);
      if (e.target.value.length > 0) {
        setError({ ...error, repeatPassword: false });
      }
    },
    [error]
  );

  const onChangeEnteredEmail = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setEnteredEmail(e.target.value);
      if (e.target.value.length > 0) {
        setError({ ...error, enteredEmail: false });
      }
    },
    [error]
  );

  useEffect(() => {
    authentication
      .verifyPasswordResetCode(actionCode)
      .then((email) => {
        setEmail(email);
        setTitle(`Please set your new password below for ${email}`);
        setResetMode(RESET_MODES.RESET_PASSWORD);
      })
      .catch(() => {
        setSnackbarOpen(true);
        setSnackbarType("negative");
        setSnackbarText("Invalid or expired action code");
        setResetMode(RESET_MODES.SEND_NEW_LINK);
      });
  }, [actionCode, setSnackbarOpen, setSnackbarText, setSnackbarType]);

  const performPasswordReset = useCallback(() => {
    setLoading(true);
    authentication
      .confirmPasswordReset(actionCode, newPassword)
      .then(() => {
        if (!email) {
          setLoading(false);
          return;
        }
        authentication
          .signIn(email, newPassword)
          .then(() => {
            setLoading(false);
            navigate("/home");
          })
          .catch(() => {
            setLoading(false);
            setSnackbarOpen(true);
            setSnackbarType("negative");
            setSnackbarText("Error: failed to login");
            navigate("/login");
          });
      })
      .catch(() => {
        setLoading(false);
        setSnackbarOpen(true);
        setSnackbarType("negative");
        setSnackbarText("Invalid or expired action code");
      });
  }, [
    setLoading,
    actionCode,
    newPassword,
    email,
    navigate,
    setSnackbarOpen,
    setSnackbarType,
    setSnackbarText,
  ]);

  const onClickSend = useCallback(() => {
    setLoading(true);
    if (email && isEmail(email)) {
      authentication.sendPasswordResetEmail(email);
    } else {
      setError({ ...error, enteredEmail: true });
    }
    setLoading(false);
  }, [email, error, setLoading]);

  const onClickConfirmPassword = useCallback(() => {
    if (newPassword.length === 0 && repeatPassword.length === 0) {
      return setError({ ...error, newPassword: true, repeatPassword: true });
    } else if (newPassword.length === 0) {
      return setError({ ...error, newPassword: true });
    } else if (repeatPassword.length === 0) {
      return setError({ ...error, repeatPassword: true });
    } else if (newPassword !== repeatPassword) {
      return;
    } else if (newPassword === repeatPassword) {
      performPasswordReset();
    }

    // TODO: Implement logic for resetting password
  }, [newPassword, repeatPassword, error, performPasswordReset]);

  return (
    <Stack gap={3} width={310}>
      <ScaffcalcLogoVertical width={310} style={{ marginBottom: 32 }} />
      {resetMode === RESET_MODES.RESET_PASSWORD ? (
        <ResetPassword
          title={title}
          newPassword={{
            value: newPassword,
            error: error.newPassword,
            onChange: onChangeNewPassword,
          }}
          repeatPassword={{
            value: repeatPassword,
            error: error.repeatPassword,
            onChange: onChangeRepeatPassword,
          }}
          onConfirm={onClickConfirmPassword}
        />
      ) : (
        <>
          <Typography variant="h6" textAlign={"center"}>
            {"The reset password link has expired or is invalid"}
          </Typography>
          <Typography variant="subtitle1" textAlign={"center"}>
            {"Please enter your email to send a new link"}
          </Typography>
          <InputField
            placeholder="email"
            value={enteredEmail}
            onChange={onChangeEnteredEmail}
            error={error.enteredEmail}
            helperText={error.enteredEmail ? "please enter a valid email" : ""}
            required={true}
          />
          <Button onClick={onClickSend} text={"Send new link"} color="blue" />
        </>
      )}
    </Stack>
  );
};

export default ResetPasswordPage;
