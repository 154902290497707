import {
  FirebaseApp,
  FirebaseError,
  FirebaseOptions,
  getApp,
  getApps,
  initializeApp,
} from "firebase/app";
import {
  initializeFirestore,
  Firestore,
  addDoc as addDocFirestore,
  getDoc as getDocFirestore,
  deleteDoc as deleteDocFirestore,
  doc,
  updateDoc,
  setDoc,
  onSnapshot,
  connectFirestoreEmulator,
  query,
  collection as collectionFirestore,
  where,
  writeBatch,
  getDocs as getDocsFirestore,
  orderBy,
  limit,
  limitToLast,
  startAfter,
  endBefore,
  getCountFromServer,
  DocumentSnapshot,
  documentId,
} from "firebase/firestore";
import { DatabaseService } from "../../database/database.interface";
import { DatabaseDoc } from "../../database/database.type";

class FirebaseFirestore implements DatabaseService {
  private app: FirebaseApp;
  private firestore: Firestore;

  /** Initialize a FirebaseAuthentication object
   *
   * @param props
   */
  constructor(props: {
    config: FirebaseOptions & { functionsLink: string };
    isLocalDev?: boolean;
  }) {
    const { config, isLocalDev } = props;

    this.app = !getApps().length ? initializeApp(config) : getApp();
    this.firestore = initializeFirestore(this.app, {
      experimentalAutoDetectLongPolling: true,
    });

    /** Setup local emulator */
    if (isLocalDev) this.setupLocalEmulator();
  }

  /** Get a document from a collection
   *
   * @param collection
   * @param data
   * @returns
   */
  public addDoc = (props: { collection: string; data: DatabaseDoc }) => {
    const { collection, data } = props;

    return addDocFirestore(
      collectionFirestore(this.firestore, collection),
      data
    );
  };

  /** Get a document from a collection
   *
   * @param collection
   * @param docId
   * @returns
   */
  public getDoc = (props: { collection: string; docId: string }) => {
    const { collection, docId } = props;

    return getDocFirestore(doc(this.firestore, collection, docId)).then(
      (snap) => {
        if (!snap.exists()) return null;

        return snap.data() as DatabaseDoc;
      }
    );
  };

  /** Get a document from a collection
   *
   * @param collection
   * @param docId
   * @returns
   */
  public getDocSnap = (props: { collection: string; docId: string }) => {
    const { collection, docId } = props;

    return getDocFirestore(doc(this.firestore, collection, docId));
  };

  /** Delete a document from a collection
   *
   * @param collection
   * @param docId
   * @returns
   */
  public deleteDoc = (props: { collection: string; docId: string }) => {
    const { collection, docId } = props;

    return deleteDocFirestore(doc(this.firestore, collection, docId));
  };

  /** Batch delete documents from a collection
   *
   * @param collection
   * @param docId
   * @returns
   */
  public deleteDocs = (docs: { docId: string; collection: string }[]) => {
    const batch = writeBatch(this.firestore);

    docs.forEach(({ docId, collection }) => {
      batch.delete(doc(this.firestore, collection, docId));
    });

    return batch.commit();
  };

  /** Get a collection
   * @param collection
   * @param filter
   * @param filterValue
   * @returns {Promise<DatabaseDoc[]>}
   */
  public getCollection = (props: {
    collection: string;
    filter: "companyId" | "projectId";
    filterValue: string;
  }) => {
    const { collection, filter, filterValue } = props;
    let q = query(collectionFirestore(this.firestore, collection));

    q = query(
      collectionFirestore(this.firestore, collection),
      where(filter, "==", filterValue)
    );
    return getDocsFirestore(q).then((snap) => {
      if (snap.size > 0) {
        const entries: (DatabaseDoc & { id: string })[] = [];

        snap.forEach((doc) => {
          const data = doc.data() as DatabaseDoc;
          entries.push({ ...data, id: doc.id } as DatabaseDoc & {
            id: string;
          });
        });
        return entries;
      }
      return [];
    });
  };

  /** Set a document in a collection
   *
   * @param collection
   * @param docId
   * @param newData
   * @returns
   */
  public updateDoc = (props: {
    collection: string;
    docId: string;
    newData: Partial<DatabaseDoc>;
  }) => {
    const { collection, docId, newData } = props;

    return updateDoc(doc(this.firestore, collection, docId), newData);
  };

  /** Set a document in a collection
   *
   * @param collection
   * @param docId
   * @param newData
   * @returns
   */
  public setDoc = (props: {
    collection: string;
    docId: string;
    newData: DatabaseDoc;
  }) => {
    const { collection, docId, newData } = props;

    return setDoc(doc(this.firestore, collection, docId), newData);
  };

  /** Listen to updates on a document in a collection
   *
   * @param collection
   * @param docId
   * @returns
   */
  public onUpdateDoc = (props: {
    collection: string;
    docId: string;
    onSuccess: (userDoc: DatabaseDoc & { id: string }) => void;
    onError: (error?: FirebaseError) => void;
  }) => {
    const { collection, docId, onSuccess, onError } = props;

    return onSnapshot(
      doc(this.firestore, collection, docId),
      (snap) => {
        if (snap.exists()) {
          const data = snap.data() as DatabaseDoc;
          onSuccess({ ...data, id: snap.id } as DatabaseDoc & { id: string });
        } else {
          onError();
        }
      },
      (error) => {
        onError(error);
      }
    );
  };

  /** Listen to updates on a collection
   *
   * @param props
   */
  public onUpdateCollection = (props: {
    filterValue?: string;
    collection: string;
    onSuccess: (
      docs: (DatabaseDoc & { id: string })[],
      fromCache?: boolean
    ) => void;
    onError: (error: FirebaseError) => void;
    filter?: "createdByCompanyId" | "projectId" | "companyId" | "docId";
  }) => {
    const { filterValue, filter, collection, onSuccess, onError } = props;

    // let q = collectionFirestore(this.firestore, collection);
    let q = query(collectionFirestore(this.firestore, collection));
    if (filter && filterValue) {
      q = query(q, where(filter, "==", filterValue));
    } else {
      const a = collectionFirestore(this.firestore, collection);
      return onSnapshot(
        a,
        (snap) => {
          const entries: (DatabaseDoc & { id: string })[] = [];

          if (snap.size > 0) {
            snap.forEach((doc) => {
              const data = doc.data() as DatabaseDoc;
              entries.push({ ...data, id: doc.id } as DatabaseDoc & {
                id: string;
              });
            });
          }
          onSuccess(entries, snap.metadata.fromCache);
        },
        (error) => {
          onError(error);
        }
      );
    }

    return onSnapshot(
      q,
      (snap) => {
        const entries: (DatabaseDoc & { id: string })[] = [];
        if (snap.size > 0) {
          snap.forEach((doc) => {
            const data = doc.data() as DatabaseDoc;
            entries.push({ ...data, id: doc.id } as DatabaseDoc & {
              id: string;
            });
          });
        }
        onSuccess(entries);
      },
      (error) => {
        onError(error);
      }
    );
  };

  /** Listen to updates on a collection
   *
   * @param props
   */
  public onUpdateCalcCollection = (props: {
    filterValue?: string;
    companyIdFilterValues?: string[];
    userIdFilterValues?: string[];
    docNameFilterValues?: string[];
    orderBy?: string;
    limit?: number;
    startAfterDocSnap?: DocumentSnapshot<unknown>;
    endBeforeDocSnap?: DocumentSnapshot<unknown>;
    collection: string;
    onSuccess: (docs: (DatabaseDoc & { id: string })[]) => void;
    onError: (error: FirebaseError) => void;
    filter?:
      | "calculationStatus"
      | "createdByCompanyId"
      | "createdByUserId"
      | "projectId"
      | "companyId"
      | "sentForCalculationType"
      | "docId"
      | "name";
  }) => {
    const {
      filterValue,
      filter,
      collection,
      onSuccess,
      onError,
      startAfterDocSnap,
      companyIdFilterValues,
      userIdFilterValues,
      docNameFilterValues,
      endBeforeDocSnap,
    } = props;

    let q = query(collectionFirestore(this.firestore, collection));
    let orderByField = props.orderBy;

    if (filter === "docId" && filterValue) {
      q = query(q, where(documentId(), ">=", filterValue));
      q = query(q, where(documentId(), "<", filterValue + "~"));
      orderByField = undefined;
    } else if (filter && filterValue) {
      if (filter === "calculationStatus") {
        q = query(q, where(filter, "==", filterValue));
      } else {
        q = query(q, where(filter, ">=", filterValue));
        q = query(q, where(filter, "<", filterValue + "~"));
        orderByField = undefined;
      }
    } else if (companyIdFilterValues && companyIdFilterValues.length > 0) {
      q = query(q, where("createdByCompanyId", "in", companyIdFilterValues));
    } else if (userIdFilterValues && userIdFilterValues.length > 0) {
      q = query(q, where("createdByUserId", "in", userIdFilterValues));
    } else if (docNameFilterValues && docNameFilterValues.length > 0) {
      q = query(q, where("name", "in", docNameFilterValues));
    }

    if (orderByField) {
      q = query(q, orderBy(orderByField, "desc"));
    }

    if (startAfterDocSnap) {
      q = query(q, startAfter(startAfterDocSnap));
    }

    if (endBeforeDocSnap && props.limit) {
      q = query(q, endBefore(endBeforeDocSnap));
      q = query(q, limitToLast(props.limit));
    }

    if (props.limit && !endBeforeDocSnap) {
      q = query(q, limit(props.limit));
    }

    return onSnapshot(
      q,
      (snap) => {
        const entries: (DatabaseDoc & { id: string })[] = [];
        if (snap.size > 0) {
          snap.forEach((doc) => {
            const data = doc.data() as DatabaseDoc;
            entries.push({ ...data, id: doc.id } as DatabaseDoc & {
              id: string;
            });
          });
        }
        onSuccess(entries);
      },
      (error) => {
        onError(error);
      }
    );
  };

  public getCollectionSize = (props: {
    collection: string;
    filter?:
      | "calculationStatus"
      | "createdByCompanyId"
      | "createdByUserId"
      | "projectId"
      | "companyId"
      | "sentForCalculationType"
      | "docId"
      | "name";
    companyIdFilterValues?: string[];
    userIdFilterValues?: string[];
    docNameFilterValues?: string[];
    filterValue?: string;
  }) => {
    const {
      collection,
      filterValue,
      filter,
      companyIdFilterValues,
      userIdFilterValues,
      docNameFilterValues,
    } = props;
    let q = query(collectionFirestore(this.firestore, collection));

    if (filter === "docId" && filterValue) {
      q = query(q, where(documentId(), ">=", filterValue));
      q = query(q, where(documentId(), "<", filterValue + "~"));
    } else if (filter && filterValue) {
      q = query(q, where(filter, ">=", filterValue));
      q = query(q, where(filter, "<", filterValue + "~"));
    }

    if (companyIdFilterValues && companyIdFilterValues.length > 0) {
      q = query(q, where("createdByCompanyId", "in", companyIdFilterValues));
    } else if (userIdFilterValues && userIdFilterValues.length > 0) {
      q = query(q, where("createdByUserId", "in", userIdFilterValues));
    } else if (docNameFilterValues && docNameFilterValues.length > 0) {
      q = query(q, where("name", "in", docNameFilterValues));
    }

    return getCountFromServer(q);
  };

  /** Setup local emulator */
  setupLocalEmulator = () => {
    connectFirestoreEmulator(this.firestore, "localhost", 8080);
  };
}

export default FirebaseFirestore;
