import { FC, useState, useMemo } from "react";
import { ScaffcalcLogo } from "../../assets/graphics";
import NavDrawerProps, { DrawerItemListProps } from "./NavDrawer.types";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";

const DrawerItemList: FC<DrawerItemListProps> = (props) => {
  const { items, onClickItem } = props;

  const { t } = useTranslation();

  /** Memo: no scaffold view on phone */
  const scaffoldAndTrsFreeItems = useMemo(() => {
    return items?.filter((item) => item.id !== "scaffold" && item.id !== "trs");
  }, [items]);

  return (
    <>
      {scaffoldAndTrsFreeItems && (
        <List sx={{ padding: 0, margin: "22px 30px" }}>
          {scaffoldAndTrsFreeItems.map((item, idx) => (
            <ListItem
              key={idx}
              onClick={() => onClickItem && onClickItem(item)}
              sx={{
                marginTop: idx > 0 ? "12px" : undefined,
              }}
              disablePadding
            >
              <ListItemButton sx={{ padding: 0, margin: 0 }}>
                {item.icon && (
                  <Box
                    component="div"
                    sx={{
                      width: "30px",
                      marginRight: "12px",
                      marginTop: "8px",
                    }}
                  >
                    <item.icon style={{ fill: "white" }} />
                  </Box>
                )}
                <ListItemText primary={t(`headers.${item.id}`)} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      )}
    </>
  );
};

const NavDrawer: FC<NavDrawerProps> = (props) => {
  const { items, onClickItem, onClose, open } = props;

  /** State: open/closed drawer */
  const [isOpen, setIsOpen] = useState(false);

  /** Memo: home route item */
  const homeItem = useMemo(() => {
    const match = items?.find((item) => item.id === "home");
    return match;
  }, [items]);

  return (
    <Drawer
      aria-label="nav-drawer"
      anchor="left"
      open={open || isOpen}
      onClose={() => {
        setIsOpen(false);
        if (onClose) onClose();
      }}
      sx={{ width: 280 }}
    >
      <ScaffcalcLogo
        width={"34px"}
        onClick={() => {
          onClickItem && homeItem && onClickItem(homeItem);
        }}
        style={{ margin: "30px " }}
      />
      <Divider sx={{ borderColor: "action.active" }} />
      <DrawerItemList items={items} onClickItem={onClickItem} />
    </Drawer>
  );
};

export default NavDrawer;
