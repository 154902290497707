import {
  BAYLENGTHS,
  BAYWIDTHS,
  CONSOLEWIDTHS,
  LOADINGBAYLENGTHS,
  LOADINGBAYWIDTHS,
  PLANKORIENTATIONS,
  PLANKTYPES,
  PLANKWIDTHS,
  STAIRLENGTHS,
  STAIRWIDTHS,
  STANDARDLENGTHS,
} from "./constants";
import { SCAFFOLDTYPES } from "./enums";
import Supplier from "../Supplier";
import { TFunction } from "i18next";
import {
  SCAFFOLD_ANCHORDENSITY,
  SCAFFOLD_CORNER,
  SCAFFOLD_LOADCLASS,
  SCAFFOLD_PLANKORIENTATION,
  SCAFFOLD_PLANKTYPE,
  SCAFFOLD_STANDARDLENGTH,
} from "../../../enums";

export class Speedyscaf implements Supplier {
  bayLengths: typeof BAYLENGTHS;
  bayWidths: typeof BAYWIDTHS;
  consoleWidths: typeof CONSOLEWIDTHS;
  loadingBayLengths: typeof LOADINGBAYLENGTHS;
  loadingBayWidths: typeof LOADINGBAYWIDTHS;
  plankOrientations: typeof PLANKORIENTATIONS;
  plankTypes: typeof PLANKTYPES;
  plankWidths: typeof PLANKWIDTHS;
  scaffoldType: SCAFFOLDTYPES;
  stairLengths: typeof STAIRLENGTHS;
  stairWidths: typeof STAIRWIDTHS;
  standardLengths: typeof STANDARDLENGTHS;

  constructor(scaffoldType: SCAFFOLDTYPES = SCAFFOLDTYPES.FRAME) {
    this.bayLengths = BAYLENGTHS;
    this.bayWidths = BAYWIDTHS;
    this.consoleWidths = CONSOLEWIDTHS;
    this.loadingBayLengths = LOADINGBAYLENGTHS;
    this.loadingBayWidths = LOADINGBAYWIDTHS;
    this.plankOrientations = PLANKORIENTATIONS;
    this.plankTypes = PLANKTYPES;
    this.plankWidths = PLANKWIDTHS;
    this.scaffoldType = scaffoldType;
    this.stairLengths = STAIRLENGTHS;
    this.stairWidths = STAIRWIDTHS;
    this.standardLengths = STANDARDLENGTHS;
  }

  getBayLengths = (
    plankType: SCAFFOLD_PLANKTYPE,
    loadClass: SCAFFOLD_LOADCLASS,
    plankWidth: number | undefined
  ) => {
    if (
      plankType === this.plankTypes.STEEL &&
      plankWidth === this.plankWidths.MM190
    ) {
      if (loadClass === SCAFFOLD_LOADCLASS.LOADCLASS6) {
        return [
          this.bayLengths.MM730,
          this.bayLengths.MM1090,
          this.bayLengths.MM1570,
          this.bayLengths.MM2070,
        ];
      } else if (loadClass === SCAFFOLD_LOADCLASS.LOADCLASS5) {
        return [
          this.bayLengths.MM730,
          this.bayLengths.MM1090,
          this.bayLengths.MM1570,
          this.bayLengths.MM2070,
          this.bayLengths.MM2570,
        ];
      } else if (loadClass <= SCAFFOLD_LOADCLASS.LOADCLASS4) {
        return [
          this.bayLengths.MM730,
          this.bayLengths.MM1090,
          this.bayLengths.MM1570,
          this.bayLengths.MM2070,
          this.bayLengths.MM2570,
          this.bayLengths.MM3070,
        ];
      } else {
        return [];
      }
    } else if (
      plankType === this.plankTypes.STEEL &&
      plankWidth === this.plankWidths.MM320
    ) {
      if (loadClass === SCAFFOLD_LOADCLASS.LOADCLASS6) {
        return [
          this.bayLengths.MM730,
          this.bayLengths.MM1090,
          this.bayLengths.MM1570,
          this.bayLengths.MM2070,
        ];
      } else if (loadClass === SCAFFOLD_LOADCLASS.LOADCLASS5) {
        return [
          this.bayLengths.MM730,
          this.bayLengths.MM1090,
          this.bayLengths.MM1570,
          this.bayLengths.MM2070,
          this.bayLengths.MM2570,
        ];
      } else if (loadClass === SCAFFOLD_LOADCLASS.LOADCLASS4) {
        return [
          this.bayLengths.MM730,
          this.bayLengths.MM1090,
          this.bayLengths.MM1570,
          this.bayLengths.MM2070,
          this.bayLengths.MM2570,
          this.bayLengths.MM3070,
        ];
      } else if (loadClass <= SCAFFOLD_LOADCLASS.LOADCLASS3) {
        return [
          this.bayLengths.MM730,
          this.bayLengths.MM1090,
          this.bayLengths.MM1570,
          this.bayLengths.MM2070,
          this.bayLengths.MM2570,
          this.bayLengths.MM3070,
          this.bayLengths.MM4140,
        ];
      } else {
        return [];
      }
    } else if (
      plankType === this.plankTypes.ALUMINUM &&
      plankWidth === this.plankWidths.MM320
    ) {
      if (loadClass === SCAFFOLD_LOADCLASS.LOADCLASS6) {
        return [
          this.bayLengths.MM730,
          this.bayLengths.MM1090,
          this.bayLengths.MM1570,
        ];
      } else if (loadClass === SCAFFOLD_LOADCLASS.LOADCLASS5) {
        return [
          this.bayLengths.MM730,
          this.bayLengths.MM1090,
          this.bayLengths.MM1570,
          this.bayLengths.MM2070,
        ];
      } else if (loadClass === SCAFFOLD_LOADCLASS.LOADCLASS4) {
        return [
          this.bayLengths.MM730,
          this.bayLengths.MM1090,
          this.bayLengths.MM1570,
          this.bayLengths.MM2070,
          this.bayLengths.MM2570,
        ];
      } else if (loadClass <= SCAFFOLD_LOADCLASS.LOADCLASS3) {
        return [
          this.bayLengths.MM730,
          this.bayLengths.MM1090,
          this.bayLengths.MM1570,
          this.bayLengths.MM2070,
          this.bayLengths.MM2570,
          this.bayLengths.MM3070,
        ];
      } else {
        return [];
      }
    } else if (
      plankType === this.plankTypes.ALUMINUM_PLASTIC &&
      plankWidth === this.plankWidths.MM320
    ) {
      if (loadClass === SCAFFOLD_LOADCLASS.LOADCLASS6) {
        return [this.bayLengths.MM1570];
      } else if (loadClass === SCAFFOLD_LOADCLASS.LOADCLASS5) {
        return [this.bayLengths.MM1570, this.bayLengths.MM2070];
      } else if (loadClass === SCAFFOLD_LOADCLASS.LOADCLASS4) {
        return [
          this.bayLengths.MM1570,
          this.bayLengths.MM2070,
          this.bayLengths.MM2570,
        ];
      } else if (loadClass <= SCAFFOLD_LOADCLASS.LOADCLASS3) {
        return [
          this.bayLengths.MM1570,
          this.bayLengths.MM2070,
          this.bayLengths.MM2570,
          this.bayLengths.MM3070,
        ];
      } else {
        return [];
      }
    } else if (
      plankType === this.plankTypes.ALUMINUM_PLASTIC &&
      plankWidth === this.plankWidths.MM610
    ) {
      if (loadClass <= SCAFFOLD_LOADCLASS.LOADCLASS3) {
        return [
          this.bayLengths.MM730,
          this.bayLengths.MM1090,
          this.bayLengths.MM1570,
          this.bayLengths.MM2070,
          this.bayLengths.MM2570,
          this.bayLengths.MM3070,
        ];
      } else {
        return [];
      }
    } else if (
      plankType === this.plankTypes.ALUMINUM_STEEL &&
      plankWidth === this.plankWidths.MM190
    ) {
      if (loadClass === SCAFFOLD_LOADCLASS.LOADCLASS6) {
        return [this.bayLengths.MM1570, this.bayLengths.MM2070];
      } else if (loadClass === SCAFFOLD_LOADCLASS.LOADCLASS5) {
        return [
          this.bayLengths.MM1570,
          this.bayLengths.MM2070,
          this.bayLengths.MM2570,
        ];
      } else if (loadClass <= SCAFFOLD_LOADCLASS.LOADCLASS4) {
        return [
          this.bayLengths.MM1570,
          this.bayLengths.MM2070,
          this.bayLengths.MM2570,
          this.bayLengths.MM3070,
        ];
      } else {
        return [];
      }
    } else if (
      plankType === this.plankTypes.ALUMINUM_STEEL &&
      plankWidth === this.plankWidths.MM320
    ) {
      if (loadClass === SCAFFOLD_LOADCLASS.LOADCLASS6) {
        return [this.bayLengths.MM1570];
      } else if (loadClass === SCAFFOLD_LOADCLASS.LOADCLASS5) {
        return [this.bayLengths.MM1570, this.bayLengths.MM2070];
      } else if (loadClass <= SCAFFOLD_LOADCLASS.LOADCLASS4) {
        return [
          this.bayLengths.MM1570,
          this.bayLengths.MM2070,
          this.bayLengths.MM2570,
        ];
      } else {
        return [];
      }
    } else if (
      plankType === this.plankTypes.ALUMINUM_STEEL &&
      plankWidth === this.plankWidths.MM610
    ) {
      if (loadClass === SCAFFOLD_LOADCLASS.LOADCLASS6) {
        return [
          this.bayLengths.MM730,
          this.bayLengths.MM1090,
          this.bayLengths.MM1400,
          this.bayLengths.MM1570,
          this.bayLengths.MM2070,
        ];
      } else if (loadClass === SCAFFOLD_LOADCLASS.LOADCLASS5) {
        return [
          this.bayLengths.MM730,
          this.bayLengths.MM1090,
          this.bayLengths.MM1400,
          this.bayLengths.MM1570,
          this.bayLengths.MM2070,
          this.bayLengths.MM2570,
        ];
      } else if (loadClass <= SCAFFOLD_LOADCLASS.LOADCLASS4) {
        return [
          this.bayLengths.MM730,
          this.bayLengths.MM1090,
          this.bayLengths.MM1400,
          this.bayLengths.MM1570,
          this.bayLengths.MM2070,
          this.bayLengths.MM2570,
          this.bayLengths.MM3070,
        ];
      } else {
        return [];
      }
    } else if (
      plankType === this.plankTypes.ALUMINUM_PLYWOOD &&
      plankWidth === this.plankWidths.MM610
    ) {
      if (loadClass <= SCAFFOLD_LOADCLASS.LOADCLASS3) {
        return [
          this.bayLengths.MM1570,
          this.bayLengths.MM2070,
          this.bayLengths.MM2570,
          this.bayLengths.MM3070,
        ];
      } else {
        return [];
      }
    } else {
      return [];
    }
  };

  getBayWidths = () => {
    return Object.values(this.bayWidths);
  };

  getLoadClasses = () => {
    return [
      SCAFFOLD_LOADCLASS.LOADCLASS1,
      SCAFFOLD_LOADCLASS.LOADCLASS2,
      SCAFFOLD_LOADCLASS.LOADCLASS3,
      SCAFFOLD_LOADCLASS.LOADCLASS4,
      SCAFFOLD_LOADCLASS.LOADCLASS5,
      SCAFFOLD_LOADCLASS.LOADCLASS6,
    ];
  };

  getLoadingBayLengths = () => [];

  getLoadingBayWidths = () => [];

  getAnchorDensities = () => [
    SCAFFOLD_ANCHORDENSITY.EVERY_FOUR,
    SCAFFOLD_ANCHORDENSITY.EVERY_THREE,
    SCAFFOLD_ANCHORDENSITY.EVERY_TWO,
  ];

  getStandardLengths = () => [SCAFFOLD_STANDARDLENGTH.M2];

  getConsoleWidths = (plankWidth: number | undefined) => {
    if (plankWidth === this.plankWidths.MM190) {
      return [
        this.consoleWidths.MM220,
        this.consoleWidths.MM360,
        this.consoleWidths.MM500,
        this.consoleWidths.MM730,
      ];
    } else if (plankWidth == this.plankWidths.MM320) {
      return [
        this.consoleWidths.MM360,
        this.consoleWidths.MM730,
        this.consoleWidths.MM500,
      ];
    } else {
      return [];
    }
  };

  getLoadingBayLoadClasses = () => [];

  getStairWidths = () => Object.values(STAIRWIDTHS);

  getStairLengths = () => Object.values(STAIRLENGTHS);

  getBayPlankConfiguration = (
    bayWidth: number,
    _: SCAFFOLD_PLANKORIENTATION | undefined,
    plankWidth: number | undefined
  ) => {
    if (
      bayWidth === this.bayWidths.MM730 &&
      plankWidth === this.plankWidths.MM320
    ) {
      const A = Array(2).fill(this.plankWidths.MM320);
      return [A];
    } else if (
      bayWidth === this.bayWidths.MM730 &&
      plankWidth === this.plankWidths.MM610
    ) {
      const A = [this.plankWidths.MM610];
      return [A];
    } else if (
      bayWidth === this.bayWidths.MM1090 &&
      plankWidth === this.plankWidths.MM320
    ) {
      const A = Array(3).fill(this.plankWidths.MM320);
      return [A];
    } else {
      return [];
    }
  };

  getLoadingBayPlankConfiguration = () => {
    return [];
  };

  getConsolePlankConfiguration = (consoleWidth: number) => {
    if (consoleWidth === this.consoleWidths.MM220) {
      const A = [this.plankWidths.MM190];
      return [A];
    } else if (consoleWidth === this.consoleWidths.MM360) {
      const A = [this.plankWidths.MM320];
      const B = [this.plankWidths.MM190];
      return [A, B];
    } else if (consoleWidth === this.consoleWidths.MM730) {
      const A = [this.plankWidths.MM610];
      const B = Array(2).fill(this.plankWidths.MM320);
      const C = Array(3).fill(this.plankWidths.MM190);
      return [A, B, C];
    } else if (consoleWidth === this.consoleWidths.MM500) {
      const A = Array(2).fill(this.plankWidths.MM190);
      const B = [this.plankWidths.MM320];
      return [A, B];
    } else {
      return [];
    }
  };

  getCornerConfigurations = () => {
    return [SCAFFOLD_CORNER.ABOVE_BAY_EXTRA, SCAFFOLD_CORNER.IN_BAY_EXTRA];
  };

  getPlankingOptions = (t: TFunction) => {
    return [
      {
        value: this.plankTypes.STEEL + this.plankWidths.MM190,
        material: this.plankTypes.STEEL,
        label: t("options.plankingtype.shared.steel19"),
        width: this.plankWidths.MM190,
        orientation: SCAFFOLD_PLANKORIENTATION.LONGITUDINAL,
      },
      {
        value: this.plankTypes.STEEL + this.plankWidths.MM320,
        material: this.plankTypes.STEEL,
        label: t("options.plankingtype.shared.steel32"),
        width: this.plankWidths.MM320,
        orientation: SCAFFOLD_PLANKORIENTATION.LONGITUDINAL,
      },
      {
        value: this.plankTypes.ALUMINUM + this.plankWidths.MM320,
        material: this.plankTypes.ALUMINUM,
        label: t("options.plankingtype.shared.alu32"),
        width: this.plankWidths.MM320,
        orientation: SCAFFOLD_PLANKORIENTATION.LONGITUDINAL,
      },
      {
        value: this.plankTypes.ALUMINUM_PLASTIC + this.plankWidths.MM320,
        material: this.plankTypes.ALUMINUM_PLASTIC,
        label: t("options.plankingtype.shared.aluplastic32"),
        width: this.plankWidths.MM320,
        orientation: SCAFFOLD_PLANKORIENTATION.LONGITUDINAL,
      },
      {
        value: this.plankTypes.ALUMINUM_PLASTIC + this.plankWidths.MM610,
        material: this.plankTypes.ALUMINUM_PLASTIC,
        label: t("options.plankingtype.shared.aluplastic61"),
        width: this.plankWidths.MM610,
        orientation: SCAFFOLD_PLANKORIENTATION.LONGITUDINAL,
      },
      {
        value: this.plankTypes.ALUMINUM_STEEL + this.plankWidths.MM190,
        material: this.plankTypes.ALUMINUM_STEEL,
        label: t("options.plankingtype.shared.alusteel19"),
        width: this.plankWidths.MM190,
        orientation: SCAFFOLD_PLANKORIENTATION.LONGITUDINAL,
      },
      {
        value: this.plankTypes.ALUMINUM_STEEL + this.plankWidths.MM320,
        material: this.plankTypes.ALUMINUM_STEEL,
        label: t("options.plankingtype.shared.alusteel32"),
        width: this.plankWidths.MM320,
        orientation: SCAFFOLD_PLANKORIENTATION.LONGITUDINAL,
      },
      {
        value: this.plankTypes.ALUMINUM_STEEL + this.plankWidths.MM610,
        material: this.plankTypes.ALUMINUM_STEEL,
        label: t("options.plankingtype.shared.alusteel61"),
        width: this.plankWidths.MM610,
        orientation: SCAFFOLD_PLANKORIENTATION.LONGITUDINAL,
      },
      {
        value: this.plankTypes.ALUMINUM_PLYWOOD + this.plankWidths.MM610,
        material: this.plankTypes.ALUMINUM_PLYWOOD,
        label: t("options.plankingtype.shared.aluplywood61"),
        width: this.plankWidths.MM610,
        orientation: SCAFFOLD_PLANKORIENTATION.LONGITUDINAL,
      },
    ];
  };
}

export default Speedyscaf;
