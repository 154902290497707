import {
  BAYLENGTHS,
  BAYWIDTHS,
  CONSOLEWIDTHS,
  LOADINGBAYLENGTHS,
  LOADINGBAYLOADCLASSES,
  LOADINGBAYWIDTHS,
  PLANKORIENTATIONS,
  PLANKTYPES,
  PLANKWIDTHS,
  STAIRLENGTHS,
  STAIRWIDTHS,
  STANDARDLENGTHS,
} from "./constants";
import { SCAFFOLDTYPES } from "./enums";
import Supplier from "../Supplier";
import { TFunction } from "i18next";

import {
  SCAFFOLD_ANCHORDENSITY,
  SCAFFOLD_CORNER,
  SCAFFOLD_LOADCLASS,
  SCAFFOLD_PLANKORIENTATION,
  SCAFFOLD_PLANKTYPE,
  SCAFFOLD_STANDARDLENGTH,
} from "../../../enums";

export class Plettac implements Supplier {
  bayLengths: typeof BAYLENGTHS;
  bayWidths: typeof BAYWIDTHS;
  consoleWidths: typeof CONSOLEWIDTHS;
  loadingBayLengths: typeof LOADINGBAYLENGTHS;
  loadingBayWidths: typeof LOADINGBAYWIDTHS;
  plankOrientations: typeof PLANKORIENTATIONS;
  plankTypes: typeof PLANKTYPES;
  plankWidths: typeof PLANKWIDTHS;
  scaffoldType: SCAFFOLDTYPES;
  stairLengths: typeof STAIRLENGTHS;
  stairWidths: typeof STAIRWIDTHS;
  standardLengths: typeof STANDARDLENGTHS;

  constructor(scaffoldType: SCAFFOLDTYPES = SCAFFOLDTYPES.MODULE) {
    this.bayLengths = BAYLENGTHS;
    this.bayWidths = BAYWIDTHS;
    this.consoleWidths = CONSOLEWIDTHS;
    this.loadingBayLengths = LOADINGBAYLENGTHS;
    this.loadingBayWidths = LOADINGBAYWIDTHS;
    this.plankOrientations = PLANKORIENTATIONS;
    this.plankTypes = PLANKTYPES;
    this.plankWidths = PLANKWIDTHS;
    this.scaffoldType = scaffoldType;
    this.stairLengths = STAIRLENGTHS;
    this.stairWidths = STAIRWIDTHS;
    this.standardLengths = STANDARDLENGTHS;
  }

  getBayLengths = (
    plankType: SCAFFOLD_PLANKTYPE,
    loadClass: SCAFFOLD_LOADCLASS,
    plankWidth?: number
  ) => {
    if (plankType === this.plankTypes.STEEL) {
      if (plankWidth === this.plankWidths.MM320) {
        if (loadClass === SCAFFOLD_LOADCLASS.LOADCLASS6) {
          return [
            this.bayLengths.MM400,
            this.bayLengths.MM740,
            this.bayLengths.MM750,
            this.bayLengths.MM1000,
            this.bayLengths.MM1100,
            this.bayLengths.MM1500,
            this.bayLengths.MM2000,
          ];
        } else if (loadClass === SCAFFOLD_LOADCLASS.LOADCLASS5) {
          return [
            this.bayLengths.MM400,
            this.bayLengths.MM740,
            this.bayLengths.MM750,
            this.bayLengths.MM1000,
            this.bayLengths.MM1100,
            this.bayLengths.MM1500,
            this.bayLengths.MM2000,
            this.bayLengths.MM2500,
          ];
        } else if (loadClass <= SCAFFOLD_LOADCLASS.LOADCLASS4) {
          return [
            this.bayLengths.MM400,
            this.bayLengths.MM740,
            this.bayLengths.MM750,
            this.bayLengths.MM1000,
            this.bayLengths.MM1100,
            this.bayLengths.MM1500,
            this.bayLengths.MM2000,
            this.bayLengths.MM2500,
            this.bayLengths.MM3000,
          ];
        } else {
          return [];
        }
      } else if (plankWidth === this.plankWidths.MM340) {
        if (loadClass === SCAFFOLD_LOADCLASS.LOADCLASS6) {
          return [
            this.bayLengths.MM400,
            this.bayLengths.MM740,
            this.bayLengths.MM750,
            this.bayLengths.MM1000,
            this.bayLengths.MM1500,
            this.bayLengths.MM2000,
          ];
        } else if (loadClass === SCAFFOLD_LOADCLASS.LOADCLASS5) {
          return [
            this.bayLengths.MM400,
            this.bayLengths.MM740,
            this.bayLengths.MM750,
            this.bayLengths.MM1000,
            this.bayLengths.MM1500,
            this.bayLengths.MM2000,
            this.bayLengths.MM2500,
          ];
        } else if (loadClass <= SCAFFOLD_LOADCLASS.LOADCLASS4) {
          return [
            this.bayLengths.MM400,
            this.bayLengths.MM740,
            this.bayLengths.MM750,
            this.bayLengths.MM1000,
            this.bayLengths.MM1500,
            this.bayLengths.MM2000,
            this.bayLengths.MM2500,
            this.bayLengths.MM3000,
          ];
        } else {
          return [];
        }
      } else {
        return [];
      }
    } else if (
      plankType === this.plankTypes.ALUMINUM &&
      plankWidth === this.plankWidths.MM320
    ) {
      if (loadClass === SCAFFOLD_LOADCLASS.LOADCLASS6) {
        return [this.bayLengths.MM1500, this.bayLengths.MM2000];
      } else if (loadClass === SCAFFOLD_LOADCLASS.LOADCLASS5) {
        return [
          this.bayLengths.MM400,
          this.bayLengths.MM740,
          this.bayLengths.MM750,
          this.bayLengths.MM1500,
          this.bayLengths.MM2000,
          this.bayLengths.MM2500,
        ];
      } else if (loadClass <= SCAFFOLD_LOADCLASS.LOADCLASS4) {
        return [
          this.bayLengths.MM400,
          this.bayLengths.MM740,
          this.bayLengths.MM750,
          this.bayLengths.MM1500,
          this.bayLengths.MM2000,
          this.bayLengths.MM2500,
          this.bayLengths.MM3000,
        ];
      } else {
        return [];
      }
    } else if (
      plankType === this.plankTypes.WOOD &&
      plankWidth === this.plankWidths.MM320
    ) {
      if (loadClass === SCAFFOLD_LOADCLASS.LOADCLASS6) {
        return [this.bayLengths.MM400, this.bayLengths.MM1500];
      } else if (loadClass === SCAFFOLD_LOADCLASS.LOADCLASS5) {
        return [
          this.bayLengths.MM400,
          this.bayLengths.MM740,
          this.bayLengths.MM750,
          this.bayLengths.MM1500,
          this.bayLengths.MM2000,
        ];
      } else if (loadClass === SCAFFOLD_LOADCLASS.LOADCLASS4) {
        return [
          this.bayLengths.MM400,
          this.bayLengths.MM740,
          this.bayLengths.MM750,
          this.bayLengths.MM1500,
          this.bayLengths.MM2000,
          this.bayLengths.MM2500,
        ];
      } else if (loadClass <= SCAFFOLD_LOADCLASS.LOADCLASS3) {
        return [
          this.bayLengths.MM400,
          this.bayLengths.MM740,
          this.bayLengths.MM750,
          this.bayLengths.MM1500,
          this.bayLengths.MM2000,
          this.bayLengths.MM2500,
          this.bayLengths.MM3000,
        ];
      } else {
        return [];
      }
    } else {
      return [];
    }
  };

  getBayWidths = () => Object.values(this.bayWidths);

  getLoadClasses = () => [
    SCAFFOLD_LOADCLASS.LOADCLASS1,
    SCAFFOLD_LOADCLASS.LOADCLASS2,
    SCAFFOLD_LOADCLASS.LOADCLASS3,
    SCAFFOLD_LOADCLASS.LOADCLASS4,
    SCAFFOLD_LOADCLASS.LOADCLASS5,
    SCAFFOLD_LOADCLASS.LOADCLASS6,
  ];

  getLoadingBayLengths = () => Object.values(LOADINGBAYLENGTHS);

  getLoadingBayWidths = () => Object.values(LOADINGBAYWIDTHS);

  getAnchorDensities = () => [
    SCAFFOLD_ANCHORDENSITY.EVERY_FOUR,
    SCAFFOLD_ANCHORDENSITY.EVERY_THREE,
    SCAFFOLD_ANCHORDENSITY.EVERY_TWO,
  ];

  getStandardLengths = () => [
    SCAFFOLD_STANDARDLENGTH.M4,
    SCAFFOLD_STANDARDLENGTH.M3,
    SCAFFOLD_STANDARDLENGTH.M2,
  ];

  getConsoleWidths = () => Object.values(CONSOLEWIDTHS);

  getLoadingBayLoadClasses = () => Object.values(LOADINGBAYLOADCLASSES);

  getStairWidths = () => Object.values(STAIRWIDTHS);

  getStairLengths = () => Object.values(STAIRLENGTHS);

  getBayPlankConfiguration = (
    bayWidth: number,
    plankOrientation?: SCAFFOLD_PLANKORIENTATION,
    plankWidth?: number
  ) => this.calculatePlankConfiguration(bayWidth, plankWidth);

  getLoadingBayPlankConfiguration = (
    loadingBayWidth: number,
    plankOrientation?: SCAFFOLD_PLANKORIENTATION,
    plankWidth?: number
  ) => this.calculatePlankConfiguration(loadingBayWidth, plankWidth);

  getConsolePlankConfiguration = (
    consoleWidth: number,
    plankOrientation?: SCAFFOLD_PLANKORIENTATION,
    plankWidth?: number
  ) => this.calculatePlankConfiguration(consoleWidth, plankWidth);

  private calculatePlankConfiguration = (
    bayWidth: number,
    plankWidth?: number
  ) => {
    if (plankWidth) {
      const nPlanks = Math.floor(bayWidth / plankWidth);

      if (Object.values(this.bayWidths).includes(bayWidth)) {
        const A = Array(nPlanks).fill(plankWidth);
        return [A];
      } else {
        return [];
      }
    } else {
      return [];
    }
  };

  getCornerConfigurations = () => {
    return [
      SCAFFOLD_CORNER.ABOVE_BAY,
      SCAFFOLD_CORNER.ABOVE_BAY_EXTRA,
      SCAFFOLD_CORNER.IN_BAY,
      SCAFFOLD_CORNER.IN_BAY_EXTRA,
    ];
  };

  getPlankingOptions = (t: TFunction) => {
    return [
      {
        value: this.plankTypes.ALUMINUM + this.plankWidths.MM320,
        material: this.plankTypes.ALUMINUM,
        label: t("options.plankingtype.shared.alu32"),
        width: this.plankWidths.MM320,
        orientation: SCAFFOLD_PLANKORIENTATION.LONGITUDINAL,
      },
      {
        value: this.plankTypes.STEEL + this.plankWidths.MM320,
        material: this.plankTypes.STEEL,
        label: t("options.plankingtype.shared.steel32"),
        width: this.plankWidths.MM320,
        orientation: SCAFFOLD_PLANKORIENTATION.LONGITUDINAL,
      },
      {
        value: this.plankTypes.WOOD + this.plankWidths.MM320,
        material: this.plankTypes.WOOD,
        label: t("options.plankingtype.shared.wood32"),
        width: this.plankWidths.MM320,
        orientation: SCAFFOLD_PLANKORIENTATION.LONGITUDINAL,
      },
      {
        value: this.plankTypes.STEEL + this.plankWidths.MM340,
        material: this.plankTypes.STEEL,
        label: t("options.plankingtype.shared.steel34"),
        width: this.plankWidths.MM340,
        orientation: SCAFFOLD_PLANKORIENTATION.LONGITUDINAL,
      },
    ];
  };
}

export default Plettac;
