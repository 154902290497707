import { createSvgIcon } from "@mui/material";

const AutoIcon = createSvgIcon(
  <svg viewBox="0 0 24 24" fill="currentColor">
    <path
      d="M19.3,8.1l-1.1-2.5l-2.6-1.1l2.6-1.1L19.3,1l1.1,2.4L23,4.5l-2.6,1.1L19.3,8.1z M19.3,23l-1.1-2.4l-2.6-1.1l2.6-1.1l1.1-2.5
	l1.1,2.5l2.6,1.1l-2.6,1.1L19.3,23z M8.4,19.2l-2.3-4.9l-5-2.2l5-2.2l2.3-4.9l2.3,4.9l5,2.2l-5,2.2L8.4,19.2z M8.4,15.5l1.2-2.4
	L12,12l-2.4-1.1L8.4,8.5l-1.2,2.4L4.7,12l2.5,1.1L8.4,15.5z"
    />
  </svg>,
  "Auto"
);

export default AutoIcon;
