import { createSvgIcon } from "@mui/material";

const RealComponentsIcon = createSvgIcon(
  <svg viewBox="0 0 24 24" fill="currentColor">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.6 1C9.6 0.723877 9.37614 0.5 9.1 0.5H8.5C8.22386 0.5 8 0.723877 8 1V3.25885L4.1 4.67706V3C4.1 2.72388 3.87614 2.5 3.6 2.5H3C2.72386 2.5 2.5 2.72388 2.5 3V18C2.5 18.2761 2.72386 18.5 3 18.5H3.6C3.87614 18.5 4.1 18.2761 4.1 18V15.3749L14.5 19.7083V23C14.5 23.2761 14.7239 23.5 15 23.5H15.6C15.8761 23.5 16.1 23.2761 16.1 23V19.7411L20 18.3229V21C20 21.2761 20.2239 21.5 20.5 21.5H21.1C21.3761 21.5 21.6 21.2761 21.6 21V6C21.6 5.72388 21.3761 5.5 21.1 5.5H20.5C20.2239 5.5 20 5.72388 20 6V7.625L9.6 3.29169V1ZM8 12.2589L4.1 13.6771V6.375L8 8V12.2589ZM20 16.6251L16.1 15.0001V10.7411L20 9.32294V16.6251ZM9.6 12.2917L14.5 14.3334V10.7083L9.6 8.66663V12.2917Z"
    />
  </svg>,
  "RealComponents"
);

export default RealComponentsIcon;
