interface VerifyEmailPageProps {
  actionCode: string;
}

export enum VERIFY_MODES {
  PASSWORD_LINK_SENT = "passwordlinkset",
  RESEND_LINK = "resendlink",
}

export default VerifyEmailPageProps;
