import { createSvgIcon } from "@mui/material";

const CylinderIcon = createSvgIcon(
  <svg viewBox="0 0 24 24" fill="currentColor">
    <path
      d="M12,2C9.9,2,3,2.3,3,6v12c0,3.7,6.9,4,9,4s9-0.3,9-4V6C21,2.3,14.1,2,12,2z M12,20c-3.9,0-7-0.9-7-2c0-1.1,3.1-2,7-2
	s7,0.9,7,2C19,19.1,15.9,20,12,20z M19,15.2c-2.2-1.1-5.6-1.2-7-1.2s-4.8,0.1-7,1.2V8.8c2.2,1.1,5.6,1.2,7,1.2s4.8-0.1,7-1.2V15.2z
	 M12,8C8.1,8,5,7.1,5,6c0-1.1,3.1-2,7-2s7,0.9,7,2C19,7.1,15.9,8,12,8z"
    />
  </svg>,
  "Cylinder"
);

export default CylinderIcon;
