import { createSvgIcon } from "@mui/material";

const MeasureIcon = createSvgIcon(
  <svg viewBox="0 0 24 24">
    <path
      d="M18.6834 5.3166L19.6826 5.27779L18.7222 4.31735L18.6834 5.3166ZM5.31658 18.6834L4.31734 18.7222L4.35325 19.6468L5.27777 19.6827L5.31658 18.6834ZM17.9763 4.60949L4.60948 17.9763L6.02369 19.3905L19.3905 6.02371L17.9763 4.60949ZM5.27777 19.6827L10.4929 19.8852L10.5705 17.8867L5.35539 17.6842L5.27777 19.6827ZM6.31583 18.6446L6.11327 13.4295L4.11477 13.5071L4.31734 18.7222L6.31583 18.6446ZM17.6842 5.35541L17.8867 10.5705L19.8852 10.4929L19.6826 5.27779L17.6842 5.35541ZM18.7222 4.31735L13.5071 4.11479L13.4295 6.11328L18.6446 6.31585L18.7222 4.31735ZM15.5773 1.45711L22.5429 8.4227L23.9571 7.00848L16.9915 0.0428933L15.5773 1.45711ZM0.0428933 16.9915L7.00848 23.9571L8.4227 22.5429L1.45711 15.5773L0.0428933 16.9915Z"
      fill="currentColor"
    />
  </svg>,

  "Measure"
);

export default MeasureIcon;
