import { createSvgIcon } from "@mui/material";

const KeyRIcon = createSvgIcon(
  <svg viewBox="0 0 24 24">
    <path
      d="M20.4211 2H3.57895C2.70692 2 2 2.70692 2 3.57895V20.4211C2 21.2931 2.70692 22 3.57895 22H20.4211C21.2931 22 22 21.2931 22 20.4211V3.57895C22 2.70692 21.2931 2 20.4211 2Z"
      stroke="currentColor"
      fill="none"
    />
    <path
      d="M8.19922 6.7H11.9712C12.7817 6.7 13.4727 6.82207 14.0439 7.06621C14.6152 7.31035 15.0522 7.67168 15.355 8.15019C15.6626 8.62383 15.8164 9.20976 15.8164 9.908C15.8164 10.4402 15.7188 10.909 15.5234 11.3143C15.3281 11.7195 15.0522 12.0613 14.6958 12.3396C14.3394 12.6131 13.9146 12.8255 13.4214 12.9769L12.8647 13.2478H9.47363L9.45898 11.7903H12.0005C12.4399 11.7903 12.8062 11.7122 13.0991 11.556C13.3921 11.3997 13.6118 11.1873 13.7583 10.9187C13.9097 10.6453 13.9854 10.3377 13.9854 9.9959C13.9854 9.6248 13.9121 9.30254 13.7656 9.0291C13.624 8.75078 13.4043 8.53838 13.1064 8.39189C12.8086 8.24052 12.4302 8.16484 11.9712 8.16484H10.0376V17.3641H8.19922V6.7ZM14.2856 17.3641L11.7808 12.574L13.707 12.5667L16.2485 17.2688V17.3641H14.2856Z"
      fill="currentColor"
    />
  </svg>,
  "KeyR"
);

export default KeyRIcon;
