import { FIREBASE_PROJECTID } from "../src/firebase/firebase.types";

import { FirebaseStorage } from "../src/firebase";
import getConfig from "../src/firebase/firebase.config";
import { StorageServiceProvider } from "./storage.enums";

export const initStorageService = (storageProvider: StorageServiceProvider) => {
  /** Extract projectId from env. variables */
  const envProjectId = process.env.REACT_APP_FB_ENV as FIREBASE_PROJECTID;

  if (storageProvider === StorageServiceProvider.FIREBASE) {
    /** Get firebase config and return the FirebaseAuthentication service as the authService */
    const firebaseConfig = getConfig(envProjectId);
    return new FirebaseStorage({
      config: firebaseConfig,
      isLocalDev: envProjectId === FIREBASE_PROJECTID.LOCAL,
    });
  }

  /** Default return: Get firebase config and return the FirebaseAuthentication service as the authService */
  const firebaseConfig = getConfig(envProjectId);
  return new FirebaseStorage({
    config: firebaseConfig,
    isLocalDev: envProjectId === FIREBASE_PROJECTID.LOCAL,
  });
};
