import Dexie, { Table } from "dexie";
import { Vector3Tuple } from "three";

export interface BlueprintTable {
  id: string;
  position: Vector3Tuple;
  rotation: Vector3Tuple;
  image: Blob;
  height: number;
  width: number;
  opacity?: number;
}

class ModdedDexie extends Dexie {
  /** See https://dexie.org/docs/Tutorial/React#using-typescript */
  models!: Table<BlueprintTable>;

  constructor() {
    super("scaffcalc-blueprints-db");
    this.version(1).stores({
      models: "++id, position, rotation, image, height, width, opacity",
    });
  }
}

/** Wrapped ModdedDexie to expose used functions */
class DbBlueprint extends ModdedDexie {
  public saveToDb = async (item: BlueprintTable, id: string) => {
    await this.deleteFromDb(id);
    await this.models.add(item, id);
  };

  public saveOrUpdateToDb = async (item: BlueprintTable, id: string) => {
    await this.models.put(item, id);
  };

  public readFromDb = async (id: string) => {
    return this.models.where("id").equals(id).toArray();
  };

  public readAllFromDb = async () => {
    return this.models.toArray();
  };

  public deleteFromDb = async (id: string) => {
    await this.models.delete(id);
  };

  public clearDb = async () => {
    await this.models.clear();
  };
}

export default DbBlueprint;
