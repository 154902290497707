import { FirebaseError } from "firebase/app";
import { StorageServiceProvider } from "./storage.enums";
import { StorageService } from "./storage.interface";
import { UploadResult } from "firebase/storage";
import { initStorageService } from "./storage.utils";
import { IfcModel } from "@scaffcalc/packages-draw/src/hooks/zustand/slices/createIfcSlice";
import { IfcProperties, Serializer } from "bim-fragment";
import { Blueprint } from "@scaffcalc/packages-draw/src/hooks/zustand/slices/createBlueprintSlice";
import {
  BlueprintFirestore,
  IfcModelFirestore,
} from "@scaffcalc/backends-firebase";

class Storage {
  private storageService: StorageService;

  public constructor(storageService = StorageServiceProvider.FIREBASE) {
    this.storageService = initStorageService(storageService);
  }

  public uploadCompanyLogo = async (props: {
    companyId: string;
    data: ArrayBuffer;
    onSuccess: (res: UploadResult) => void;
    onError: (error: FirebaseError) => void;
  }) => {
    const { companyId, onSuccess, onError, data } = props;
    const companyLogosPath = `companies/${companyId}/logo.png`;

    this.storageService
      .uploadBytes({
        data,
        path: companyLogosPath,
      })
      .then(onSuccess)
      .catch(onError);
  };

  public deleteCompanyLogo = (props: { companyId: string }) => {
    const { companyId } = props;
    const companyLogosPath = `companies/${companyId}/logo.png`;

    return this.storageService.deleteObject({
      path: companyLogosPath,
    });
  };

  public getCompanyLogoDownloadUrl = (props: {
    companyId: string;
    onSuccess: (url: string) => void;
    onError: (error: FirebaseError) => void;
  }) => {
    const { companyId, onSuccess, onError } = props;
    const companyLogosPath = `companies/${companyId}/logo.png`;

    this.storageService.getDownloadUrl({
      path: companyLogosPath,
      onSuccess,
      onError,
    });
  };

  public uploadIfcFragmentsFiles = (props: {
    companyId: string;
    models: IfcModel[];
    projectId: string;
    scaffoldId: string;
    userId: string;
    properties?: IfcProperties;
  }) => {
    const { companyId, userId, projectId, scaffoldId, models, properties } =
      props;

    const commonPath = `companies/${companyId}/users/${userId}/projects/${projectId}/scaffolds/${scaffoldId}/`;

    const fragSerializer = new Serializer();

    return models.map((model) => {
      const { fragments, ...rest } = model;

      const fragmentsBinary = fragSerializer.export(fragments);
      const fragmentsFile = new File(
        [fragmentsBinary],
        `ifc-fragment_${model.id}.frag`
      );
      const fragmentsFilePath = `${commonPath}/ifc-fragment_${model.id}.frag`;

      const propertiesString = JSON.stringify(
        properties ? properties : fragments.properties
      );
      const propertiesFile = new File(
        [propertiesString],
        `ifc-properties_${model.id}.json`
      );
      const propertiesFilePath = `${commonPath}/ifc-properties_${model.id}.json`;

      const fragmentsUploadTask = this.storageService.uploadBytesResumable({
        data: fragmentsFile,
        path: fragmentsFilePath,
      });
      const propertiesUploadTask = this.storageService.uploadBytesResumable({
        data: propertiesFile,
        path: propertiesFilePath,
      });

      const ifcModelFirestore: IfcModelFirestore = {
        ...rest,
        fragmentsLink: fragmentsUploadTask.link,
        propertiesLink: propertiesUploadTask.link,
      };

      return {
        tasks: [fragmentsUploadTask, propertiesUploadTask],
        ifcModelFirestore,
      };
    });
  };

  public downloadIfcFile = (props: { path: string }) => {
    return this.storageService.downloadFileBlob(props);
  };

  public deleteIFcFile = (props: {
    fragmentsPath: string;
    propertiesPath: string;
  }) => {
    const fragProm = this.storageService.deleteObject({
      path: props.fragmentsPath,
    });
    const propProm = this.storageService.deleteObject({
      path: props.propertiesPath,
    });

    return [fragProm, propProm];
  };

  public uploadBlueprintFiles = (props: {
    blueprints: Blueprint[];
    companyId: string;
    projectId: string;
    scaffoldId: string;
    userId: string;
  }) => {
    const { blueprints, companyId, userId, projectId, scaffoldId } = props;

    const blueprintPaths = blueprints.map((x) => ({
      fileName: `blueprint_${x.id}`,
      fileType: "jpg",
      url: x.imageUrl,
    }));

    const blueprintsWithBlob = Promise.all(
      blueprintPaths.map((blueprint) => {
        const { url, ...rest } = blueprint;

        return fetch(url)
          .then((r) => r.blob())
          .then((blob) => ({ ...rest, blob }));
      })
    );

    const commonPath = `companies/${companyId}/users/${userId}/projects/${projectId}/scaffolds/${scaffoldId}/`;
    return blueprintsWithBlob.then((blueprintWithBlob) => {
      const uploadTasks = blueprintWithBlob.map((blueprint, idx) => {
        const { fileName, fileType, blob } = blueprint;

        const blueprintFilePath = `${commonPath}/${fileName}.${fileType}`;

        const uploadTask = this.storageService.uploadBytesResumable({
          data: blob,
          path: blueprintFilePath,
        });

        /** Generate the firestore blueprint prop */
        const originalBlueprint = blueprints[idx];
        const { imageUrl, opacity, ...rest } = originalBlueprint;
        const blueprintFirestore: BlueprintFirestore = {
          ...rest,
          ...(opacity && { opacity }),
          link: uploadTask.link,
        };

        return { ...uploadTask, blueprintFirestore };
      });

      return uploadTasks;
    });
  };

  public downloadBlueprintFiles = (props: { paths: string[] }) => {
    const { paths } = props;
    const downloadPromises = paths.map((p) =>
      this.storageService.downloadFileBlob({ path: p })
    );

    return Promise.all(downloadPromises);
  };

  public deleteBlueprintFiles = (props: { paths: string[] }) => {
    const { paths } = props;

    return paths.map((p) => this.storageService.deleteObject({ path: p }));
  };
}

/** Default Storage object */
const storage = new Storage();

export { storage };
export default Storage;
