import { ButtonBase } from "@mui/material";
import { FC, ReactNode } from "react";

export interface ButtonBaseInterface {
  children: ReactNode;
  onClick?: () => void;
}

const ButtonBaseCustom: FC<ButtonBaseInterface> = (props) => {
  const { children, onClick } = props;

  return <ButtonBase onClick={onClick}>{children}</ButtonBase>;
};

export default ButtonBaseCustom;
