import { FC, useCallback } from "react";
import { useMediaQuery } from "usehooks-ts";
import { MEDIA_QUERY } from "../../enums/mediaQueryEnums";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import NavLayoutProps from "./NavLayout.types";
import Box from "@mui/material/Box";
import { ROUTE } from "../../enums/routingEnums";
import { Route } from "../../store/routing/routing.types";
import { useRedirectRequestActions } from "../../store";
import NavBar from "../../components/NavBar/NavBar";

const NavLayout: FC<NavLayoutProps> = (props) => {
  const { navbarProps } = props;

  /** Hook: Check if user is on mobile */
  const isMobileDevice = useMediaQuery(MEDIA_QUERY.MOBILE);

  /** Hook: navigation */
  const navigate = useNavigate();

  /** Hook: */
  const { setRequestedRedirectPath } = useRedirectRequestActions();

  /** Hook:  */
  const location = useLocation();

  /** Callback:  */
  const handleOnClickNavItem = useCallback(
    (routeItem: Route) => {
      /** If existing is create/scaffold */
      const scaffoldRegex = new RegExp(`^${ROUTE.SCAFFOLD}/[a-zA-Z0-9]+$`);
      const trsRegex = new RegExp(`^${ROUTE.TRS}/[a-zA-Z0-9]+$`);

      if (
        (location.pathname === ROUTE.SCAFFOLD &&
          routeItem.route === ROUTE.SCAFFOLD) ||
        location.pathname.match(scaffoldRegex) ||
        (location.pathname === ROUTE.TRS && routeItem.route === ROUTE.TRS) ||
        location.pathname.match(trsRegex)
      ) {
        setRequestedRedirectPath(routeItem.route);
      } else {
        navigate(routeItem.route);
      }
    },
    [location.pathname, navigate, setRequestedRedirectPath]
  );

  return (
    <Box
      component="div"
      sx={{
        display: "flex",
        flexDirection: isMobileDevice ? "column" : "row",
        height: "100%",
        left: 0,
        position: "absolute",
        top: 0,
        width: "100%",
      }}
    >
      <NavBar onClickItem={handleOnClickNavItem} {...navbarProps} />
      <Box component="div" sx={{ flex: 1, position: "relative" }}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default NavLayout;
