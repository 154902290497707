import { createSvgIcon } from "@mui/material";

const MoveIcon = createSvgIcon(
  <svg viewBox="0 0 24 24" fill="currentColor">
    <path d="M12.0002 1.58579L16.2073 5.79289L14.793 7.20711L13.0002 5.41421V11H18.5859L16.793 9.20711L18.2073 7.79289L22.4144 12L18.2073 16.2071L16.793 14.7929L18.5859 13H13.0002V18.5858L14.793 16.7929L16.2073 18.2071L12.0002 22.4142L7.79304 18.2071L9.20726 16.7929L11.0002 18.5858V13H5.41436L7.20726 14.7929L5.79304 16.2071L1.58594 12L5.79304 7.79289L7.20726 9.20711L5.41436 11H11.0002V5.41421L9.20726 7.20711L7.79304 5.79289L12.0002 1.58579Z" />
  </svg>,
  "Move"
);

export default MoveIcon;
