import { ApiServiceProvider } from "./api.types";
import { FirebaseFunctions } from "../src/firebase";
import { FIREBASE_PROJECTID } from "../src/firebase/firebase.types";
import getConfig from "../src/firebase/firebase.config";

export const initApiService = (serviceProvider: ApiServiceProvider) => {
  /** Extract projectId from env. variables */
  const envProjectId = process.env.REACT_APP_FB_ENV as FIREBASE_PROJECTID;

  if (serviceProvider === ApiServiceProvider.FIREBASE) {
    /** Get firebase config and return the FirebaseAuthentication service as the authService */
    const firebaseConfig = getConfig(envProjectId);
    return new FirebaseFunctions({
      config: firebaseConfig,
    });
  }

  /** Default return: Get firebase config and return the FirebaseAuthentication service as the authService */
  const firebaseConfig = getConfig(envProjectId);
  return new FirebaseFunctions({
    config: firebaseConfig,
  });
};
