import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { FC } from "react";

export interface RadioButtonGroupInterface {
  label: string;
  value: string | undefined;
  options: { value: string; label: string }[];
  direction: "row" | "column";
  onChange: (e: any, value: string) => void;
  required?: boolean;
  helperText?: string;
  error?: boolean;
}

const RadioButtonGroup: FC<RadioButtonGroupInterface> = (props) => {
  const { label, options, direction, required, helperText, error, onChange } =
    props;

  return (
    <FormControl error={error}>
      <FormLabel
        required={required}
        sx={{
          "&.Mui-focused": {
            color: "text.secondary",
          },
        }}
      >
        {label}
      </FormLabel>
      <RadioGroup row={direction === "row" ? true : false} onChange={onChange}>
        {options.map((option) => (
          <FormControlLabel
            value={option.value}
            control={<Radio />}
            label={option.label}
          />
        ))}
      </RadioGroup>
      <FormHelperText
        sx={{
          color: "error.main",
        }}
      >
        {helperText}
      </FormHelperText>
    </FormControl>
  );
};

export default RadioButtonGroup;
