import { FC } from "react";
import { Navigate, Outlet } from "react-router-dom";
import ProtectedRouteProps from "./ProtectedRoute.types";

const ProtectedRoute: FC<ProtectedRouteProps> = (props) => {
  const { children, isAllowed, redirectTo } = props;

  if (!isAllowed) {
    return <Navigate to={redirectTo} replace />;
  }

  return children ? children : <Outlet />;
};

export default ProtectedRoute;
