import { createSvgIcon } from "@mui/material";

const BayIcon = createSvgIcon(
  <svg viewBox="0 0 24 24">
    <path
      d="M10.8889 2.22222L19.6667 7.11111C20 7.22222 20.2222 7.66667 20.2222 8V18C20.2222 18.4444 20 18.7778 19.6667 19L14.6667 21.7778C14.4444 21.8889 14.2222 22 14 22C13.7778 22 13.5556 21.8889 13.3333 21.7778L4.55556 16.8889C4.22222 16.6667 4 16.3333 4 15.8889V5.88889C4 5.55556 4.22222 5.11111 4.55556 5L9.55556 2.11111C9.77778 2 10 2 10.2222 2C10.4444 2 10.6667 2 10.8889 2.22222ZM10.2222 4.33333L9 5L7.33333 6L14 9.66667L15.2222 9L16.8889 8L10.2222 4.33333ZM15.1111 19.1111L18 17.4444V10L15.1111 11.6667V19.1111ZM12.8889 11.6667L6.22222 7.88889L6.11111 15.3333L13 18.8889L12.8889 11.6667Z"
      fill="currentColor"
    />
  </svg>,
  "Bay"
);

export default BayIcon;
