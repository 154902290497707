export enum WIND_REFERENCESPEED {
  WS21 = 21,
  WS22 = 22,
  WS23 = 23,
  WS24 = 24,
  WS25 = 25,
  WS26 = 26,
  WS27 = 27,
  WS28 = 28,
  WS29 = 29,
  WS30 = 30,
  WS31 = 31,
}
