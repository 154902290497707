import { createSvgIcon } from "@mui/material";

const RulerIcon = createSvgIcon(
  <svg viewBox="0 0 24 24" fill="currentColor">
    <path
      d="M14.1,1.4L1.4,14.1c-0.8,0.8-0.8,2.1,0,2.8l5.7,5.7c0.8,0.8,2.1,0.8,2.8,0L22.6,9.9c0.8-0.8,0.8-2.1,0-2.8l-5.7-5.7
	C16.2,0.6,14.9,0.6,14.1,1.4z M21.2,8.5L8.5,21.2l-5.7-5.7l1.4-1.4l2.8,2.8l1.4-1.4l-2.8-2.8l1.4-1.4l2.8,2.8l1.4-1.4L8.5,9.9
	l1.4-1.4l2.8,2.8l1.4-1.4l-2.8-2.8l1.4-1.4l2.8,2.8l1.4-1.4l-2.8-2.8l1.4-1.4L21.2,8.5z"
    />
  </svg>,
  "Ruler"
);

export default RulerIcon;
