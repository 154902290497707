import { createSvgIcon } from "@mui/material";

const GableIcon = createSvgIcon(
  <svg viewBox="0 0 24 24" fill="currentColor">
    <path d="M18.5 12V18H5.5L5.5 12L3.5 12V20H20.5V12H18.5Z" />
    <path d="M2.5 12H21.5V4.00001H2.5V12ZM19.5 10V6H4.5V10H19.5Z" />
  </svg>,
  "Gable"
);

export default GableIcon;
