import {
  IconButton,
  Paper,
  Snackbar,
  SnackbarCloseReason,
  Typography,
} from "@mui/material";
import { FC, SyntheticEvent, useCallback, useMemo } from "react";
import {
  useSnackbarHorizontal,
  useSnackbarOpen,
  useSnackbarText,
  useSnackbarType,
  useSnackbarVertical,
} from "../../store";
import SnackbarOverlayProps from "./SnackbarOverlay.types";
import { Block, Check, Close, QuestionMark } from "@mui/icons-material";
import { Outlet } from "react-router-dom";
import { useSnackbarActions } from "../../store";

const SnackbarOverlay: FC<SnackbarOverlayProps> = () => {
  const open = useSnackbarOpen();
  const text = useSnackbarText();
  const type = useSnackbarType();
  const vertical = useSnackbarVertical();
  const horizontal = useSnackbarHorizontal();
  const { setSnackbarOpen } = useSnackbarActions();

  const icon = useMemo(() => {
    if (type === "positive") return <Check />;
    if (type === "negative") return <Block />;

    return <QuestionMark />;
  }, [type]);

  const paperBg = useMemo(() => {
    if (type === "positive") return "success.main";
    if (type === "negative") return "error.main";

    return "warning.main";
  }, [type]);

  const handleOnClose = useCallback(
    (e?: Event | SyntheticEvent<any, Event>, reason?: SnackbarCloseReason) => {
      if (reason === "clickaway") return;

      setSnackbarOpen(false);
    },
    [setSnackbarOpen]
  );

  return (
    <>
      <Snackbar
        autoHideDuration={6000}
        anchorOrigin={{ vertical, horizontal }}
        onClose={handleOnClose}
        open={open}
      >
        <Paper
          sx={{
            maxWidth: 340,
            backgroundColor: paperBg,
            padding: "12px",
            paddingLeft: "18px",
            display: "flex",
            alignItems: "center",
            borderRadius: 0,
          }}
        >
          {icon}
          {text && (
            <Typography
              variant="body1"
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                marginLeft: "12px",
              }}
            >
              {text}
            </Typography>
          )}
          <div
            style={{
              position: "relative",
              marginLeft: "12px",
              minHeight: 38,
              width: 38,
            }}
          >
            <IconButton
              size="small"
              onClick={handleOnClose}
              sx={{
                color: "text.primary",
                position: "absolute",
                left: 3,
                zIndex: 2,
                top: 3,
              }}
            >
              <Close />
            </IconButton>
          </div>
        </Paper>
      </Snackbar>
      <Outlet />
    </>
  );
};

export default SnackbarOverlay;
