import { createSvgIcon } from "@mui/material";

const ManualIcon = createSvgIcon(
  <svg viewBox="0 0 24 24">
    <path
      d="M18.5998 14.9167C19.2457 16.4864 19.3481 18.2046 18.8845 19.6932C18.4208 21.1818 17.4292 22.319 16.1276 22.8545"
      fill="currentColor"
    />
    <path
      d="M15.2329 13.7069L14.9285 12.9671C14.767 12.5746 14.4829 12.2515 14.1386 12.0686C13.7944 11.8858 13.4182 11.8583 13.0928 11.9922C12.7674 12.1261 12.5195 12.4104 12.4036 12.7825C12.2877 13.1547 12.3133 13.5842 12.4747 13.9767"
      fill="currentColor"
    />
    <path
      d="M12.4747 13.9767L12.1703 13.2368C12.0088 12.8444 11.7247 12.5213 11.3805 12.3384C11.0362 12.1556 10.66 12.1281 10.3346 12.262C10.0092 12.3959 9.76133 12.6802 9.64542 13.0523C9.52952 13.4245 9.55511 13.854 9.71658 14.2465L10.021 14.9863"
      fill="currentColor"
    />
    <path
      d="M18.5998 14.9167C19.2457 16.4864 19.3481 18.2046 18.8845 19.6932C18.4208 21.1818 17.4292 22.319 16.1276 22.8545L14.9008 23.3593C13.1832 24.066 11.8359 23.7553 10.514 23.14L6.29195 20.8979C5.9649 20.7028 5.69998 20.3804 5.55205 19.9972C5.40413 19.6141 5.38452 19.1997 5.4973 18.8398C5.61008 18.4799 5.84659 18.2021 6.15788 18.0639C6.46917 17.9257 6.9492 17.9804 7.16954 18.0973L8.78488 18.9552L5.74081 11.557C5.57934 11.1645 5.55374 10.735 5.66965 10.3628C5.78556 9.99069 6.03347 9.7064 6.35886 9.57252C6.68425 9.43863 7.06045 9.46613 7.4047 9.64894C7.74896 9.83176 8.03308 10.1549 8.19454 10.5474L9.71658 14.2465C9.55511 13.854 9.52952 13.4245 9.64542 13.0523C9.76133 12.6802 10.0092 12.3959 10.3346 12.262C10.66 12.1281 11.0362 12.1556 11.3805 12.3384C11.7247 12.5213 12.0088 12.8444 12.1703 13.2368L12.4747 13.9767C12.3133 13.5842 12.2877 13.1547 12.4036 12.7825C12.5195 12.4104 12.7674 12.1261 13.0928 11.9922C13.4182 11.8583 13.7944 11.8858 14.1386 12.0686C14.4829 12.2515 14.767 12.5746 14.9285 12.9671L15.2329 13.7069C15.0714 13.3144 15.0459 12.8849 15.1618 12.5127C15.2777 12.1406 15.5256 11.8563 15.851 11.7224C16.1764 11.5885 16.5526 11.616 16.8968 11.7988C17.2411 11.9816 17.5252 12.3048 17.6867 12.6972L18.5998 14.9167Z"
      fill="currentColor"
    />
    <circle
      cx="5.96316"
      cy="9.11612"
      r="3.5"
      stroke="currentColor"
      fill="none"
    />
    <path d="M8.16668 6.58743L11 4.5" stroke="currentColor" />
    <path d="M15.4999 4.00003L19 6" stroke="currentColor" />
    <circle
      cx="13.1122"
      cy="3.33711"
      r="2.5"
      stroke="currentColor"
      fill="none"
    />
    <circle
      cx="20.4021"
      cy="6.83313"
      r="1.5"
      stroke="currentColor"
      fill="none"
    />
  </svg>,
  "Manual"
);

export default ManualIcon;
