import Switch from "./Switch";
import { DRAW_RENDERMODE } from "@scaffcalc/packages-shared";
import { FC } from "react";

export interface RealRenderToggleInterface {
  onClickRealRender: (newMode: DRAW_RENDERMODE) => void;
  realRenderDisabled: boolean;
  renderMode: DRAW_RENDERMODE;
  switchLabels: {
    realRender: string;
  };
  size?: "small" | "medium";
  hide?: {
    realRender?: boolean;
  };
}

const RealRenderToggle: FC<RealRenderToggleInterface> = (props) => {
  const {
    onClickRealRender,
    realRenderDisabled,
    renderMode,
    switchLabels,
    hide,
    size = "medium",
  } = props;

  return (
    <>
      {!hide?.realRender && (
        <Switch
          label={switchLabels.realRender}
          checked={renderMode === DRAW_RENDERMODE.REAL}
          disabled={realRenderDisabled}
          onChange={() => {
            const newMode =
              renderMode === DRAW_RENDERMODE.BOX
                ? DRAW_RENDERMODE.REAL
                : DRAW_RENDERMODE.BOX;
            onClickRealRender(newMode);
          }}
          size={size}
        />
      )}
    </>
  );
};

export default RealRenderToggle;
