import {
  SCAFFOLD_PLANKORIENTATION,
  SCAFFOLD_PLANKTYPE,
  SCAFFOLD_STANDARDLENGTH,
} from "../../../enums";

export const BAYWIDTHS = {
  MM730: 0.73,
};

export const LOADINGBAYWIDTHS = {};

export const BAYLENGTHS = {
  MM700: 0.7,
  MM730: 0.73,
  MM1090: 1.09,
  MM1570: 1.57,
  MM2070: 2.07,
  MM2570: 2.57,
  MM3070: 3.07,
};

export const LOADINGBAYLENGTHS = {};

export const PLANKWIDTHS = {
  MM190: 0.19,
  MM300: 0.3,
  MM320: 0.32,
  MM610: 0.61,
};

export const STANDARDLENGTHS = {
  M2: SCAFFOLD_STANDARDLENGTH.M2,
};

export const CONSOLEWIDTHS = {
  MM360: 0.36,
  MM730: 0.73,
};

export const LOADINGBAYLOADCLASSES = {};

export const STAIRWIDTHS = {
  MM730: 0.73,
};
export const STAIRLENGTHS = {
  MM2570: 2.57,
  MM3070: 3.07,
};

export const PLANKORIENTATIONS = {
  LONGITUDINAL: SCAFFOLD_PLANKORIENTATION.LONGITUDINAL,
};

export const PLANKTYPES = {
  STEEL: SCAFFOLD_PLANKTYPE.STEEL,
  ALUMINUM: SCAFFOLD_PLANKTYPE.ALUMINUM,
};
