import { FC } from "react";
import { Outlet } from "react-router-dom";

import TrialLayoutProps from "./TrialLayout.types";
import TrialOverlay from "../../components/TrialOverlay";

const TrialLayout: FC<TrialLayoutProps> = () => {
  return (
    <>
      <TrialOverlay />
      <Outlet />
    </>
  );
};

export default TrialLayout;
