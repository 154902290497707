import { createSvgIcon } from "@mui/material";

const HouseOffIcon = createSvgIcon(
  <svg viewBox="0 0 24 24" fill="currentColor">
    <rect
      x="10.8"
      y="-2.7"
      transform="matrix(0.7071 -0.7071 0.7071 0.7071 -5.2586 11.8798)"
      width="1.8"
      height="30"
    />

    <polygon points="10.6,7.4 12.7,5.5 17.7,10 17.7,14.4 19.7,16.4 19.7,11.8 22.7,11.8 12.7,2.8 9.2,6 	" />
    <polygon points="17.7,17 17.7,17.8 7.7,17.8 7.7,10 9.3,8.6 7.8,7.2 2.7,11.8 5.7,11.8 5.7,19.8 19.7,19.8 19.7,19 	" />
  </svg>,
  "HouseOff"
);

export default HouseOffIcon;
