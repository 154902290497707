import {
  BAYLENGTHS,
  BAYWIDTHS,
  CONSOLEWIDTHS,
  LOADINGBAYLENGTHS,
  LOADINGBAYLOADCLASSES,
  LOADINGBAYWIDTHS,
  PLANKORIENTATIONS,
  PLANKTYPES,
  PLANKWIDTHS,
  STAIRLENGTHS,
  STAIRWIDTHS,
  STANDARDLENGTHS,
} from "./constants";
import { SCAFFOLDTYPES } from "./enums";
import Supplier from "../Supplier";
import { TFunction } from "i18next";
import {
  SCAFFOLD_ANCHORDENSITY,
  SCAFFOLD_CORNER,
  SCAFFOLD_LOADCLASS,
  SCAFFOLD_PLANKORIENTATION,
  SCAFFOLD_PLANKTYPE,
  SCAFFOLD_STANDARDLENGTH,
} from "../../../enums";

export class Aluscaff implements Supplier {
  bayLengths: typeof BAYLENGTHS;
  bayWidths: typeof BAYWIDTHS;
  consoleWidths: typeof CONSOLEWIDTHS;
  loadingBayLengths: typeof LOADINGBAYLENGTHS;
  loadingBayWidths: typeof LOADINGBAYWIDTHS;
  plankOrientations: typeof PLANKORIENTATIONS;
  plankTypes: typeof PLANKTYPES;
  plankWidths: typeof PLANKWIDTHS;
  scaffoldType: SCAFFOLDTYPES;
  stairLengths: typeof STAIRLENGTHS;
  stairWidths: typeof STAIRWIDTHS;
  standardLengths: typeof STANDARDLENGTHS;

  constructor(scaffoldType: SCAFFOLDTYPES = SCAFFOLDTYPES.MODULE) {
    this.bayLengths = BAYLENGTHS;
    this.bayWidths = BAYWIDTHS;
    this.consoleWidths = CONSOLEWIDTHS;
    this.loadingBayLengths = LOADINGBAYLENGTHS;
    this.loadingBayWidths = LOADINGBAYWIDTHS;
    this.plankOrientations = PLANKORIENTATIONS;
    this.plankTypes = PLANKTYPES;
    this.plankWidths = PLANKWIDTHS;
    this.scaffoldType = scaffoldType;
    this.stairLengths = STAIRLENGTHS;
    this.stairWidths = STAIRWIDTHS;
    this.standardLengths = STANDARDLENGTHS;
  }

  getBayLengths = (
    plankType: SCAFFOLD_PLANKTYPE,
    loadClass: SCAFFOLD_LOADCLASS,
    plankWidth: number | undefined
  ) => {
    if (
      plankType === this.plankTypes.ALUMINUM &&
      plankWidth === this.plankWidths.MM100
    ) {
      if (loadClass <= SCAFFOLD_LOADCLASS.LOADCLASS6) {
        return [this.bayLengths.MM3050];
      } else {
        return [];
      }
    } else if (
      plankType === this.plankTypes.ALUMINUM &&
      plankWidth === this.plankWidths.MM170
    ) {
      if (loadClass === SCAFFOLD_LOADCLASS.LOADCLASS6) {
        return [
          this.bayLengths.MM550,
          this.bayLengths.MM720,
          this.bayLengths.MM1050,
          this.bayLengths.MM1250,
          this.bayLengths.MM1650,
          this.bayLengths.MM1960,
          this.bayLengths.MM2450,
        ];
      } else if (loadClass <= SCAFFOLD_LOADCLASS.LOADCLASS5) {
        return [
          this.bayLengths.MM550,
          this.bayLengths.MM720,
          this.bayLengths.MM1050,
          this.bayLengths.MM1250,
          this.bayLengths.MM1650,
          this.bayLengths.MM1960,
          this.bayLengths.MM2450,
          this.bayLengths.MM3000,
        ];
      } else {
        return [];
      }
    } else if (
      plankType === this.plankTypes.ALUMINUM &&
      plankWidth === this.plankWidths.MM230
    ) {
      if (loadClass === SCAFFOLD_LOADCLASS.LOADCLASS6) {
        return [
          this.bayLengths.MM550,
          this.bayLengths.MM720,
          this.bayLengths.MM1050,
          this.bayLengths.MM1250,
          this.bayLengths.MM1650,
          this.bayLengths.MM1960,
        ];
      } else if (loadClass === SCAFFOLD_LOADCLASS.LOADCLASS5) {
        return [
          this.bayLengths.MM550,
          this.bayLengths.MM720,
          this.bayLengths.MM1050,
          this.bayLengths.MM1250,
          this.bayLengths.MM1650,
          this.bayLengths.MM1960,
          this.bayLengths.MM2450,
        ];
      } else if (loadClass <= SCAFFOLD_LOADCLASS.LOADCLASS4) {
        return [
          this.bayLengths.MM550,
          this.bayLengths.MM720,
          this.bayLengths.MM1050,
          this.bayLengths.MM1250,
          this.bayLengths.MM1650,
          this.bayLengths.MM1960,
          this.bayLengths.MM2450,
          this.bayLengths.MM3000,
        ];
      } else {
        return [];
      }
    } else if (
      plankType === this.plankTypes.COMPOSITE &&
      plankWidth === this.plankWidths.MM490
    ) {
      if (loadClass <= SCAFFOLD_LOADCLASS.LOADCLASS6) {
        return [this.bayLengths.MM1250];
      } else {
        return [];
      }
    } else {
      return [];
    }
  };

  getBayWidths = (plankType: SCAFFOLD_PLANKTYPE) => {
    if (plankType === this.plankTypes.COMPOSITE) {
      return [this.bayWidths.MM1050, this.bayWidths.MM1655];
    } else {
      return [
        this.bayWidths.MM770,
        this.bayWidths.MM1050,
        this.bayWidths.MM1250,
        this.bayWidths.MM1655,
      ];
    }
  };

  getLoadClasses = (plankType: SCAFFOLD_PLANKTYPE) => {
    if (
      plankType === this.plankTypes.COMPOSITE ||
      plankType === this.plankTypes.ALUMINUM
    ) {
      return [
        SCAFFOLD_LOADCLASS.LOADCLASS1,
        SCAFFOLD_LOADCLASS.LOADCLASS2,
        SCAFFOLD_LOADCLASS.LOADCLASS3,
        SCAFFOLD_LOADCLASS.LOADCLASS4,
        SCAFFOLD_LOADCLASS.LOADCLASS5,
        SCAFFOLD_LOADCLASS.LOADCLASS6,
      ];
    } else {
      return [];
    }
  };

  getLoadingBayLengths = () => Object.values(LOADINGBAYLENGTHS);

  getLoadingBayWidths = () => Object.values(LOADINGBAYWIDTHS);

  getAnchorDensities = () => [
    SCAFFOLD_ANCHORDENSITY.EVERY_FOUR,
    SCAFFOLD_ANCHORDENSITY.EVERY_THREE,
    SCAFFOLD_ANCHORDENSITY.EVERY_TWO,
  ];

  getStandardLengths = () => [
    SCAFFOLD_STANDARDLENGTH.M4,
    SCAFFOLD_STANDARDLENGTH.M3,
    SCAFFOLD_STANDARDLENGTH.M2,
  ];

  getConsoleWidths = (plankWidth: number | undefined) => {
    if (
      plankWidth === this.plankWidths.MM100 ||
      plankWidth === this.plankWidths.MM230
    ) {
      return [
        this.consoleWidths.MM240,
        this.consoleWidths.MM300,
        this.consoleWidths.MM470,
        this.consoleWidths.MM500,
        this.consoleWidths.MM700,
        this.consoleWidths.MM720,
        this.consoleWidths.MM1200,
      ];
    } else if (plankWidth == this.plankWidths.MM170) {
      return [
        this.consoleWidths.MM240,
        this.consoleWidths.MM700,
        this.consoleWidths.MM720,
        this.consoleWidths.MM1200,
      ];
    } else if (plankWidth === this.plankWidths.MM490) {
      return [this.consoleWidths.MM500];
    } else {
      return [];
    }
  };

  getLoadingBayLoadClasses = () => Object.values(LOADINGBAYLOADCLASSES);

  getStairWidths = () => Object.values(STAIRWIDTHS);

  getStairLengths = () => Object.values(STAIRLENGTHS);

  getBayPlankConfiguration = (bayWidth: number) => {
    if (bayWidth === this.bayWidths.MM770) {
      const A = Array(7).fill(this.plankWidths.MM100);
      const B = Array(4).fill(this.plankWidths.MM170);
      const C = Array(3).fill(this.plankWidths.MM230);
      return [A, B, C];
    } else if (bayWidth === this.bayWidths.MM1050) {
      const A = Array(10).fill(this.plankWidths.MM100);
      const B = Array(6).fill(this.plankWidths.MM170);
      const C = Array(4).fill(this.plankWidths.MM230);
      const D = Array(2).fill(this.plankWidths.MM490);
      return [A, B, C, D];
    } else if (bayWidth === this.bayWidths.MM1250) {
      const A = Array(12).fill(this.plankWidths.MM100);
      const B = Array(7).fill(this.plankWidths.MM170);
      const C = Array(5).fill(this.plankWidths.MM230);
      return [A, B, C];
    } else if (bayWidth === this.bayWidths.MM1655) {
      const A = Array(16).fill(this.plankWidths.MM100);
      const B = Array(9).fill(this.plankWidths.MM170);
      const C = Array(7).fill(this.plankWidths.MM230);
      const D = Array(3).fill(this.plankWidths.MM490);
      return [A, B, C, D];
    } else {
      return [];
    }
  };

  getLoadingBayPlankConfiguration = () => {
    return [];
  };
  /* getLoadingBayPlankConfiguration = (loadingBayWidth: number) => {
      if (loadingBayWidth === this.loadingBayWidths.MM730) {
        const A = Array(2).fill(this.plankWidths.MM320);
        const B = [this.plankWidths.MM610];
        return [A, B];
      } else if (loadingBayWidth === this.loadingBayWidths.MM1090) {
        const A = Array(3).fill(this.plankWidths.MM320);
        const B = [this.plankWidths.MM320, this.plankWidths.MM610];
        return [A, B];
      } else if (loadingBayWidth === this.loadingBayWidths.MM1400) {
        const A = Array(4).fill(this.plankWidths.MM320);
        const B = [this.plankWidths.MM610, this.plankWidths.MM610];
        return [A, B];
      } else if (loadingBayWidth === this.loadingBayWidths.MM1570) {
        const A = [this.plankWidths.MM190].concat(
          Array(4).fill(this.plankWidths.MM320)
        );
        return [A];
      } else if (loadingBayWidth === this.loadingBayWidths.MM2070) {
        const A = Array(6).fill(this.plankWidths.MM320);
        return [A];
      } else if (loadingBayWidth === this.loadingBayWidths.MM2570) {
        const A = [this.plankWidths.MM190].concat(
          Array(7).fill(this.plankWidths.MM320)
        );
        return [A];
      } else if (loadingBayWidth === this.loadingBayWidths.MM3070) {
        const A = Array(9).fill(this.plankWidths.MM320);
        return [A];
      } else {
        return [];
      }
    }; */

  getConsolePlankConfiguration = (consoleWidth: number) => {
    if (consoleWidth === this.consoleWidths.MM240) {
      const A = [this.plankWidths.MM170];
      const B = Array(2).fill(this.plankWidths.MM100);
      const C = [this.plankWidths.MM230];
      return [A, B, C];
    } else if (consoleWidth === this.consoleWidths.MM300) {
      const A = [this.plankWidths.MM230];
      const B = Array(2).fill(this.plankWidths.MM100);
      return [A, B];
    } else if (consoleWidth === this.consoleWidths.MM470) {
      const A = Array(2).fill(this.plankWidths.MM230);
      const B = Array(4).fill(this.plankWidths.MM100);
      return [A, B];
    } else if (consoleWidth == this.consoleWidths.MM500) {
      const A = Array(2).fill(this.plankWidths.MM230);
      const B = Array(5).fill(this.plankWidths.MM100);
      const C = [this.plankWidths.MM490];
      return [A, B, C];
    } else if (
      consoleWidth === this.consoleWidths.MM700 ||
      consoleWidth === this.consoleWidths.MM720
    ) {
      const A = Array(3).fill(this.plankWidths.MM230);
      const B = Array(4).fill(this.plankWidths.MM170);
      const C = Array(7).fill(this.plankWidths.MM100);
      return [A, B, C];
    } else if (consoleWidth === this.consoleWidths.MM1200) {
      const A = Array(5).fill(this.plankWidths.MM230);
      const B = Array(7).fill(this.plankWidths.MM170);
      const C = Array(12).fill(this.plankWidths.MM100);
      return [A, B, C];
    } else {
      return [];
    }
  };

  /*     if (consoleWidth === this.consoleWidths.MM280) {
        const A = [this.plankWidths.MM190];
        return [A];
      } else if (consoleWidth === this.consoleWidths.MM390) {
        const A = [this.plankWidths.MM320];
        return [A];
      } else if (consoleWidth === this.consoleWidths.MM590) {
        const A = [this.plankWidths.MM320, this.plankWidths.MM190];
        return [A];
      } else if (consoleWidth === this.consoleWidths.MM730) {
        const A = Array(2).fill(this.plankWidths.MM320);
        return [A];
      } else {
        return [];
      } 
    }; */

  getCornerConfigurations = () => {
    return [
      SCAFFOLD_CORNER.ABOVE_BAY,
      SCAFFOLD_CORNER.ABOVE_BAY_EXTRA,
      SCAFFOLD_CORNER.IN_BAY,
      SCAFFOLD_CORNER.IN_BAY_EXTRA,
    ];
  };

  getPlankingOptions = (t: TFunction) => {
    return [
      {
        value: this.plankTypes.ALUMINUM + this.plankWidths.MM100,
        material: this.plankTypes.ALUMINUM,
        label: t("options.plankingtype.shared.alu10"),
        width: this.plankWidths.MM100,
        orientation: SCAFFOLD_PLANKORIENTATION.LONGITUDINAL,
      },
      {
        value: this.plankTypes.ALUMINUM + this.plankWidths.MM170,
        material: this.plankTypes.ALUMINUM,
        label: t("options.plankingtype.shared.alu17"),
        width: this.plankWidths.MM170,
        orientation: SCAFFOLD_PLANKORIENTATION.LONGITUDINAL,
      },
      {
        value: this.plankTypes.ALUMINUM + this.plankWidths.MM230,
        material: this.plankTypes.ALUMINUM,
        label: t("options.plankingtype.shared.alu23"),
        width: this.plankWidths.MM230,
        orientation: SCAFFOLD_PLANKORIENTATION.LONGITUDINAL,
      },
      {
        value: this.plankTypes.COMPOSITE + this.plankWidths.MM490,
        material: this.plankTypes.COMPOSITE,
        label: t("options.plankingtype.shared.comp49"),
        width: this.plankWidths.MM490,
        orientation: SCAFFOLD_PLANKORIENTATION.LONGITUDINAL,
      },
    ];
  };
}
export default Aluscaff;
