import RouteManager from "./components/RouteManager";
import ThemeManager from "./components/ThemeManager";
import { ErrorBoundary } from "react-error-boundary";
import { LicenseInfo } from "@mui/x-license-pro";

/**
 * Registration of the MUI X Pro license key (ment to public)
 * link: https://mui.com/x/introduction/licensing/#license-key-security
 */
// const MUI_X_PRO =
//   "bf1a9b46d5ad6dfb8bb6cdf19aef9c8bTz04NTc4NixFPTE3NDEyNjU4ODUwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=";
const MUI_X_PREMIUM =
  "92b42a0400f86e2b98cff96b8d735c2eTz04NTgyMyxFPTE3NDEyOTM2MzMwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y";
LicenseInfo.setLicenseKey(MUI_X_PREMIUM);

const App = () => {
  return (
    <ErrorBoundary fallback={null}>
      <ThemeManager>
        <RouteManager />
      </ThemeManager>
    </ErrorBoundary>
  );
};

export default App;
