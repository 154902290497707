import { FC } from "react";
import { useLoading, useLoadingText } from "../../store";
import { Outlet } from "react-router-dom";
import { Stack, Typography } from "@mui/material";
import LoadingOverlayProps from "./LoadingOverlay.types";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const LoadingOverlay: FC<LoadingOverlayProps> = (props) => {
  /** Hooks: loading */
  let isLoading = useLoading();
  let loadingText = useLoadingText();

  if (props.loadingText !== undefined) loadingText = props.loadingText;
  if (props.isLoading !== undefined) isLoading = props.isLoading;

  return (
    <>
      <Backdrop
        open={isLoading}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Stack alignItems="center" justifyContent="center">
          <CircularProgress />
          {loadingText && <Typography mt="16px">{loadingText}</Typography>}
        </Stack>
      </Backdrop>
      <Outlet />
    </>
  );
};

export default LoadingOverlay;
