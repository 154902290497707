import { createSvgIcon } from "@mui/material";

const KeyAIcon = createSvgIcon(
  <svg viewBox="0 0 24 24">
    <path
      d="M20.4211 2H3.57895C2.70692 2 2 2.70692 2 3.57895V20.4211C2 21.2931 2.70692 22 3.57895 22H20.4211C21.2931 22 22 21.2931 22 20.4211V3.57895C22 2.70692 21.2931 2 20.4211 2Z"
      stroke="currentColor"
      fill="none"
    />
    <path
      d="M12.2607 8.1209L9.07471 17.3641H7.14844L11.1621 6.7H12.3926L12.2607 8.1209ZM14.9268 17.3641L11.7334 8.1209L11.5942 6.7H12.832L16.8604 17.3641H14.9268ZM14.7729 13.409V14.8665H8.97217V13.409H14.7729Z"
      fill="currentColor"
    />
  </svg>,
  "KeyAIcon"
);

export default KeyAIcon;
