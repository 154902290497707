import { FC, useCallback, useEffect, useState } from "react";
import ThemeManager from "../../../../web/app/src/components/ThemeManager";
import ToolTip from "./ToolTip";
import { ButtonBase, Typography, Stack, Divider } from "@mui/material";
import {
  ChevronLeft,
  ChevronRight,
  Settings,
  Delete,
} from "@mui/icons-material";
import {
  GableRoofIcon,
  InsertIcon,
  ManualIcon,
  MoveIcon,
  RotateIcon,
  SelectAllBaysIcon,
  ShedRoofIcon,
  SplitIcon,
} from "../assets/icons";
import { useTranslation } from "react-i18next";

export interface ToolBarProps {
  disabled?: {
    addbay?: boolean;
    delete?: boolean;
    gable?: boolean;
    left?: boolean;
    move?: boolean;
    right?: boolean;
    rotate?: boolean;
    selectallbays?: boolean;
    settings?: boolean;
    shed?: boolean;
    split?: boolean;
  };
  mode: "house" | "roof" | "scaffold" | "addons" | "blueprint" | string;
  modifierValue?: "move" | "scale" | string;
  multi?: boolean;
  multiScaffolds?: boolean;
  onClick: (icon: string, e: any) => void;
  onPointerDown?: (e: any) => void;
  onPointerMove?: (e: any) => void;
  onPointerUp?: (e: any) => void;
  pageNumber?: number;
  pageNumbers?: number;
  pageText?: string;
  polygonTypes?: { rectangles: boolean; polygons: boolean };
  roofValue?: "flat" | "shed" | "gable" | string;
  skipDelete?: boolean;
}

const ToolButton: FC<{
  tool: string;
  icon: JSX.Element;
  handleOnClick: (
    id: string,
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  disabled?: boolean;
  tippyText: string;
}> = (props) => {
  const { tool, icon, handleOnClick, disabled, tippyText } = props;

  return (
    <ToolTip content={tippyText} placement="bottom">
      {/* Extra stack because of posibility of button being disabled */}
      <Stack>
        <ButtonBase
          id={tool}
          onClick={(e) => handleOnClick(tool, e)}
          disabled={disabled}
          sx={{
            borderRadius: 1,
            padding: 0.5,
            "&:hover": {
              backgroundColor: "action.hover",
            },
          }}
        >
          {icon}
        </ButtonBase>
      </Stack>
    </ToolTip>
  );
};

const HouseIcons = ({
  modifierValue,
  onClick,
  disabled,
  multi,
}: Partial<ToolBarProps>) => {
  const [selectedModifier, setSelectedModifier] =
    useState<string | undefined>(undefined);

  useEffect(() => {
    setSelectedModifier(modifierValue);
  }, [modifierValue]);

  const { t } = useTranslation();

  const handleOnClick = useCallback(
    (id: string, e: any) => {
      if (onClick) onClick(id, e);

      if (selectedModifier === id) {
        setSelectedModifier(undefined);
      } else {
        setSelectedModifier(id);
      }
    },
    [selectedModifier, onClick]
  );

  return (
    <>
      {!multi && (
        <>
          <ToolButton
            tool={"move"}
            icon={
              <MoveIcon
                sx={{
                  color: disabled?.move ? "text.disabled" : "text.primary",
                }}
              />
            }
            handleOnClick={handleOnClick}
            disabled={disabled?.move}
            tippyText={t("actions.move")}
          />
          <Divider sx={{ borderWidth: "thin", margin: 0.25 }} />
        </>
      )}
      <ToolButton
        tool="trashcan"
        icon={
          <Delete
            sx={{
              color: disabled?.delete ? "text.disabled" : "text.primary",
            }}
          />
        }
        handleOnClick={handleOnClick}
        disabled={disabled?.delete}
        tippyText={t("actions.delete")}
      />
    </>
  );
};

const PolygonPointIcons = ({
  modifierValue,
  onClick,
  disabled,
}: Partial<ToolBarProps>) => {
  const [selectedModifier, setSelectedModifier] =
    useState<string | undefined>(undefined);

  useEffect(() => {
    setSelectedModifier(modifierValue);
  }, [modifierValue]);

  const { t } = useTranslation();

  const handleOnClick = useCallback(
    (id: string, e: any) => {
      if (onClick) onClick(id, e);

      if (selectedModifier === id) {
        setSelectedModifier(undefined);
      } else {
        setSelectedModifier(id);
      }
    },
    [selectedModifier, onClick]
  );

  return (
    <>
      <ToolButton
        tool={"move"}
        icon={
          <MoveIcon
            sx={{
              color: disabled?.move ? "text.disabled" : "text.primary",
            }}
          />
        }
        handleOnClick={handleOnClick}
        disabled={disabled?.move}
        tippyText={t("actions.move")}
      />
      <Divider sx={{ borderWidth: "thin", margin: 0.25 }} />
      <ToolButton
        tool={"trashcan"}
        icon={
          <Delete
            sx={{
              color: disabled?.delete ? "text.disabled" : "text.primary",
            }}
          />
        }
        handleOnClick={handleOnClick}
        disabled={disabled?.delete}
        tippyText={t("actions.delete")}
      />
    </>
  );
};

const BlueprintIcons = ({
  modifierValue,
  onClick,
  disabled,
}: Partial<ToolBarProps>) => {
  const [selectedModifier, setSelectedModifier] =
    useState<string | undefined>(undefined);

  useEffect(() => {
    setSelectedModifier(modifierValue);
  }, [modifierValue]);

  const { t } = useTranslation();

  const handleOnClick = useCallback(
    (id: string, e: any) => {
      if (onClick) onClick(id, e);

      if (selectedModifier === id) {
        setSelectedModifier(undefined);
      } else {
        setSelectedModifier(id);
      }
    },
    [selectedModifier, onClick]
  );

  return (
    <>
      <ToolButton
        tool={"move"}
        icon={
          <MoveIcon
            sx={{
              color: disabled?.move ? "text.disabled" : "text.primary",
            }}
          />
        }
        handleOnClick={handleOnClick}
        disabled={disabled?.move}
        tippyText={t("actions.move")}
      />
      <Divider sx={{ borderWidth: "thin", margin: 0.25 }} />
      <ToolButton
        tool={"trashcan"}
        icon={
          <Delete
            sx={{
              color: disabled?.delete ? "text.disabled" : "text.primary",
            }}
          />
        }
        handleOnClick={handleOnClick}
        disabled={disabled?.delete}
        tippyText={t("actions.delete")}
      />
    </>
  );
};

const RoofIcons = ({
  onClick,
  disabled,
  polygonTypes,
}: Partial<ToolBarProps>) => {
  const handleOnClick = useCallback(
    (id: string, e: any) => {
      if (onClick) onClick(id, e);
    },
    [onClick]
  );

  const { t } = useTranslation();

  return (
    <Stack alignItems={"center"} gap={1}>
      {polygonTypes?.rectangles && (
        <Stack direction={"row"} gap={0.5}>
          <ToolButton
            tool={"shed"}
            icon={
              <ShedRoofIcon
                sx={{
                  color: disabled?.shed ? "text.disabled" : "text.primary",
                }}
              />
            }
            handleOnClick={handleOnClick}
            disabled={disabled?.shed}
            tippyText={t("options.rooftype.shed")}
          />
          <ToolButton
            tool={"gable"}
            icon={
              <GableRoofIcon
                sx={{
                  color: disabled?.gable ? "text.disabled" : "text.primary",
                }}
              />
            }
            handleOnClick={handleOnClick}
            disabled={disabled?.gable}
            tippyText={t("options.rooftype.gable")}
          />
          <Divider sx={{ borderWidth: "thin", margin: 0.25 }} />
          <ToolButton
            tool={"rotate"}
            icon={
              <RotateIcon
                sx={{
                  color: disabled?.rotate ? "text.disabled" : "text.primary",
                }}
              />
            }
            handleOnClick={handleOnClick}
            disabled={disabled?.rotate}
            tippyText={t("actions.rotate")}
          />
          <Divider sx={{ borderWidth: "thin", margin: 0.25 }} />
          <ToolButton
            tool="trashcan"
            icon={
              <Delete
                sx={{
                  color: disabled?.delete ? "text.disabled" : "text.primary",
                }}
              />
            }
            handleOnClick={handleOnClick}
            disabled={disabled?.delete}
            tippyText={t("actions.delete")}
          />
        </Stack>
      )}
      {polygonTypes?.rectangles && polygonTypes?.polygons && (
        <Divider flexItem sx={{ borderWidth: "thin" }} />
      )}
      {polygonTypes?.polygons && (
        <Typography variant="body2" maxWidth={"200px"} textAlign={"center"}>
          {polygonTypes?.rectangles
            ? t("helpers.roofonlyrectangular")
            : t("helpers.disabledroofpolygon")}
        </Typography>
      )}
    </Stack>
  );
};

const ScaffoldIcons = ({
  modifierValue,
  onClick,
  disabled,
  multi,
  multiScaffolds,
}: Partial<ToolBarProps>) => {
  const [selectedModifier, setSelectedModifier] = useState<string | null>(null);

  const { t } = useTranslation();

  useEffect(() => {
    if (modifierValue !== undefined) setSelectedModifier(modifierValue);
  }, [modifierValue]);

  const handleOnClick = useCallback(
    (id: string, e: any) => {
      if (onClick) onClick(id, e);

      if (selectedModifier === id) {
        setSelectedModifier(null);
      } else {
        setSelectedModifier(id);
      }
    },
    [selectedModifier, onClick]
  );

  return (
    <>
      <ToolButton
        tool={"selectall"}
        icon={
          <SelectAllBaysIcon
            sx={{
              color: disabled?.selectallbays ? "text.disabled" : "text.primary",
            }}
          />
        }
        handleOnClick={handleOnClick}
        disabled={disabled?.selectallbays}
        tippyText={t("actions.selectallbays")}
      />
      <Divider sx={{ borderWidth: "thin", margin: 0.25 }} />
      {!multi && (
        <>
          <ToolButton
            tool={"move"}
            icon={
              <MoveIcon
                sx={{
                  color: disabled?.move ? "text.disabled" : "text.primary",
                }}
              />
            }
            handleOnClick={handleOnClick}
            disabled={disabled?.move}
            tippyText={t("actions.move")}
          />
          <Divider sx={{ borderWidth: "thin", margin: 0.25 }} />
        </>
      )}
      {!multiScaffolds && (
        <ToolButton
          tool={"add"}
          icon={
            <ManualIcon
              sx={{
                color: disabled?.addbay ? "text.disabled" : "text.primary",
              }}
            />
          }
          handleOnClick={handleOnClick}
          disabled={disabled?.addbay}
          tippyText={t("tools.addbay")}
        />
      )}
      <ToolButton
        tool={"insert"}
        icon={
          <InsertIcon
            sx={{
              color: disabled?.addbay ? "text.disabled" : "text.primary",
            }}
          />
        }
        handleOnClick={handleOnClick}
        disabled={disabled?.addbay}
        tippyText={t("actions.insert")}
      />
      <ToolButton
        tool={"split"}
        icon={
          <SplitIcon
            sx={{
              color: disabled?.addbay ? "text.disabled" : "text.primary",
            }}
          />
        }
        handleOnClick={handleOnClick}
        disabled={disabled?.split}
        tippyText={t("actions.split")}
      />
      <Divider sx={{ borderWidth: "thin", margin: 0.25 }} />
      <ToolButton
        tool={"trashcan"}
        icon={
          <Delete
            sx={{
              color: disabled?.delete ? "text.disabled" : "text.primary",
            }}
          />
        }
        handleOnClick={handleOnClick}
        disabled={disabled?.delete}
        tippyText={t("actions.delete")}
      />
    </>
  );
};

const TRSIcons = ({
  modifierValue,
  onClick,
  disabled,
  skipDelete,
}: Partial<ToolBarProps>) => {
  const [selectedModifier, setSelectedModifier] = useState<string | null>(null);

  const { t } = useTranslation();

  useEffect(() => {
    if (modifierValue !== undefined) setSelectedModifier(modifierValue);
  }, [modifierValue]);

  const handleOnClick = useCallback(
    (id: string, e: any) => {
      if (onClick) onClick(id, e);

      if (selectedModifier === id) {
        setSelectedModifier(null);
      } else {
        setSelectedModifier(id);
      }
    },
    [selectedModifier, onClick]
  );

  return (
    <>
      <ToolButton
        tool={"settings"}
        icon={
          <Settings
            sx={{
              color: disabled?.settings ? "text.disabled" : "text.primary",
            }}
          />
        }
        handleOnClick={handleOnClick}
        disabled={disabled?.settings}
        tippyText={t("tools.settings")}
      />
      {!skipDelete && (
        <>
          <Divider sx={{ borderWidth: "thin", margin: 0.25 }} />
          <ToolButton
            tool={"trashcan"}
            icon={
              <Delete
                sx={{
                  color: disabled?.delete ? "text.disabled" : "text.primary",
                }}
              />
            }
            handleOnClick={handleOnClick}
            disabled={disabled?.delete}
            tippyText={t("actions.delete")}
          />
        </>
      )}
    </>
  );
};

const MeasureIcons = ({ onClick }: Partial<ToolBarProps>) => {
  const [selectedModifier, setSelectedModifier] = useState<string | null>(null);

  const { t } = useTranslation();

  const handleOnClick = useCallback(
    (id: string, e: any) => {
      if (onClick) onClick(id, e);

      if (selectedModifier === id) {
        setSelectedModifier(null);
      } else {
        setSelectedModifier(id);
      }
    },
    [selectedModifier, onClick]
  );

  return (
    <ToolButton
      tool={"trashcan"}
      icon={<Delete />}
      handleOnClick={handleOnClick}
      tippyText={t("actions.delete")}
    />
  );
};

const AddonIcons = ({
  modifierValue,
  onClick,
  disabled,
}: Partial<ToolBarProps>) => {
  const [selectedModifier, setSelectedModifier] = useState<string | null>(null);

  const { t } = useTranslation();

  useEffect(() => {
    if (modifierValue !== undefined) setSelectedModifier(modifierValue);
  }, [modifierValue]);

  const handleOnClick = useCallback(
    (id: string, e: any) => {
      if (onClick) onClick(id, e);

      if (selectedModifier === id) {
        setSelectedModifier(null);
      } else {
        setSelectedModifier(id);
      }
    },
    [selectedModifier, onClick]
  );

  return (
    <ToolButton
      tool={"trashcan"}
      icon={
        <Delete
          sx={{
            color: disabled?.delete ? "text.disabled" : "text.primary",
          }}
        />
      }
      handleOnClick={handleOnClick}
      tippyText={t("actions.delete")}
    />
  );
};

const PdfIcons = ({
  onClick,
  pageText = "Page",
  pageNumber = 0,
  pageNumbers = 0,
  disabled,
}: Partial<ToolBarProps>) => {
  const { t } = useTranslation();

  const handleOnClick = useCallback(
    (id: string, e: any) => {
      if (onClick) onClick(id, e);
    },
    [onClick]
  );

  return (
    <>
      <ToolButton
        tool={"move"}
        icon={
          <MoveIcon
            sx={{
              color: disabled?.move ? "text.disabled" : "text.primary",
            }}
          />
        }
        handleOnClick={handleOnClick}
        disabled={disabled?.move}
        tippyText={t("actions.move")}
      />
      {pageNumbers > 1 && (
        <>
          <Divider
            sx={{
              borderWidth: "thin",
              margin: 0.25,
            }}
          />
          <ToolButton
            tool={"left"}
            icon={
              <ChevronLeft
                sx={{
                  color: disabled?.left ? "text.disabled" : "text.primary",
                }}
              />
            }
            handleOnClick={handleOnClick}
            disabled={disabled?.left}
            tippyText={t("actions.previous")}
          />
          <Stack
            style={{
              alignItems: "center",
              justifyContent: "center",
              marginLeft: 14,
              marginRight: 14,
              userSelect: "none",
            }}
          >
            <Typography variant="body1" sx={{ whiteSpace: "nowrap" }}>
              {`${pageText} ${pageNumber}/${pageNumbers}`}
            </Typography>
          </Stack>
          <ToolButton
            tool={"right"}
            icon={
              <ChevronRight
                sx={{
                  color: disabled?.right ? "text.disabled" : "text.primary",
                }}
              />
            }
            handleOnClick={handleOnClick}
            disabled={disabled?.right}
            tippyText={t("actions.next")}
          />
        </>
      )}
    </>
  );
};

const ToolBar = ({
  disabled,
  mode = "house",
  modifierValue,
  multi,
  multiScaffolds,
  onClick = () => {},
  onPointerDown,
  onPointerMove,
  onPointerUp,
  pageNumber,
  pageNumbers,
  pageText,
  polygonTypes,
  roofValue,
  skipDelete,
}: ToolBarProps) => {
  return (
    <ThemeManager>
      <Stack
        direction={"row"}
        alignContent={"center"}
        justifyContent={"center"}
        gap={0.5}
        padding={1}
        bgcolor={"secondary.light"}
        borderRadius={2}
        boxShadow={2}
        onPointerDown={(e) => (onPointerDown ? onPointerDown(e) : () => {})}
        onPointerUp={(e) => (onPointerUp ? onPointerUp(e) : () => {})}
        onPointerMove={(e) => (onPointerMove ? onPointerMove(e) : () => {})}
      >
        {mode === "house" && (
          <HouseIcons
            multi={multi}
            modifierValue={modifierValue}
            onClick={onClick}
            disabled={disabled}
          />
        )}
        {mode === "polygonPoint" && (
          <PolygonPointIcons
            modifierValue={modifierValue}
            onClick={onClick}
            disabled={disabled}
          />
        )}
        {mode === "roof" && (
          <RoofIcons
            multi={multi}
            polygonTypes={polygonTypes}
            roofValue={roofValue}
            onClick={onClick}
            disabled={disabled}
          />
        )}
        {mode === "scaffold" && (
          <ScaffoldIcons
            multi={multi}
            multiScaffolds={multiScaffolds}
            modifierValue={modifierValue}
            onClick={onClick}
            disabled={disabled}
          />
        )}
        {mode === "trs" && (
          <TRSIcons
            modifierValue={modifierValue}
            onClick={onClick}
            disabled={disabled}
            skipDelete={skipDelete}
          />
        )}
        {mode === "addons" && (
          <AddonIcons
            modifierValue={modifierValue}
            onClick={onClick}
            disabled={disabled}
          />
        )}
        {mode === "pdf" && (
          <PdfIcons
            pageText={pageText}
            pageNumber={pageNumber}
            pageNumbers={pageNumbers}
            onClick={onClick}
            disabled={disabled}
          />
        )}
        {mode === "measure" && <MeasureIcons onClick={onClick} />}
        {mode === "blueprint" && (
          <BlueprintIcons
            modifierValue={modifierValue}
            onClick={onClick}
            disabled={disabled}
          />
        )}
      </Stack>
    </ThemeManager>
  );
};

export default ToolBar;
