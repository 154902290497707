import {
  SCAFFOLD_ANCHORDENSITY,
  SCAFFOLD_CORNER,
  SCAFFOLD_CORNER_ROTATION_DEGREES,
} from "@scaffcalc/backends-firebase";
import { BayInterface, plus } from "@scaffcalc/packages-shared";
import { divide } from "@scaffcalc/packages-shared";
import { indexOfMax } from "../../utils/math";
import {
  HEIGHT_BETWEEN_FRAMES,
  STANDARD_BOTTOM_OFFSET,
} from "./lines.constants";
import { XBay } from "./lines.types";
import { CornerHandles } from "./lines.interfaces";

export const getNextBay = (
  bays: BayInterface[],
  bayId: string
): BayInterface | undefined => {
  const bayIdx = bays
    .sort((a, b) => a.position.z - b.position.z)
    .findIndex((bay) => bay.id === bayId);
  return bays[bayIdx + 1];
};

export const getPreviousBay = (
  bays: BayInterface[],
  bayId: string
): BayInterface | undefined => {
  const bayIdx = bays
    .sort((a, b) => a.position.z - b.position.z)
    .findIndex((bay) => bay.id === bayId);
  return bays[bayIdx - 1];
};

export const getBayAnchorHeights = (
  height: number,
  anchorDensity: SCAFFOLD_ANCHORDENSITY | null
) => {
  let anchorHeights: Array<number> = [];

  if (typeof anchorDensity === "number") {
    let numberHeights = divide(height, anchorDensity);

    for (let y = 0; y < numberHeights; y++) {
      if (height - anchorDensity * y >= 0.5) {
        anchorHeights.push(height - anchorDensity * y);
      }
    }
  } else {
    /** Fallback */
    let numberHeights = divide(height, 2);

    for (let y = 0; y < numberHeights; y++) {
      if (height - 2 * y >= 2) {
        anchorHeights.push(height - 2 * y);
      }
    }
  }
  return anchorHeights;
};

export const getHighestAnchorHeights = (
  prevBayHeight: number | undefined,
  bayHeight: number,
  anchorDensity: SCAFFOLD_ANCHORDENSITY | null
) => {
  let bayAnchorHeights: number[] = getBayAnchorHeights(
    bayHeight,
    anchorDensity
  );

  if (prevBayHeight) {
    const aAnchorHeights = getBayAnchorHeights(prevBayHeight, anchorDensity);

    const maxB = Math.max(...bayAnchorHeights);
    const maxA = Math.max(...aAnchorHeights);

    if (maxB < maxA) {
      bayAnchorHeights = aAnchorHeights;
    }
  }

  return bayAnchorHeights;
};

export const getBayFrameHeights = (
  height: number,
  maxNextHeight?: number,
  maxPrevHeight?: number
) => {
  let bayFrameHeights = [];
  /** Get number of yTiles for current height*/
  let numberHeights = getNBayTiles(height);
  for (let y = 0; y < numberHeights; y++) {
    /** Push y-heights for the number of yTiles */
    bayFrameHeights.push(
      height - HEIGHT_BETWEEN_FRAMES * y - STANDARD_BOTTOM_OFFSET
    );
  }

  /** Push bottom height of 0.4  */
  if (numberHeights > 0 && Math.min(...bayFrameHeights) > 1) {
    bayFrameHeights.push(0.4);
  }

  const frameHeightsWithNext = [...bayFrameHeights];
  if (maxNextHeight) {
    let numberMaxNextHeights = getNBayTiles(maxNextHeight);

    for (let y = 0; y < numberMaxNextHeights; y++) {
      frameHeightsWithNext.push(
        maxNextHeight - HEIGHT_BETWEEN_FRAMES * y - STANDARD_BOTTOM_OFFSET
      );
    }

    /** Insurance */
    if (numberMaxNextHeights > 0) {
      frameHeightsWithNext.push(0.4);
    }
  }
  /** Get unique and sorted frameHeight arrays */
  const bayFrameHeightsNext = [...new Set(frameHeightsWithNext)].sort(
    (a, b) => a - b
  );

  const frameHeightsWithPrev = [...bayFrameHeights];
  if (maxPrevHeight) {
    const numberMaxPrevHeights = getNBayTiles(maxPrevHeight);

    for (let y = 0; y < numberMaxPrevHeights; y++) {
      frameHeightsWithPrev.push(
        maxPrevHeight - HEIGHT_BETWEEN_FRAMES * y - STANDARD_BOTTOM_OFFSET
      );
    }

    /** Insurance */
    if (numberMaxPrevHeights > 0) {
      frameHeightsWithPrev.push(0.4);
    }
  }
  /** Get unique and sorted frameHeight arrays */
  const bayFrameHeightsPrev = [...new Set(frameHeightsWithPrev)].sort(
    (a, b) => a - b
  );

  /** Sort frameHeights after it has been used above */
  bayFrameHeights = bayFrameHeights.sort((a, b) => a - b);

  return {
    bayFrameHeights,
    bayFrameHeightsNext,
    bayFrameHeightsPrev,
  };
};

export const getNBayTiles = (bayHeight: number): number => {
  return Math.ceil(divide(bayHeight, 2));
};

export const getConnectedBayDimensions = (xBays: XBay[], idxXBay: number) => {
  const xBay = xBays[idxXBay];
  let maxConnectedScaleY = xBay.scale.y;
  let scaleYPrev = 0;
  let scaleYNext = 0;
  let maxConnectedPlatforms = xBay.platforms;
  let maxConnectedFallProtectionHeight = xBay.fallProtectionHeight;
  let prevXBay: typeof xBay | null = null;
  let nextXBay: typeof xBay | null = null;

  /** Assign prevBay if bay idx is higher than 0 */
  if (idxXBay > 0) {
    prevXBay = xBays[idxXBay - 1];
    scaleYPrev = prevXBay.scale.y;
  }

  /** Assign nextBay if bay idx is higher than last element */
  if (idxXBay < xBays.length - 1) {
    nextXBay = xBays[plus(idxXBay, 1)];
    scaleYNext = nextXBay.scale.y;

    /** Update maxScaleY and maxPlatforms if nextBay is higher than current */
    const maxIdx = indexOfMax([xBay.scale.y, scaleYNext]);
    const maxPlatformIdx = indexOfMax([
      Math.max(...xBay.platforms),
      Math.max(...nextXBay.platforms),
    ]);
    if ( xBay.fallProtectionHeight&& 
    nextXBay.fallProtectionHeight)
{    const maxFallProtectionHeight = indexOfMax([
      xBay.fallProtectionHeight,
      nextXBay.fallProtectionHeight,
    ]);

    if (maxFallProtectionHeight === 1) {
      maxConnectedFallProtectionHeight = nextXBay.fallProtectionHeight;
    }
}
    if (maxIdx === 1) {
      maxConnectedScaleY = scaleYNext;
    }

    if (maxPlatformIdx === 1) {
      maxConnectedPlatforms = nextXBay.platforms;
    }
  }

  return {
    prevXBay,
    nextXBay,
    scaleYNext,
    scaleYPrev,
    maxConnectedPlatforms,
    maxConnectedScaleY,
    maxConnectedFallProtectionHeight,
  };
};

/** Function to process xBay for corner scenarios
 *
 * @param xBay xBay object to be processed for corners
 * @param cornerScenarioEvents corner events that trigger when a corner scenario is found
 */
export const onCornerScenario = (xBay: XBay, handles?: CornerHandles) => {
  /** Extract in/out corners from xBay */
  const isInCorner = xBay.corners?.in;
  const isOutCorner = xBay.corners?.out;

  /** Find which corner scenario the xBay is in */
  if (isInCorner && isOutCorner) {
    /** Find if right or left corner for both in and out corner */
    const isInRightTurn =
      isInCorner.rotationDegrees ===
      SCAFFOLD_CORNER_ROTATION_DEGREES.DEGREES_90;
    const isInLeftTurn =
      isInCorner.rotationDegrees ===
      SCAFFOLD_CORNER_ROTATION_DEGREES.DEGREES_270;
    const isOutRightTurn =
      isOutCorner.rotationDegrees ===
      SCAFFOLD_CORNER_ROTATION_DEGREES.DEGREES_90;
    const isOutLeftTurn =
      isOutCorner.rotationDegrees ===
      SCAFFOLD_CORNER_ROTATION_DEGREES.DEGREES_270;

    /** Iterate over in corner, right and left turn */
    if (isInRightTurn) {
      if (isInCorner.type === SCAFFOLD_CORNER.ABOVE_BAY) {
        handles?.INOUT_IN_RIGHT_ABOVE_BAY?.();
      } else if (isInCorner.type === SCAFFOLD_CORNER.ABOVE_BAY_EXTRA) {
        handles?.INOUT_IN_RIGHT_ABOVE_BAY_EXTRA?.();
      } else if (isInCorner.type === SCAFFOLD_CORNER.IN_BAY) {
        handles?.INOUT_IN_RIGHT_IN_BAY?.();
      } else if (isInCorner.type === SCAFFOLD_CORNER.IN_BAY_EXTRA) {
        handles?.INOUT_IN_RIGHT_IN_BAY_EXTRA?.();
      }
    } else if (isInLeftTurn) {
      if (isInCorner.type === SCAFFOLD_CORNER.ABOVE_BAY) {
        handles?.INOUT_IN_LEFT_ABOVE_BAY?.();
      } else if (isInCorner.type === SCAFFOLD_CORNER.ABOVE_BAY_EXTRA) {
        handles?.INOUT_IN_LEFT_ABOVE_BAY_EXTRA?.();
      } else if (isInCorner.type === SCAFFOLD_CORNER.IN_BAY) {
        handles?.INOUT_IN_LEFT_IN_BAY?.();
      } else if (isInCorner.type === SCAFFOLD_CORNER.IN_BAY_EXTRA) {
        handles?.INOUT_IN_LEFT_IN_BAY_EXTRA?.();
      }
    }

    /** Iterate over out corner, right and left turn */
    if (isOutRightTurn) {
      if (isOutCorner.type === SCAFFOLD_CORNER.ABOVE_BAY) {
        handles?.INOUT_OUT_RIGHT_ABOVE_BAY?.();
      } else if (isOutCorner.type === SCAFFOLD_CORNER.ABOVE_BAY_EXTRA) {
        handles?.INOUT_OUT_RIGHT_ABOVE_BAY_EXTRA?.();
      } else if (isOutCorner.type === SCAFFOLD_CORNER.IN_BAY) {
        handles?.INOUT_OUT_RIGHT_IN_BAY?.();
      } else if (isOutCorner.type === SCAFFOLD_CORNER.IN_BAY_EXTRA) {
        handles?.INOUT_OUT_RIGHT_IN_BAY_EXTRA?.();
      }
    } else if (isOutLeftTurn) {
      if (isOutCorner.type === SCAFFOLD_CORNER.ABOVE_BAY) {
        handles?.INOUT_OUT_LEFT_ABOVE_BAY?.();
      } else if (isOutCorner.type === SCAFFOLD_CORNER.ABOVE_BAY_EXTRA) {
        handles?.INOUT_OUT_LEFT_ABOVE_BAY_EXTRA?.();
      } else if (isOutCorner.type === SCAFFOLD_CORNER.IN_BAY) {
        handles?.INOUT_OUT_LEFT_IN_BAY?.();
      } else if (isOutCorner.type === SCAFFOLD_CORNER.IN_BAY_EXTRA) {
        handles?.INOUT_OUT_LEFT_IN_BAY_EXTRA?.();
      }
    }
  } else if (isInCorner) {
    /** Find if right or left corner for in corner */
    const isInRightTurn =
      isInCorner.rotationDegrees ===
      SCAFFOLD_CORNER_ROTATION_DEGREES.DEGREES_90;
    const isInLeftTurn =
      isInCorner.rotationDegrees ===
      SCAFFOLD_CORNER_ROTATION_DEGREES.DEGREES_270;

    /** Iterate over in corner, right and left turn */
    if (isInRightTurn) {
      if (isInCorner.type === SCAFFOLD_CORNER.ABOVE_BAY) {
        handles?.IN_RIGHT_ABOVE_BAY?.();
      } else if (isInCorner.type === SCAFFOLD_CORNER.ABOVE_BAY_EXTRA) {
        handles?.IN_RIGHT_ABOVE_BAY_EXTRA?.();
      } else if (isInCorner.type === SCAFFOLD_CORNER.IN_BAY) {
        handles?.IN_RIGHT_IN_BAY?.();
      } else if (isInCorner.type === SCAFFOLD_CORNER.IN_BAY_EXTRA) {
        handles?.IN_RIGHT_IN_BAY_EXTRA?.();
      }
    } else if (isInLeftTurn) {
      if (isInCorner.type === SCAFFOLD_CORNER.ABOVE_BAY) {
        handles?.IN_LEFT_ABOVE_BAY?.();
      } else if (isInCorner.type === SCAFFOLD_CORNER.ABOVE_BAY_EXTRA) {
        handles?.IN_LEFT_ABOVE_BAY_EXTRA?.();
      } else if (isInCorner.type === SCAFFOLD_CORNER.IN_BAY) {
        handles?.IN_LEFT_IN_BAY?.();
      } else if (isInCorner.type === SCAFFOLD_CORNER.IN_BAY_EXTRA) {
        handles?.IN_LEFT_IN_BAY_EXTRA?.();
      }
    }
  } else if (isOutCorner) {
    /** Find if right or left corner for both in and out corner */
    const isOutRightTurn =
      isOutCorner.rotationDegrees ===
      SCAFFOLD_CORNER_ROTATION_DEGREES.DEGREES_90;
    const isOutLeftTurn =
      isOutCorner.rotationDegrees ===
      SCAFFOLD_CORNER_ROTATION_DEGREES.DEGREES_270;

    /** Iterate over out corner, right and left turn */
    if (isOutRightTurn) {
      if (isOutCorner.type === SCAFFOLD_CORNER.ABOVE_BAY) {
        handles?.OUT_RIGHT_ABOVE_BAY?.();
      } else if (isOutCorner.type === SCAFFOLD_CORNER.ABOVE_BAY_EXTRA) {
        handles?.OUT_RIGHT_ABOVE_BAY_EXTRA?.();
      } else if (isOutCorner.type === SCAFFOLD_CORNER.IN_BAY) {
        handles?.OUT_RIGHT_IN_BAY?.();
      } else if (isOutCorner.type === SCAFFOLD_CORNER.IN_BAY_EXTRA) {
        handles?.OUT_RIGHT_IN_BAY_EXTRA?.();
      }
    } else if (isOutLeftTurn) {
      if (isOutCorner.type === SCAFFOLD_CORNER.ABOVE_BAY) {
        handles?.OUT_LEFT_ABOVE_BAY?.();
      } else if (isOutCorner.type === SCAFFOLD_CORNER.ABOVE_BAY_EXTRA) {
        handles?.OUT_LEFT_ABOVE_BAY_EXTRA?.();
      } else if (isOutCorner.type === SCAFFOLD_CORNER.IN_BAY) {
        handles?.OUT_LEFT_IN_BAY?.();
      } else if (isOutCorner.type === SCAFFOLD_CORNER.IN_BAY_EXTRA) {
        handles?.OUT_LEFT_IN_BAY_EXTRA?.();
      }
    }
  } else {
    /** No corner */
    handles?.NO_CORNER?.();
  }
};
