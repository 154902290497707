export enum SCAFFOLD_MANUFACTURER {
  LAYHER = "layher",
  PLUSEIGHT = "pluseight",
  HAKI = "haki",
  RAMSCAFF = "ramscaff",
  MOSTOSTALRAM = "mostostalram",
  PLETTAC = "plettac",
  SPEEDYSCAF = "speedyscaf",
  ALUSCAFF = "aluscaff",
  SCAFOMRUX = "scafomrux",
  ALFIX = "alfix",
  SUPER9 = "super9",
  PERIUP = "periup",
}

export enum SCAFFOLD_TRSSYSTEM {
  KEDERXL = "kederXL",
  MONZON_PROTECT_IT = "MonZon PROTECT IT",
  HAKITEC750 = "hakitec750",
  HAKITEC450 = "hakitec450",
  UBIX780 = "ubix780",
  GIBSON = "gibson",
}

export enum SCAFFOLD_CONSTRUCTION {
  FACADE = "facade",
  FACADECAP = "facadeCap",
  ROOFCAP = "roofCap",
}

export enum SCAFFOLD_CLOTHING {
  NET = "net",
  PLASTIC = "plastic",
}

export enum SCAFFOLD_ANCHORDENSITY {
  EVERY_TWO = 2,
  EVERY_THREE = 3,
  EVERY_FOUR = 4,
}

export enum SCAFFOLD_ADDON {
  LOADINGBAY = "loadingbay",
  STAIR = "stair",
  ELEVATOR = "elevator",
  ELEVATORTIE = "elevatorTie",
  CONSOLE = "console",
  MEASURE = "measure",
}

export enum SCAFFOLD_PLANKTYPE {
  ALUMINUM_STEEL = "aluminium/steel",
  ALUMINUM_PLASTIC = "aluminium/plastic",
  ALUMINUM = "aluminum",
  ALUMINUM_ALUMINUM = "aluminium/aluminum",
  ALUMINUM_DUCKBOARD = "aluminium/duckboard",
  ALUMINUM_PLYWOOD = "aluminium/plywood",
  COMPOSITE = "composite",
  COMPOSITE_DUCKBOARD = "composite/duckboard",
  DUCKBOARD = "duckboard",
  HOOKPLANE = "hookplane",
  PLYWOOD = "plywood",
  ROBUST = "robust",
  STEEL = "steel",
  STEEL_DUCKBOARD = "steel/duckboard",
  WOOD = "wood",
  WOOD_DUCKBOARD = "wood/duckboard",
  WOOD_SOUTH_DUCKBOARD = "wood/south/duckboard",
}

export enum SCAFFOLD_LOADCLASS {
  LOADCLASS1 = 1,
  LOADCLASS2 = 2,
  LOADCLASS3 = 3,
  LOADCLASS4 = 4,
  LOADCLASS5 = 5,
  LOADCLASS6 = 6,
}

export enum WIND_STANDARD {
  EN = "EN",
  SSEN = "SSEN",
}

export enum SCAFFOLD_STANDARDLENGTH {
  M4 = 4,
  M3 = 3,
  M2 = 2,
  M1 = 1,
}

export enum SCAFFOLD_PLANKORIENTATION {
  TRANSVERSE = "transverse",
  LONGITUDINAL = "longitudinal",
}

export enum SCAFFOLD_CORNER {
  ABOVE_BAY = "aboveBay",
  ABOVE_BAY_EXTRA = "aboveBayExtra",
  IN_BAY = "inBay",
  IN_BAY_EXTRA = "inBayExtra",
}

export enum SCAFFOLD_CORNER_ROTATION_DEGREES {
  DEGREES_90 = 90,
  DEGREES_270 = 270,
}

export enum SCAFFOLD_CORNER_PIECE_TYPE {
  ATTACHING = "attaching",
  ORIGIN = "origin",
}

export enum POLYGON_TYPE {
  POLYGON = "polygon",
  RECTANGLE = "rectangle",
  CYLINDER = "cylinder",
}
