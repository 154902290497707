import { createSvgIcon } from "@mui/material";

const RoofIcon = createSvgIcon(
  <svg viewBox="0 0 24 24" fill="currentColor">
    <path d="M12.2 8L17.8 13V17.3H6.6V13L12.2 8ZM12.2 5L1 15H4.3V17.3H19.9V15.1H23.2L12.2 5Z" />
  </svg>,
  "Roof"
);

export default RoofIcon;
