import { createSvgIcon } from "@mui/material";

const SelectAllBaysIcon = createSvgIcon(
  <svg viewBox="0 0 24 24" fill="currentColor">
    <path d="M14.1231 19.5468C14.1023 19.549 14.0814 19.5502 14.0605 19.5502C13.8772 19.5502 13.6939 19.4585 13.5106 19.3669L12.6165 18.8689L12.6153 18.8696L13.5086 19.3671C13.6919 19.4588 13.8753 19.5504 14.0586 19.5504C14.0801 19.5504 14.1016 19.5492 14.1231 19.5468ZM11.6667 18.3412V18.3399L9.89608 17.3538L9.8961 17.3551L11.6667 18.3412ZM9.91386 18.5096L13.0415 20.2516L13.0614 20.2615L13.0926 20.2774C13.1635 20.3136 13.2837 20.3751 13.4354 20.4305L11.3 21.6169C11.1167 21.7085 10.9333 21.8002 10.75 21.8002C10.5667 21.8002 10.3833 21.7085 10.2 21.6169L2.95833 17.5835C2.68333 17.4002 2.5 17.1252 2.5 16.7585V8.50854C2.5 8.23354 2.68333 7.86688 2.95833 7.77521L4.80859 6.70617V10.4279L4.33333 10.1585L4.24167 16.3002L9.925 19.2335L9.91386 18.5096ZM5.80859 10.9945L5.81055 10.9956V8.90851L5.80859 8.90744V10.9945ZM5.80859 8.26506V6.25876C5.80859 6.2145 5.81334 6.16786 5.82246 6.12038L5.82461 6.11914C5.81536 6.16698 5.81055 6.21397 5.81055 6.25855V8.26388L5.80859 8.26506Z" />
    <path d="M16.2695 8.10061L15.0646 8.82356L14.0565 9.37342L8.55791 6.34918L9.93257 5.52438L10.9406 4.97452L12.7695 5.9804V5.2C12.7695 4.79812 12.9671 4.44238 13.2704 4.2246L11.4905 3.23329C11.3072 3.05 11.1239 3.05 10.9406 3.05C10.7574 3.05 10.5741 3.05 10.3908 3.14164L6.26681 5.52438C5.99188 5.61603 5.80859 5.9826 5.80859 6.25753V14.5055C5.80859 14.8721 5.99188 15.147 6.26681 15.3303L13.5067 19.3626C13.69 19.4542 13.8733 19.5459 14.0565 19.5459C14.2398 19.5459 14.4231 19.4542 14.6064 19.3626L18.7304 17.0715C19.0053 16.8882 19.1886 16.6133 19.1886 16.2467V11.4942C19.1494 11.498 19.1097 11.5 19.0695 11.5H17.4695C17.4311 11.5 17.3932 11.4982 17.3557 11.4947V15.7885L14.973 17.1631V11.023L16.2695 10.275V8.10061ZM14.6233 7H17.0695C17.18 7 17.2695 7.08954 17.2695 7.2V9.69808L17.3557 9.64836V10.4645C17.388 10.4869 17.4272 10.5 17.4695 10.5H19.0695C19.1141 10.5 19.1553 10.4854 19.1886 10.4607V7.99877C19.1886 7.72383 19.0053 7.35726 18.7304 7.26562L14.6626 5H13.9695C13.8591 5 13.7695 5.08954 13.7695 5.2V6.5304L14.6233 7ZM7.54983 14.0473L7.64147 7.90712L13.1401 11.023L13.2317 16.9799L7.54983 14.0473Z" />
    <path d="M22.7695 7V5H19.2695V1.5H17.2695V5H13.7695V7H17.2695V10.5H19.2695V7H22.7695Z" />
  </svg>,
  "SelectAllBays"
);

export default SelectAllBaysIcon;
