import { FC } from "react";
import { Outlet } from "react-router-dom";
import CenterLayoutProps from "./CenterLayout.types";
import Box from "@mui/material/Box";

const CenterLayout: FC<CenterLayoutProps> = (props) => {
  const { style } = props;

  return (
    <Box
      component="div"
      sx={{
        display: "flex",
        height: "100%",
        left: 0,
        position: "absolute",
        top: 0,
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        ...style,
      }}
    >
      <Outlet />
    </Box>
  );
};

export default CenterLayout;
