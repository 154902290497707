import { FC, useCallback, useEffect, useRef } from "react";
import { authentication, database } from "../../services";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  useCompanyActions,
  useLoadingActions,
  useSnackbarActions,
  useUserActions,
} from "../../store";
import { Unsubscribe } from "firebase/auth";
import SessionManagerProps from "./SessionManager.types";
import { storage } from "../../services/storage";

const SessionManager: FC<SessionManagerProps> = (props) => {
  /** Hook: store userActions */
  const {
    setUserEmail,
    setUserFirstName,
    setUserLastName,
    setUserId,
    clearUser,
    setUserPreferences,
    setUserAccountType,
    setUserExpires,
    setUserRole,
    setUserAchievements,
  } = useUserActions();

  /** Hook: store companyAction */
  const { setCompanyName, setCompanyId, setCompanyUsers, setCompanyLogoUrl } =
    useCompanyActions();

  /** Hook: store loadingActions */
  const { setLoading } = useLoadingActions();

  /** Hook: navigation */
  const navigate = useNavigate();

  /** Hook: snackbar */
  const { setSnackbarOpen, setSnackbarType, setSnackbarText } =
    useSnackbarActions();

  /** Hook: location */
  const location = useLocation();

  /** Ref: unsubscribe function for onAuthStateChanged */
  const unSubOnAuthStateChanged = useRef<void | Unsubscribe>();
  /** Ref: unsubscribe function for onUpdateUserDoc */
  const unSubOnUpdateUserDoc = useRef<void | Unsubscribe>();
  /** Ref: unsubscribe function for onUpdateCompanyDoc */
  const unSubOnUpdateCompanyDoc = useRef<void | Unsubscribe>();
  /** Ref: unsubscribe function for onUpdateCompanyUsersDoc */
  const unSubOnUpdateCompanyUserDocs = useRef<void | Unsubscribe>();

  /** Callback: error handler */
  const handleOnError = useCallback(
    (error?: any) => {
      clearUser();
      if (unSubOnUpdateUserDoc.current) unSubOnUpdateUserDoc.current();
      if (unSubOnUpdateCompanyDoc.current) unSubOnUpdateCompanyDoc.current();
      if (unSubOnUpdateCompanyUserDocs.current)
        unSubOnUpdateCompanyUserDocs.current();
      setLoading(false);
      navigate("/login");
    },
    [clearUser, navigate, setLoading]
  );

  /** Effect: on mount */
  useEffect(() => {
    setLoading(true);

    /** Setup listener on auth object and provide onUpdate function */
    unSubOnAuthStateChanged.current = authentication.onSessionUpdate(
      (authUser) => {
        if (authUser) {
          const { uid, emailVerfied } = authUser;

          if (!emailVerfied) {
            setSnackbarText("Please verify your email address");
            setSnackbarType("negative");
            setSnackbarOpen(true);
            authentication.signOut();
            return;
          }

          /** Setup user document listener and provide onSuccess function */
          unSubOnUpdateUserDoc.current = database.onUpdateUserDoc({
            uid,
            onSuccess: (userDoc) => {
              const {
                email,
                firstName,
                lastName,
                companyId,
                preferences,
                accountType,
                expires,
                role,
                active,
                achievements,
              } = userDoc;
              if (!active) return handleOnError();

              setUserEmail(email);
              setUserId(uid);
              setUserFirstName(firstName);
              setUserLastName(lastName);
              setCompanyId(companyId);
              setUserPreferences(preferences);
              setUserAccountType(accountType);
              setUserExpires(expires.toDate());
              setUserRole(role);

              if (achievements) setUserAchievements(achievements);

              /** Setup company document listener and provide onSuccess function */
              unSubOnUpdateCompanyDoc.current = database.onUpdateCompanyDoc({
                companyId,
                onSuccess: (companyDoc) => {
                  const { name } = companyDoc;
                  setCompanyName(name);
                  storage.getCompanyLogoDownloadUrl({
                    companyId,
                    onSuccess: (logoUrl) => {
                      setCompanyLogoUrl(logoUrl);
                    },
                    onError: () => {},
                  });
                  setLoading(false);
                },
                onError: (error) => {
                  /** Failing to fetch user document is treated as error */
                  handleOnError(error);
                },
              });

              /** Setup company document listener and provide onSuccess function */
              unSubOnUpdateCompanyUserDocs.current =
                database.onUpdateCompanyUserDocs({
                  companyId,
                  onSuccess: (userDocs) => {
                    setCompanyUsers(userDocs);
                  },
                  onError: (error) => {
                    /** Failing to fetch user document is treated as error */
                    handleOnError(error);
                  },
                });
            },
            onError: (error) => {
              /** Failing to fetch user document is treated as error */
              handleOnError(error);
            },
          });

          /** Navigate to /home if current route is /login
           * otherwise continue with specified route
           */
          if (location.pathname === "/login") navigate("/home");
        } else {
          handleOnError();
        }
      }
    );
  }, [
    handleOnError,
    location.pathname,
    navigate,
    setUserEmail,
    setUserId,
    setCompanyName,
    setCompanyId,
    setLoading,
    setUserPreferences,
    setCompanyUsers,
    setCompanyLogoUrl,
    setUserAccountType,
    setUserExpires,
    setUserRole,
    setUserFirstName,
    setUserLastName,
    setSnackbarText,
    setSnackbarType,
    setSnackbarOpen,
    setUserAchievements,
  ]);

  return <Outlet />;
};

export default SessionManager;
