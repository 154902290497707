import { FIREBASE_PROJECTID } from "../src/firebase/firebase.types";
import { DatabaseServiceProvider } from "./database.enum";
import { FirebaseFirestore } from "../src/firebase";
import getConfig from "../src/firebase/firebase.config";

export const initDatabaseService = (
  databaseProvider: DatabaseServiceProvider
) => {
  /** Extract projectId from env. variables */
  const envProjectId = process.env.REACT_APP_FB_ENV as FIREBASE_PROJECTID;

  if (databaseProvider === DatabaseServiceProvider.FIREBASE) {
    /** Get firebase config and return the FirebaseAuthentication service as the authService */
    const firebaseConfig = getConfig(envProjectId);
    return new FirebaseFirestore({
      config: firebaseConfig,
      isLocalDev: envProjectId === FIREBASE_PROJECTID.LOCAL,
    });
  }

  /** Default return: Get firebase config and return the FirebaseAuthentication service as the authService */
  const firebaseConfig = getConfig(envProjectId);
  return new FirebaseFirestore({
    config: firebaseConfig,
    isLocalDev: envProjectId === FIREBASE_PROJECTID.LOCAL,
  });
};
