export enum ELEVATOR_TIEORIENTATION {
  RIGHT = "right",
  LEFT = "left",
}

export enum ELEVATOR_MANUFACTURER {
  ALIMAK = "alimak",
}

export enum ELEVATOR_MODEL {
  ALIMAK_TPL1000 = "ALIMAKTPL1000",
}
