export enum DRAW_BOXTYPE {
  ACCESSDECK = "accessdeck",
  BAY = "bay",
  CONSOLE = "console",
  ELEVATOR = "elevator",
  ELEVATORTIE = "elevatorTie",
  LOADINGBAY = "loadingbay",
  STAIR = "stair",
}

export enum DRAW_ROOFTYPE {
  GABLE = "gable",
  SHED = "shed",
}
