import { ActionCodeInfo, Unsubscribe } from "firebase/auth";

export interface AuthenticationService {
  signIn: (email: string, password: string) => Promise<AuthUser>;
  signInWithToken: (token: string) => void;
  signOut: () => void;
  onSessionUpdate: (
    onUpdate: (user: AuthUser | null) => void
  ) => Unsubscribe | void;
  setupLocalEmulator: () => void;
  sendPasswordResetEmail: (email: string) => Promise<void>;
  confirmPasswordReset: (code: string, newPassword: string) => Promise<void>;
  verifyPasswordResetCode: (code: string) => Promise<string>;
  checkActionCode: (code: string) => Promise<ActionCodeInfo>;
  changePassword: (
    currentPassword: string,
    newPassword: string
  ) => Promise<void>;
  getToken: () => Promise<string | undefined>;
}

export interface AuthUser {
  emailVerfied: boolean;
  refreshToken: string;
  uid: string;
}

export enum AuthenticationServiceProvider {
  FIREBASE = "firebase",
}
