interface ResetPasswordPageProps {
  actionCode: string;
}

export enum RESET_MODES {
  SEND_NEW_LINK = "sendnewlink",
  RESET_PASSWORD = "resetpassword",
}

export default ResetPasswordPageProps;
