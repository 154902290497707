import {
  BAYLENGTHS,
  BAYWIDTHS,
  CONSOLEWIDTHS,
  LOADINGBAYLENGTHS,
  LOADINGBAYLOADCLASSES,
  LOADINGBAYWIDTHS,
  PLANKORIENTATIONS,
  PLANKTYPES,
  PLANKWIDTHS,
  STAIRLENGTHS,
  STAIRWIDTHS,
  STANDARDLENGTHS,
} from "./constants";
import { SCAFFOLDTYPES } from "./enums";
import Supplier from "../Supplier";
import { TFunction } from "i18next";
import {
  SCAFFOLD_ANCHORDENSITY,
  SCAFFOLD_CORNER,
  SCAFFOLD_LOADCLASS,
  SCAFFOLD_PLANKORIENTATION,
  SCAFFOLD_PLANKTYPE,
  SCAFFOLD_STANDARDLENGTH,
} from "../../../enums";

export class PeriUp implements Supplier {
  bayLengths: typeof BAYLENGTHS;
  bayWidths: typeof BAYWIDTHS;
  consoleWidths: typeof CONSOLEWIDTHS;
  loadingBayLengths: typeof LOADINGBAYLENGTHS;
  loadingBayWidths: typeof LOADINGBAYWIDTHS;
  plankOrientations: typeof PLANKORIENTATIONS;
  plankTypes: typeof PLANKTYPES;
  plankWidths: typeof PLANKWIDTHS;
  scaffoldType: SCAFFOLDTYPES;
  stairLengths: typeof STAIRLENGTHS;
  stairWidths: typeof STAIRWIDTHS;
  standardLengths: typeof STANDARDLENGTHS;

  constructor(scaffoldType: SCAFFOLDTYPES = SCAFFOLDTYPES.MODULE) {
    this.bayLengths = BAYLENGTHS;
    this.bayWidths = BAYWIDTHS;
    this.consoleWidths = CONSOLEWIDTHS;
    this.loadingBayLengths = LOADINGBAYLENGTHS;
    this.loadingBayWidths = LOADINGBAYWIDTHS;
    this.plankOrientations = PLANKORIENTATIONS;
    this.plankTypes = PLANKTYPES;
    this.plankWidths = PLANKWIDTHS;
    this.scaffoldType = scaffoldType;
    this.stairLengths = STAIRLENGTHS;
    this.stairWidths = STAIRWIDTHS;
    this.standardLengths = STANDARDLENGTHS;
  }

  getBayLengths = (
    plankType: SCAFFOLD_PLANKTYPE,
    loadClass: SCAFFOLD_LOADCLASS
  ) => {
    if (plankType === this.plankTypes.STEEL) {
      if (loadClass === SCAFFOLD_LOADCLASS.LOADCLASS6) {
        return [
          this.bayLengths.MM150,
          this.bayLengths.MM250,
          this.bayLengths.MM330,
          this.bayLengths.MM500,
          this.bayLengths.MM670,
          this.bayLengths.MM750,
          this.bayLengths.MM1000,
          this.bayLengths.MM1250,
          this.bayLengths.MM1500,
          this.bayLengths.MM2000,
        ];
      } else if (loadClass === SCAFFOLD_LOADCLASS.LOADCLASS5) {
        return [
          this.bayLengths.MM150,
          this.bayLengths.MM250,
          this.bayLengths.MM330,
          this.bayLengths.MM500,
          this.bayLengths.MM670,
          this.bayLengths.MM750,
          this.bayLengths.MM1000,
          this.bayLengths.MM1250,
          this.bayLengths.MM1500,
          this.bayLengths.MM2000,
          this.bayLengths.MM2500,
        ];
      } else if (loadClass <= SCAFFOLD_LOADCLASS.LOADCLASS4) {
        return [
          this.bayLengths.MM150,
          this.bayLengths.MM250,
          this.bayLengths.MM330,
          this.bayLengths.MM500,
          this.bayLengths.MM670,
          this.bayLengths.MM750,
          this.bayLengths.MM1000,
          this.bayLengths.MM1250,
          this.bayLengths.MM1500,
          this.bayLengths.MM2000,
          this.bayLengths.MM2500,
          this.bayLengths.MM3000,
        ];
      } else {
        return [];
      }
    } else {
      return [];
    }
  };

  getBayWidths = () => {
    return Object.values(this.bayWidths);
  };

  getLoadClasses = (plankType: SCAFFOLD_PLANKTYPE) => {
    if (plankType === this.plankTypes.STEEL) {
      return [
        SCAFFOLD_LOADCLASS.LOADCLASS1,
        SCAFFOLD_LOADCLASS.LOADCLASS2,
        SCAFFOLD_LOADCLASS.LOADCLASS3,
        SCAFFOLD_LOADCLASS.LOADCLASS4,
        SCAFFOLD_LOADCLASS.LOADCLASS5,
        SCAFFOLD_LOADCLASS.LOADCLASS6,
      ];
    } else {
      return [];
    }
  };

  getLoadingBayLengths = () => Object.values(LOADINGBAYLENGTHS);

  getLoadingBayWidths = () => Object.values(LOADINGBAYWIDTHS);

  getAnchorDensities = () => [
    SCAFFOLD_ANCHORDENSITY.EVERY_FOUR,
    SCAFFOLD_ANCHORDENSITY.EVERY_THREE,
    SCAFFOLD_ANCHORDENSITY.EVERY_TWO,
  ];

  getStandardLengths = () => [
    SCAFFOLD_STANDARDLENGTH.M4,
    SCAFFOLD_STANDARDLENGTH.M3,
    SCAFFOLD_STANDARDLENGTH.M2,
  ];

  getConsoleWidths = () => Object.values(CONSOLEWIDTHS);

  getLoadingBayLoadClasses = () => Object.values(LOADINGBAYLOADCLASSES);

  getStairWidths = () => Object.values(STAIRWIDTHS);

  getStairLengths = () => Object.values(STAIRLENGTHS);

  getBayPlankConfiguration = (bayWidth: number) => {
    // if (bayWidth === this.bayWidths.MM450) {
    //   const A = [this.plankWidths.MM320];
    //   return [A];
    if (bayWidth === this.bayWidths.MM750) {
      const A = Array(3).fill(this.plankWidths.MM250);
      return [A];
    } else if (bayWidth === this.bayWidths.MM1000) {
      const A = Array(4).fill(this.plankWidths.MM250);
      const B = Array(2).fill(this.plankWidths.MM375);
      return [A, B];
    } else if (bayWidth === this.bayWidths.MM1500) {
      const A = Array(6).fill(this.plankWidths.MM250);
      return [A];
    } else if (bayWidth === this.bayWidths.MM1750) {
      const A = Array(7).fill(this.plankWidths.MM250);
      return [A];
    } else if (bayWidth === this.bayWidths.MM2000) {
      const A = Array(8).fill(this.plankWidths.MM250);
      return [A];
    } else if (bayWidth === this.bayWidths.MM2500) {
      const A = Array(10).fill(this.plankWidths.MM250);
      return [A];
    } else if (bayWidth === this.bayWidths.MM3000) {
      const A = Array(12).fill(this.plankWidths.MM250);
      return [A];
    } else {
      return [];
    }
  };

  getLoadingBayPlankConfiguration = (loadingBayWidth: number) => {
    return this.getBayPlankConfiguration(loadingBayWidth);
  };

  getConsolePlankConfiguration = (consoleWidth: number) => {
    if (consoleWidth === this.consoleWidths.MM250) {
      const A = [this.plankWidths.MM250];
      return [A];
    } else if (consoleWidth === this.consoleWidths.MM375) {
      const A = [this.plankWidths.MM375];
      return [A];
    } else if (consoleWidth === this.consoleWidths.MM500) {
      const A = [this.plankWidths.MM250, this.plankWidths.MM250];
      return [A];
    } else if (consoleWidth === this.consoleWidths.MM750) {
      const A = Array(3).fill(this.plankWidths.MM250);
      return [A];
    } else {
      return [];
    }
  };

  getCornerConfigurations = () => {
    return [
      SCAFFOLD_CORNER.ABOVE_BAY,
      SCAFFOLD_CORNER.ABOVE_BAY_EXTRA,
      SCAFFOLD_CORNER.IN_BAY,
      SCAFFOLD_CORNER.IN_BAY_EXTRA,
    ];
  };

  getPlankingOptions = (t: TFunction) => {
    return [
      {
        value: this.plankTypes.STEEL + this.plankWidths.MM250,
        material: this.plankTypes.STEEL,
        label: t("options.plankingtype.periup.steel25"),
        width: this.plankWidths.MM250,
        orientation: SCAFFOLD_PLANKORIENTATION.LONGITUDINAL,
      },
      {
        value: this.plankTypes.STEEL + this.plankWidths.MM375,
        material: this.plankTypes.STEEL,
        label: t("options.plankingtype.periup.steel375"),
        width: this.plankWidths.MM375,
        orientation: SCAFFOLD_PLANKORIENTATION.LONGITUDINAL,
      },
    ];
  };
}

export default PeriUp;
