import {
  ArrayLoadingbayInterface,
  ArrayStairInterface,
  StandardPositions,
  Vector3Arr,
} from "@scaffcalc/backends-firebase";
import * as NP from "number-precision";
import { Vector3 } from "three";

/** Disable boundry check
 * Link: https://www.npmjs.com/package/number-precision#:~:text=NP.enableBoundaryChecking(false)%3B
 */
NP.enableBoundaryChecking(false);

/** Local const */
const epsilonComparingNumbers = 0.00001;

/** Substracts values precisly
 *
 * @param {...*} values values to subtract
 * @returns {number} substraction result
 * @example minus(0.1, 0.2) // -0.1
 */
export const minus = (...values: number[]) => NP.minus(...values);

/** Addition of values precisly
 *
 * @param {...*} values values to add
 * @returns {number} addition result
 * @example plus(0.1, 0.2) // 0.3
 */
export const plus = (...values: number[]) => NP.plus(...values);

/** Division of values precisly
 *
 * If any of the values is 0, NaN or null, returns 0
 * @param {...*} values values to divide
 * @returns {number} division result
 * @example divide(0.1, 0.2) // 0.5
 */
export const divide = (...values: number[]) => {
  if (values.some((x) => x === 0 || isNaN(x) || x === null)) {
    return 0;
  } else {
    return NP.divide(...values);
  }
};

/** Divides with 2 precisly
 * @param {number} value value to halfen
 * @returns {number} division result
 * @example half(0.1) // 0.05
 */
export const half = (value: number) => divide(value, 2);

/** Divides with 3 precisly
 * @param {number} value value to divide by three
 * @returns {number} division result
 * @example third(0.3) // 0.1
 */
export const third = (value: number) => divide(value, 3);

/** Divides with 4 precisly
 * @param {number} value value to divide by four
 * @returns {number} division result
 * @example fourth(0.4) // 0.1
 */
export const fourth = (value: number) => divide(value, 4);

/** Multiplication of values precisly
 *
 * @param {...*} values values to multiply
 * @returns {number} multiplication result
 * @example times(0.1, 0.2) // 0.02
 */
export const times = (...values: number[]) => NP.times(...values);

/** Round a number to a specific decimal
 *
 * @param {number} value value to round
 * @param {number} decimal decimal to round to
 * @returns {number} rounded value
 * @example roundTo(0.123456, 2) // 0.12
 */
export const round = (value: number, decimal: number) =>
  NP.round(value, decimal);

/** Strip a number of trailing decimals
 *
 * @param {number} value
 * @returns {number} stripped value
 * @example strip(0.09999999999999998) // 0.1
 */
export const strip = (value: number) => NP.strip(value);

/** Round a number to the nearest value
 * @param {number} value value to round
 * @param {number} fraction fraction to round to
 * @returns {number} rounded value
 * @example toNearest(0.0005, 0.25) // 0.25
 */
export const toNearest = (value: number, fraction: number) =>
  times(Math.ceil(divide(value, fraction)), fraction);

/** Calculate the sum of an array of numbers
 *
 * @param {Array} arr array of numbers
 * @returns
 */
export const average = (arr: Array<number>) =>
  divide(
    arr.reduce((p, c) => plus(p, c), 0),
    arr.length
  );

export const numberEquals = (x: number, y: number) => {
  return Math.abs(x - y) < epsilonComparingNumbers;
};

/** Calculate a sum of an array of numbers
 *
 * @param {Array} arr array of numbers
 * @returns
 */
export const sum = (arr: Array<number>) => arr.reduce((p, c) => plus(p, c), 0);

/** Calculate the median of an array of numbers
 *
 * @param {Array} arr array of numbers
 * @returns
 */
export const median = (arr: Array<number>) => {
  const mid = Math.floor(arr.length / 2),
    nums = [...arr].sort((a, b) => a - b);
  return arr.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
};

/** Calculate the mode of an array of numbers
 *
 * @param {Array} arr array of numbers
 * @returns
 */
export const mode = (arr: number[]) => {
  if (arr.length === 0) {
    return 0;
  }

  const m = arr
    .reduce((items: any, current: any) => {
      const item =
        items.length === 0 ? null : items.find((x: any) => x.value === current);
      item ? item.occurrence++ : items.push({ value: current, occurrence: 1 });
      return items;
    }, [])
    .sort((a: any, b: any) => {
      if (a.occurrence < b.occurrence) {
        return 1;
      } else if (a.occurrence > b.occurrence || a.value < b.value) {
        return -1;
      } else {
        return a.value === b.value ? 0 : 1;
      }
    });

  return m[0].value;
};

export const range = (arr: number[]) => {
  const min = Math.min(...arr);
  const max = Math.max(...arr);
  return max - min;
};

export const standardDeviation = (arr: number[]) => {
  const avg = average(arr);
  const squareDiffs = arr.map((value) => {
    const diff = value - avg;
    const sqrDiff = diff * diff;
    return sqrDiff;
  });
  const avgSquareDiff = average(squareDiffs);
  const stdDev = Math.sqrt(avgSquareDiff);
  return stdDev;
};

export const variance = (arr: number[]) => {
  const avg = average(arr);
  const squareDiffs = arr.map((value) => {
    const diff = value - avg;
    const sqrDiff = diff * diff;
    return sqrDiff;
  });
  const avgSquareDiff = average(squareDiffs);
  return avgSquareDiff;
};

export const getMagnified = (params: {
  widthToAdd: number;
  startPoint: Vector3Arr;
  endPoint: Vector3Arr;
  outerRightStandard: Vector3Arr;
  outerLeftStandard: Vector3Arr;
}): { outerLeft: Vector3Arr; outerRight: Vector3Arr } => {
  const {
    widthToAdd,
    startPoint,
    endPoint,
    outerRightStandard,
    outerLeftStandard,
  } = params;
  const decimals = 4;

  const startPointVector: Vector3 = new Vector3(...startPoint);

  const outerRightStandardVector: Vector3 = new Vector3(...outerRightStandard);
  const outerLeftStandardVector: Vector3 = new Vector3(...outerLeftStandard);
  const endPointVector: Vector3 = new Vector3(...endPoint);

  const outerLeftNormalised = endPointVector
    .clone()
    .sub(startPointVector)
    .normalize();
  const outerLeftMagnified = outerLeftNormalised.multiplyScalar(widthToAdd);

  const finalOuterLeft = outerLeftMagnified
    .clone()
    .add(outerLeftStandardVector)
    .toArray()
    .map((x) => round(x, decimals)) as Vector3Arr;

  const finalOuterRight = outerLeftMagnified
    .clone()
    .add(outerRightStandardVector)
    .toArray()
    .map((x) => round(x, decimals)) as Vector3Arr;

  return { outerLeft: finalOuterLeft, outerRight: finalOuterRight };
};

export const getScaledNormalised = (params: {
  startPoint: Vector3Arr;
  endPoint: Vector3Arr;
  scale: number;
}): Vector3Arr => {
  const { startPoint, endPoint, scale } = params;

  const startPointVector: Vector3 = new Vector3(...startPoint);
  const endPointVector: Vector3 = new Vector3(...endPoint);

  const normalised = endPointVector.clone().sub(startPointVector).normalize();
  const scaled = normalised.multiplyScalar(scale);

  return scaled.toArray() as Vector3Arr;
};

export const getAddonStandardPositions = (params: {
  width: number;
  attachedBayId: string;
  attachedBayStandardPositions: StandardPositions;
  loadingBays: ArrayLoadingbayInterface[];
  stairs: ArrayStairInterface[];
  addonDepthPos: number;
}): StandardPositions => {
  const {
    width,
    attachedBayId,
    attachedBayStandardPositions,
    loadingBays,
    stairs,
    addonDepthPos,
  } = params;
  let standardPositions: StandardPositions = {};
  let prevStandardPositions: StandardPositions = {};

  const addons = [...loadingBays, ...stairs];
  const bayAddons = addons.filter((x) => x.bayId === attachedBayId);
  if (bayAddons.length > 1 && addonDepthPos > 1) {
    const depthSortedAddons = bayAddons.sort(
      (a, b) => a.position[0] - b.position[0]
    );

    // do not include current addon, (pos is not same as index)
    const addonsBeforeCurrentAddon = depthSortedAddons.slice(
      0,
      addonDepthPos - 1
    );
    const accumulatedWidthOuterStandard = addonsBeforeCurrentAddon.reduce(
      (acc, curr) => plus(acc, curr.scale[0]),
      0
    );

    const accumulatedWidthInnerStandard =
      addonsBeforeCurrentAddon.length > 1
        ? addonsBeforeCurrentAddon
            .slice(0, addonsBeforeCurrentAddon.length - 1)
            .reduce((acc, curr) => plus(acc, curr.scale[0]), 0)
        : 0;

    if (
      attachedBayStandardPositions.outerLeft &&
      attachedBayStandardPositions.outerRight &&
      attachedBayStandardPositions.innerLeft
    ) {
      const outerStandards = getMagnified({
        widthToAdd: accumulatedWidthOuterStandard,
        startPoint: attachedBayStandardPositions.innerLeft,
        endPoint: attachedBayStandardPositions.outerLeft,
        outerLeftStandard: attachedBayStandardPositions.outerLeft,
        outerRightStandard: attachedBayStandardPositions.outerRight,
      });
      const innerStandards = getMagnified({
        widthToAdd: accumulatedWidthInnerStandard,
        startPoint: attachedBayStandardPositions.innerLeft,
        endPoint: attachedBayStandardPositions.outerLeft,
        outerLeftStandard: attachedBayStandardPositions.outerLeft,
        outerRightStandard: attachedBayStandardPositions.outerRight,
      });
      prevStandardPositions = {
        outerLeft: outerStandards.outerLeft,
        outerRight: outerStandards.outerRight,
        innerLeft: innerStandards.outerLeft,
        innerRight: innerStandards.outerRight,
      };
    } else if (
      attachedBayStandardPositions.outerLeft &&
      attachedBayStandardPositions.outerRight &&
      attachedBayStandardPositions.innerRight
    ) {
      const outerStandards = getMagnified({
        widthToAdd: accumulatedWidthOuterStandard,
        startPoint: attachedBayStandardPositions.innerRight,
        endPoint: attachedBayStandardPositions.outerRight,
        outerLeftStandard: attachedBayStandardPositions.outerLeft,
        outerRightStandard: attachedBayStandardPositions.outerRight,
      });

      const innerStandards = getMagnified({
        widthToAdd: accumulatedWidthOuterStandard,
        startPoint: attachedBayStandardPositions.innerRight,
        endPoint: attachedBayStandardPositions.outerRight,
        outerLeftStandard: attachedBayStandardPositions.outerLeft,
        outerRightStandard: attachedBayStandardPositions.outerRight,
      });

      prevStandardPositions = {
        outerLeft: outerStandards.outerLeft,
        outerRight: outerStandards.outerRight,
        innerLeft: innerStandards.outerLeft,
        innerRight: innerStandards.outerRight,
      };
    }
  } else {
    prevStandardPositions = attachedBayStandardPositions;
  }

  if (prevStandardPositions.outerRight && prevStandardPositions.outerLeft) {
    standardPositions.innerLeft = prevStandardPositions.outerLeft;
    standardPositions.innerRight = prevStandardPositions.outerRight;
  }

  let outerStandards: { outerLeft?: Vector3Arr; outerRight?: Vector3Arr } = {};
  if (
    prevStandardPositions.outerLeft &&
    prevStandardPositions.innerLeft &&
    prevStandardPositions.outerRight
  ) {
    outerStandards = getMagnified({
      widthToAdd: width,
      startPoint: prevStandardPositions.innerLeft,
      endPoint: prevStandardPositions.outerLeft,
      outerLeftStandard: prevStandardPositions.outerLeft,
      outerRightStandard: prevStandardPositions.outerRight,
    });
  } else if (
    prevStandardPositions.outerRight &&
    prevStandardPositions.innerRight &&
    prevStandardPositions.outerLeft
  ) {
    outerStandards = getMagnified({
      widthToAdd: width,
      startPoint: prevStandardPositions.innerRight,
      endPoint: prevStandardPositions.outerRight,
      outerLeftStandard: prevStandardPositions.outerLeft,
      outerRightStandard: prevStandardPositions.outerRight,
    });
  }

  standardPositions.outerLeft = outerStandards.outerLeft;
  standardPositions.outerRight = outerStandards.outerRight;

  if (standardPositions.innerLeft)
    standardPositions.innerLeft = standardPositions.innerLeft.map((x) =>
      round(x, 3)
    ) as Vector3Arr;
  if (standardPositions.innerRight)
    standardPositions.innerRight = standardPositions.innerRight.map((x) =>
      round(x, 3)
    ) as Vector3Arr;
  if (standardPositions.outerLeft)
    standardPositions.outerLeft = standardPositions.outerLeft.map((x) =>
      round(x, 3)
    ) as Vector3Arr;
  if (standardPositions.outerRight)
    standardPositions.outerRight = standardPositions.outerRight.map((x) =>
      round(x, 3)
    ) as Vector3Arr;

  return standardPositions;
};

export const subtractHalfDistance = (base: number, distance: number): number =>
  minus(base, half(distance));

export const addHalfDistance = (base: number, distance: number): number =>
  plus(base, half(distance));
