import { FC, useEffect, useRef, useState } from "react";
import UpdateNotificationOverlayProps from "./UpdateNotificationOverlay.types";
import { Backdrop, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Modal, Banner } from "@scaffcalc/packages-ui";
import { Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useUserAccountType } from "../../store";
import { USER_ACCOUNTTYPE, ReleaseDoc } from "@scaffcalc/backends-firebase";
import { Unsubscribe } from "firebase/firestore";
import { database } from "../../services";
import { diff } from "semver";

const UpdateNotificationOverlay: FC<UpdateNotificationOverlayProps> = () => {
  const unSubReleaseDocs = useRef<void | Unsubscribe>();

  const [currentVersion, setCurrentVersion] = useState<string>("");

  const [majorUpdate, setMajorUpdate] = useState<boolean>(false);
  const [minorUpdate, setMinorUpdate] = useState<boolean>(false);

  useEffect(() => {
    unSubReleaseDocs.current = database.onUpdateReleaseDocs({
      onSuccess: (releaseDocs: ReleaseDoc[]) => {
        if (releaseDocs.length === 0) return;

        const latestRelease = releaseDocs.reduce((a, b) => {
          return a.date.toDate() > b.date.toDate() ? a : b;
        });

        if (currentVersion === "") {
          setCurrentVersion(latestRelease.version);
          return;
        }

        const difference = diff(currentVersion, latestRelease.version);

        if (difference === null) return;

        if (difference === "major") {
          setMajorUpdate(true);
        } else {
          setMinorUpdate(true);
        }

        setCurrentVersion(latestRelease.version);
      },
      onError: () => {},
    });

    /** Effect: on un-mount */
    return () => {
      if (unSubReleaseDocs.current) unSubReleaseDocs.current();
    };
  }, [currentVersion]);

  const { t } = useTranslation();

  const userAccountType = useUserAccountType();

  const updateHandler = () => {
    window.location.reload();
  };

  const closeHandler = () => {
    setMinorUpdate(false);
  };

  return (
    <>
      {majorUpdate && (
        <Backdrop
          open={majorUpdate}
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
        >
          <Modal
            maxWidth="sm"
            header={t("notes.majorupdatetitle")}
            open={majorUpdate}
            body={
              <Typography variant="body1">
                {t("notes.majorupdatebody")}
              </Typography>
            }
            actions={
              <Button
                color="blue"
                text={t("actions.updatenow")}
                onClick={updateHandler}
              />
            }
          />
        </Backdrop>
      )}

      {minorUpdate && (
        <Banner
          header={t("notes.minorupdatetitle")}
          actions={
            <>
              <Button
                text={t("actions.updatenow")}
                color={"blue"}
                onClick={updateHandler}
              />
              <IconButton size="small" onClick={closeHandler}>
                <CloseIcon />
              </IconButton>
            </>
          }
          stackBelow={userAccountType === USER_ACCOUNTTYPE.TRIAL}
        />
      )}

      <Outlet />
    </>
  );
};

export default UpdateNotificationOverlay;
