import { createSvgIcon } from "@mui/material";

const ForceLoadsIcon = createSvgIcon(
  <svg viewBox="0 0 24 24" fill="currentColor">
    <path d="M7.46963 6.20708L11.6767 10.4142L15.8839 6.20708L14.4697 4.79286L12.6767 6.58576V-1.52588e-05H10.6767V6.58576L8.88393 4.79286L7.46963 6.20708Z" />
    <path d="M19.3535 13.9611V23.9142H17.6377L13.1738 16.7843V23.9142H11.458V13.9611H13.1738L17.6514 21.1046V13.9611H19.3535Z" />
    <path d="M5.6543 13.4142V23.9142H4V13.4142H5.6543ZM10.248 16.5177L7.02832 20.0861L5.26465 21.8907L4.83398 20.4962L6.16699 18.8488L8.25879 16.5177H10.248ZM8.61426 23.9142L6.21484 20.4415L7.25391 19.2863L10.5215 23.9142H8.61426Z" />
  </svg>,
  "ForceLoads"
);

export default ForceLoadsIcon;
