import { FC, ReactNode } from "react";
import { GridCallbackDetails, GridPaginationModel } from "@mui/x-data-grid";
import {
  DataGridPremium as MuiDataGridPremium,
  GridSlotsComponentsProps,
  GridRowParams,
} from "@mui/x-data-grid-premium";

import { Typography } from "@mui/material";
import Stack from "@mui/material/Stack";

interface DataGridProProps {
  rows: any[];
  columns: any[];
  onRowClick?: any;
  onRowDoubleClick?: (row: any, params?: GridRowParams<any>) => void;
  noRowsProps?: NoProjectsOverlayProps;
  componentsProps?: GridSlotsComponentsProps;
  pageSizeOptions?: number[];
  rowCount?: number;
  paginationMode?: "server" | "client";
  onPaginationModelChange?: (
    model: GridPaginationModel,
    details: GridCallbackDetails
  ) => void;
  loading?: boolean;
  paginationModel?: { page: number; pageSize: number };
}

interface NoProjectsOverlayProps {
  text?: string;
  logo?: ReactNode;
}

const NoProjectsOverlay: FC<NoProjectsOverlayProps> = (props) => {
  const { text = "No rows", logo } = props;
  return (
    <Stack
      height="100%"
      alignItems="center"
      justifyContent="center"
      sx={{ userSelect: "none", opacity: 0.6 }}
    >
      {logo}
      <Typography mt="12px" color="text.primary">
        {text}
      </Typography>
    </Stack>
  );
};

const DataGridPro: FC<DataGridProProps> = (props) => {
  const {
    rows,
    columns,
    onRowClick,
    onRowDoubleClick,
    noRowsProps,
    componentsProps,
    pageSizeOptions,
    rowCount,
    paginationMode,
    onPaginationModelChange,
    paginationModel,
    loading,
    ...other
  } = props;

  return (
    <MuiDataGridPremium
      loading={loading}
      paginationModel={paginationModel}
      hideFooterPagination={loading}
      pagination
      onPaginationModelChange={onPaginationModelChange}
      columns={columns}
      density="compact"
      onRowClick={onRowClick ? onRowClick : () => {}}
      onRowDoubleClick={
        onRowDoubleClick
          ? (params) => {
              const { row } = params;
              onRowDoubleClick(row, params);
            }
          : () => {}
      }
      rows={rows}
      paginationMode={paginationMode ? paginationMode : "client"}
      slots={{
        noRowsOverlay: () => <NoProjectsOverlay {...noRowsProps} />,
      }}
      rowCount={rowCount}
      slotProps={componentsProps}
      pageSizeOptions={pageSizeOptions ? pageSizeOptions : []}
      sx={{
        height: "100%",
        cursor: "pointer",
        border: "none",
        "& .MuiDataGrid-withBorderColor": {
          borderColor: "text.primary",
          outline: "none !important",
        },
        "& .MuiDataGrid-cell": {
          border: "none !important",
          outline: "none !important",
        },
        "& .MuiDataGrid-cell--editing": {
          color: "red",
        },
        "& .MuiDataGrid-iconSeparator": {
          display: "none",
        },
        "& .MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
          outline: "none !important",
        },
      }}
      {...other}
    />
  );
};

export default DataGridPro;
