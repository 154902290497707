import { Stack } from "@mui/material";
import Button from "./Button";

export type ButtonListOnClick = (
  e: string,
  value?: number | boolean | string | null
) => void;
export interface ButtonListProps {
  buttons: {
    text: string;
    value?: number | boolean | string | null;
    startIcon?: any;
    endIcon?: any;
    infoButton?: boolean;
    infoContent?: any;
    selected?: boolean;
    disabled?: boolean;
    labelColor?: string;
  }[];
  onClick?: ButtonListOnClick;
  style?: any;
}

const ButtonList = ({ buttons, onClick, style }: ButtonListProps) => {
  return (
    <Stack gap={"12px"} sx={{ ...style }}>
      {buttons.map((button, idx) => (
        <Button
          key={idx}
          align="left"
          disabled={button.disabled}
          color={"grey"}
          text={button.text}
          startIcon={button.startIcon}
          endIcon={button.endIcon}
          style={{
            minWidth: "100%",
          }}
          onClick={() => {
            if (onClick) onClick(button.text, button.value);
          }}
          labelColor={button.labelColor}
          selected={button.selected}
          infoContent={button.infoContent}
        />
      ))}
    </Stack>
  );
};

export default ButtonList;
