import { CircularProgress } from "@mui/material";
import { FC } from "react";

export interface CircularProgressCustomInterface {
  style?: any;
  size?: string | number;
}

const CircularProgressCustom: FC<CircularProgressCustomInterface> = (props) => {
  const { style, size } = props;

  return (
    <CircularProgress
      size={size}
      sx={{
        color: "#ffffff",
        ...style,
      }}
    />
  );
};

export default CircularProgressCustom;
