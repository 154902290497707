import { StateCreator } from "zustand";
import { RoutingSlice } from "./routing.types";
import { ROUTE } from "../../enums/routingEnums";
import { ScaffoldingIcon, HomeIcon, ProfileIcon, ProjectsIcon, TRSIcon } from "@scaffcalc/packages-ui/src/assets/icons";

const createRoutingSlice: StateCreator<RoutingSlice, [], [], RoutingSlice> = (
  set
) => ({
  requestedRedirectPath: null,
  redirectRequestActions: {
    setRequestedRedirectPath: (path: ROUTE | null) =>
      set({ requestedRedirectPath: path }),
  },
  routingActiveRoute: "",
  routingRoutes: [
    {
      title: "Home",
      route: ROUTE.HOME,
      icon: HomeIcon,
      id: "home",
    },
    {
      title: "Scaffold",
      route: ROUTE.SCAFFOLD,
      icon: ScaffoldingIcon,
      id: "scaffold",
    },
    {
      title: "Temporary roof system",
      route: ROUTE.TRS,
      icon: TRSIcon,
      id: "trs",
    },
    {
      title: "Projects",
      route: ROUTE.PROJECTS,
      icon: ProjectsIcon,
      id: "projects",
    },
    {
      title: "Profile",
      route: ROUTE.PROFILE,
      icon: ProfileIcon,
      id: "profile",
    },
  ],
  routingActions: {
    setRoutingActiveRoute: (route) => set({ routingActiveRoute: route }),
  },
});

export default createRoutingSlice;
