import { useCallback } from "react";
import { UndoOutlined, RedoOutlined } from "@mui/icons-material";
import { Stack, ButtonBase, Divider } from "@mui/material";
import ToolTip from "./ToolTip";
import { useTranslation } from "react-i18next";

export interface UndoRedoToolBarProps {
  onClick: (id: string) => void;
  hideUndo?: boolean;
  hideRedo?: boolean;
}

const Icons = ({ onClick, hideRedo, hideUndo }: UndoRedoToolBarProps) => {
  const { t } = useTranslation();

  const handleOnClick = useCallback(
    (id: string) => {
      if (onClick) onClick(id);
    },
    [onClick]
  );

  return (
    <>
      <ToolTip content={t("tools.undo")} placement="bottom">
        <Stack>
          <ButtonBase
            disabled={hideUndo}
            id="undo"
            onClick={() => handleOnClick("undo")}
            sx={{
              borderRadius: 1,
              padding: 0.5,
              "&:hover": {
                backgroundColor: "action.hover",
              },
            }}
          >
            <UndoOutlined
              sx={{ color: hideUndo ? "text.disabled" : "text.primary" }}
            />
          </ButtonBase>
        </Stack>
      </ToolTip>
      <Divider sx={{ borderColor: "divider", borderWidth: 1, margin: 0.25 }} />
      <ToolTip content={t("tools.redo")} placement="bottom">
        <Stack>
          <ButtonBase
            disabled={hideRedo}
            id="redo"
            onClick={() => handleOnClick("redo")}
            sx={{
              borderRadius: 1,
              padding: 0.5,
              "&:hover": {
                backgroundColor: "action.hover",
              },
            }}
          >
            <RedoOutlined
              sx={{ color: hideRedo ? "text.disabled" : "text.primary" }}
            />
          </ButtonBase>
        </Stack>
      </ToolTip>
    </>
  );
};

const UndoRedoToolBar = ({
  onClick,
  hideRedo,
  hideUndo,
}: UndoRedoToolBarProps) => {
  return (
    <Stack
      direction="row"
      alignContent={"center"}
      justifyContent={"center"}
      gap={0.5}
      padding={1}
      bgcolor={"secondary.light"}
      borderRadius={2}
      boxShadow={2}
      sx={{
        userSelect: "none",
      }}
      width={"min-content"}
    >
      <Icons onClick={onClick} hideRedo={hideRedo} hideUndo={hideUndo} />
    </Stack>
  );
};

export default UndoRedoToolBar;
