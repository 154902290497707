import { Vector3Arr } from "@scaffcalc/backends-firebase";
import { Vector3 } from "three";

export const vectorInBetweenTwoVectorsByPerc = (
  startPoint: Vector3Arr,
  endPoint: Vector3Arr,
  percentage: number
) => {
  const startVector = new Vector3().fromArray(startPoint);
  const endVector = new Vector3().fromArray(endPoint);

  const direction = endVector.clone().sub(startVector);
  const directionLength = direction.length();
  const scaledDirection = direction
    .normalize()
    .multiplyScalar(directionLength * percentage);

  return startVector.clone().add(scaledDirection).toArray();
};

export const lengthBetweenTwoVectors = (
  startPoint: Vector3Arr,
  endPoint: Vector3Arr
) => {
  const startVector = new Vector3().fromArray(startPoint);
  const endVector = new Vector3().fromArray(endPoint);

  return endVector.clone().sub(startVector).length();
};

export const elementWiseAddOfTwoVectors = (a: Vector3Arr, b: Vector3Arr) => {
  if (a.length > b.length) {
    return a.map((num, idx) => num + b[idx] || 0) as Vector3Arr;
  } else if (b.length > a.length) {
    return b.map((num, idx) => num + a[idx] || 0) as Vector3Arr;
  } else {
    return a.map((num, idx) => num + b[idx]) as Vector3Arr;
  }
};

export const normalizedVectorOfTwoVectors = (
  a: Vector3Arr | Vector3,
  b: Vector3Arr | Vector3
) => {
  /** Vector holding the second last polygon point aka anchor vector */
  const aV = a instanceof Vector3 ? a : new Vector3().fromArray(a);
  const bV = b instanceof Vector3 ? b : new Vector3().fromArray(b);

  return bV.sub(aV).normalize();
};
