import { StateCreator } from "zustand";
import { LoadingSlice } from "./loading.types";

const createLoadingSlice: StateCreator<LoadingSlice, [], [], LoadingSlice> = (
  set
) => ({
  loading: false,
  loadingText: "",
  loadingActions: {
    setLoading: (loading) => set({ loading }),
    setLoadingText: (text) => set({ loadingText: text }),
  },
});

export default createLoadingSlice;
