import { FirebaseOptions } from "firebase/app";
import { FIREBASE_PROJECTID } from "./firebase.types";

export const prodConfig: FirebaseOptions & { functionsLink: string } = {
  apiKey: "AIzaSyClFe06R7zbBkKd1tBxbd_UbPkrd7TbqI8",
  authDomain: "scaffcalc-prod.firebaseapp.com",
  projectId: FIREBASE_PROJECTID.PROD,
  storageBucket: "scaffcalc-prod.appspot.com",
  messagingSenderId: "413855180052",
  appId: "1:413855180052:web:d80808f13c64b96b6b8e01",
  measurementId: "G-C53CCJJM0M",
  functionsLink: "https://europe-west3-scaffcalc-prod.cloudfunctions.net",
};

const devConfig: FirebaseOptions & { functionsLink: string } = {
  apiKey: "AIzaSyDFlVdhS3tSPaop65C7FJxISLsrAoecqjI",
  authDomain: "scaffcalc-dev.firebaseapp.com",
  projectId: FIREBASE_PROJECTID.DEV,
  storageBucket: "scaffcalc-dev.appspot.com",
  messagingSenderId: "942863032013",
  appId: "1:942863032013:web:bf6c06792e2747d7d65384",
  databaseURL:
    "https://scaffcalc-dev-default-rtdb.europe-west1.firebasedatabase.app",
  measurementId: "G-FVEN36PZ49",
  functionsLink: "https://europe-west3-scaffcalc-dev.cloudfunctions.net",
};

const localConfig: FirebaseOptions & { functionsLink: string } = {
  ...devConfig,
  functionsLink: "http://localhost:5001/scaffcalc-dev/europe-west3",
};

export const getConfig = (projectId?: FIREBASE_PROJECTID) => {
  if (projectId === FIREBASE_PROJECTID.PROD) {
    return prodConfig;
  } else if (projectId === FIREBASE_PROJECTID.DEV) {
    return devConfig;
  } else if (projectId === FIREBASE_PROJECTID.LOCAL) {
    return localConfig;
  } else {
    return devConfig;
  }
};

export default getConfig;
