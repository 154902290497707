import { createSvgIcon } from "@mui/material";

const ElevatorIcon = createSvgIcon(
  <svg viewBox="0 0 24 24" fill="currentColor">
    <path
      d="M7.2,7.2C7.2,6.5,7.8,6,8.5,6c0.7,0,1.3,0.6,1.3,1.3S9.2,8.5,8.5,8.5C7.8,8.5,7.2,7.9,7.2,7.2z M19,5v14H5V5H19 M19,3H5
	C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3 M10,18v-4h1v-2.5c0-1.1-0.9-2-2-2H8c-1.1,0-2,0.9-2,2
	V14h1v4H10 M18,11l-2.5-4L13,11H18 M13,13l2.5,4l2.5-4H13z"
    />
  </svg>,
  "Elevator"
);

export default ElevatorIcon;
