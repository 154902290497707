import { createSvgIcon } from "@mui/material";

const KeyNIcon = createSvgIcon(
  <svg viewBox="0 0 24 24">
    <path
      d="M20.4211 2H3.57895C2.70692 2 2 2.70692 2 3.57895V20.4211C2 21.2931 2.70692 22 3.57895 22H20.4211C21.2931 22 22 21.2931 22 20.4211V3.57895C22 2.70692 21.2931 2 20.4211 2Z"
      stroke="currentColor"
      fill="none"
    />
    <path
      d="M16.2407 6.7V17.3641H14.4023L9.61963 9.7249V17.3641H7.78125V6.7H9.61963L14.417 14.3538V6.7H16.2407Z"
      fill="currentColor"
    />
  </svg>,
  "KeyN"
);

export default KeyNIcon;
