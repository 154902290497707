export enum TERRAIN_TYPE {
  T0 = 0,
  T1 = 1,
  T2 = 2,
  T3 = 3,
  T4 = 4,
}

export enum TERRAIN_GROUND {
  ASPHALT = "asphalt",
  CLAY = "clay",
  CONCRETE = "concrete",
  GRASS = "grass",
  GRAVEL = "gravel",
  LOOSE_SAND = "looseSand",
  PACKED_COARSE_SAND = "packedCoarseSand",
  PACKED_FINE_SAND = "packedFineSand",
  PAVEMENT = "pavement",
  ROCK = "rock",
}
