import { createSvgIcon } from "@mui/material";

const PolygonIcon = createSvgIcon(
  <svg viewBox="0 0 24 24" fill="currentColor">
    <path d="M15.408 1.48103C15.9635 1.39332 16.5302 1.54352 16.9694 1.89482L21.6247 5.61908C21.8619 5.80885 22 6.09617 22 6.39995V15.9857C22 16.5162 21.7893 17.0249 21.4142 17.4L17.7071 21.1071C17.562 21.2521 17.3758 21.3491 17.1738 21.3847L8.67379 22.8847C8.39069 22.9347 8.09978 22.8604 7.87531 22.6808L3.25061 18.9811C2.77618 18.6015 2.5 18.0269 2.5 17.4193V7.89995C2.5 7.68358 2.57018 7.47305 2.7 7.29995L5.45662 3.62445C5.76877 3.20825 6.23081 2.93007 6.7447 2.84893L15.408 1.48103ZM15.72 3.45655L7.05662 4.82445L4.88154 7.72456L8.77156 10.8366L16.5158 9.46995L19.5029 6.48287L15.72 3.45655ZM20 8.81417L18 10.8142V17.9857L20 15.9857V8.81417ZM16 19.561V11.5919L9.5 12.7389V20.708L16 19.561ZM7.5 19.8193V12.3806L4.5 9.98058V17.4193L7.5 19.8193Z" />
  </svg>,
  "Polygon"
);

export default PolygonIcon;
