import { FC, useCallback, useMemo, useState } from "react";
import { useMediaQuery } from "usehooks-ts";
import { MEDIA_QUERY } from "../../enums/mediaQueryEnums";
import { ScaffcalcLogo } from "../../assets/graphics";
import {
  Stack,
  Avatar,
  ButtonBase,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
} from "@mui/material";
import { Menu, QuestionMark } from "@mui/icons-material";
import NavDrawer from "../NavDrawer";
import { useLocation } from "react-router-dom";
import NavBarProps from "./NavBar.types";
import { t } from "i18next";
import { ToolTip } from "@scaffcalc/packages-ui";
import Help from "../Help";

/** NavBar
 * This component is responsible for rendering the navigation part of the application.
 *
 * @param props NavBarProps
 * @returns JSX.Element
 */
const NavBar: FC<NavBarProps> = (props) => {
  /** Props */
  const { userInitials, items, onClickItem } = props;

  /** Hook: Check if user is on mobile */
  const isMobileDevice = useMediaQuery(MEDIA_QUERY.MOBILE);

  /** Hook: location */
  const location = useLocation();

  /** State: Open/closed drawer */
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  /** Callback: Open drawer when hamburger menu icon is clicked */
  const handleOnClickHamburgerMenu = useCallback(() => {
    setIsDrawerOpen(true);
  }, []);

  /** Callback: Close drawer when onClose event is fired from Drawer */
  const handleOnCloseDrawer = useCallback(() => {
    setIsDrawerOpen(false);
  }, []);

  /** Memo: match current url location with available routes */
  const rootUrlLocation = useMemo(() => {
    /** Return empty string if not items available */
    if (!items) return "";

    /** Extract routes from items */
    const routes = items.map((item) => item.route);
    /** Check if any route includes the current location path
     * - Assumption is that any of the available top routes will be present in any sub location pathname
     */
    const match = routes.find((route) => location.pathname.includes(route));

    /** Return match or empty string */
    return match || "";
  }, [location.pathname, items]);

  /** Memo: */
  const currentLocationItem = useMemo(() => {
    /** Return empty string if not items available */
    if (!items) return undefined;

    /** Check if any route includes the current location path
     * - Assumption is that any of the available top routes will be present in any sub location pathname
     */
    const match = items.find((item) => location.pathname.includes(item.route));

    /** Return match or empty string */
    return match;
  }, [location.pathname, items]);

  /** Memo: home route item */
  const homeItem = useMemo(() => {
    const match = items?.find((item) => item.id === "home");
    return match;
  }, [items]);

  /** Return: Conditional render of a navigation component */
  return isMobileDevice ? (
    <>
      <AppBar
        elevation={0}
        position="static"
        sx={{ backgroundColor: "navbar.main" }}
      >
        <Toolbar>
          <IconButton
            aria-label="hamburger-menu"
            size="large"
            edge="start"
            color="inherit"
            sx={{ mr: 2 }}
            onClick={handleOnClickHamburgerMenu}
          >
            <Menu />
          </IconButton>
          {currentLocationItem?.id && (
            <Typography
              sx={{ flexGrow: 1, textTransform: "uppercase" }}
              variant="body2"
            >
              {t(`headers.${currentLocationItem.id}`)}
            </Typography>
          )}
          {userInitials && (
            <Avatar
              sx={{
                width: "30px",
                height: "30px",
                fontSize: "16px",
                textTransform: "uppercase",
              }}
            >
              {userInitials}
            </Avatar>
          )}
        </Toolbar>
      </AppBar>
      <NavDrawer
        open={isDrawerOpen}
        items={items}
        onClickItem={(item) => {
          setIsDrawerOpen(false);
          onClickItem && onClickItem(item);
        }}
        onClose={handleOnCloseDrawer}
      />
    </>
  ) : (
    <Stack
      direction="column"
      height="100%"
      bgcolor="navbar.main"
      borderRight="1px solid"
      borderColor="action.active"
      paddingY={4}
      alignItems={"center"}
    >
      <ScaffcalcLogo
        width={"40px"}
        onClick={() => {
          onClickItem && homeItem && onClickItem(homeItem);
        }}
        style={{ marginInline: "auto" }}
      />
      <Stack
        direction="column"
        alignItems="center"
        width="100%"
        padding={2}
        gap={2}
        marginY={4}
      >
        {items &&
          items.map((item, idx) => (
            <ToolTip
              key={idx}
              content={t(`headers.${item.id}`)}
              placement="right"
            >
              <ButtonBase
                sx={{
                  padding: "8px 12px",
                  borderRadius: 2,
                  backgroundColor:
                    rootUrlLocation === item.route ? "action.selected" : "none",
                  "&:hover": {
                    backgroundColor:
                      rootUrlLocation === item.route
                        ? "action.selected"
                        : "action.hover",
                  },
                }}
                onClick={() => {
                  onClickItem && onClickItem(item);
                }}
              >
                {item.icon ? (
                  <item.icon
                    sx={{
                      width: "28px",
                      height: "28px",
                    }}
                  />
                ) : (
                  <QuestionMark />
                )}
              </ButtonBase>
            </ToolTip>
          ))}
      </Stack>
      <Stack justifyContent={"end"} flex={1}>
        <Help />
      </Stack>
    </Stack>
  );
};

export default NavBar;
