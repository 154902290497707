import {
  SCAFFOLD_PLANKORIENTATION,
  SCAFFOLD_PLANKTYPE,
  SCAFFOLD_STANDARDLENGTH,
} from "../../../enums";

export const BAYWIDTHS = {
  MM730: 0.73,
  MM1090: 1.09,
};
export const LOADINGBAYWIDTHS = {};

export const BAYLENGTHS = {
  MM730: 0.73,
  MM1090: 1.09,
  MM1400: 1.4,
  MM1570: 1.57,
  MM2070: 2.07,
  MM2570: 2.57,
  MM3070: 3.07,
  MM4140: 4.14,
};

export const LOADINGBAYLENGTHS = {};

export const PLANKWIDTHS = {
  MM190: 0.19,
  MM320: 0.32,
  MM610: 0.61,
};

export const STANDARDLENGTHS = {
  M2: SCAFFOLD_STANDARDLENGTH.M2,
};

export const CONSOLEWIDTHS = {
  MM220: 0.22,
  MM360: 0.36,
  MM500: 0.5,
  MM730: 0.73,
};

export const LOADINGBAYLOADCLASSES = {};

export const STAIRWIDTHS = {
  MM640: 0.64,
};
export const STAIRLENGTHS = {
  MM1090: 1.09,
  MM1200: 1.2,
  MM1700: 1.7,
  MM2570: 2.57,
  MM3070: 3.07,
};

export const PLANKORIENTATIONS = {
  LONGITUDINAL: SCAFFOLD_PLANKORIENTATION.LONGITUDINAL,
};

export const PLANKTYPES = {
  STEEL: SCAFFOLD_PLANKTYPE.STEEL,
  ALUMINUM: SCAFFOLD_PLANKTYPE.ALUMINUM,
  ALUMINUM_STEEL: SCAFFOLD_PLANKTYPE.ALUMINUM_STEEL,
  ALUMINUM_PLYWOOD: SCAFFOLD_PLANKTYPE.ALUMINUM_PLYWOOD,
  ALUMINUM_PLASTIC: SCAFFOLD_PLANKTYPE.ALUMINUM_PLASTIC,
};
