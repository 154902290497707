import { createSvgIcon } from "@mui/material";

const RoofHeightIcon = createSvgIcon(
  <svg viewBox="0 0 24 24" fill="currentColor">
    <path
      id="home-outline"
      d="M12.1,14.7l5.6,5V24H6.5v-4.3L12.1,14.7 M12.1,11.7l-11.2,10h3.3V24h15.6v-2.2h3.3L12.1,11.7z"
    />
    <path
      id="arrow-up-down"
      d="M11,10.2v-6L7.6,7.7L6,6.1L12.1,0l6.1,6.1l-1.6,1.6l-3.4-3.4v6L11,10.2L11,10.2z"
    />
  </svg>,
  "RoofHeight"
);

export default RoofHeightIcon;
