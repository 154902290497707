import { FC } from "react";
import { Check } from "@mui/icons-material";
import { Stack, getContrastRatio } from "@mui/material";

export interface ColorPickerProps {
  value: string;
  onChangeUserColor: (event: React.MouseEvent<HTMLDivElement>) => void;
  isMobileDevice: boolean | undefined;
}

const ColorPicker: FC<ColorPickerProps> = (props) => {
  const { value, onChangeUserColor } = props;

  const colors = [
    "#FB00FF",
    "#850003",
    "#FB0007",
    "#FFFF0B",
    "#2D6516",
    "#3B6DE2",
    "#0000FE",
    "#8500FF",
    "#FF7F00",
  ];

  return (
    <Stack
      direction="row"
      flexWrap="wrap"
      alignSelf="start"
      justifyContent="end"
    >
      {colors.map((color, idx) => (
        <div
          key={idx}
          style={{
            backgroundColor: color,
            width: 24,
            height: 24,
            borderRadius: 24,
            cursor: "pointer",
            margin: "4px",
            opacity: 1,
          }}
          id={color}
          onClick={onChangeUserColor}
        >
          {color === value && (
            <Check
              style={{
                color:
                  getContrastRatio(color, "#fff") >= 4.5 ? "white" : "black",
              }}
            />
          )}
        </div>
      ))}
    </Stack>
  );
};

export default ColorPicker;
