import {
  SCAFFOLD_PLANKORIENTATION,
  SCAFFOLD_PLANKTYPE,
  SCAFFOLD_STANDARDLENGTH,
} from "../../../enums";

export const BAYWIDTHS = {
  MM770: 0.77,
  MM1050: 1.05,
  MM1250: 1.25,
  MM1655: 1.655,
};

export const LOADINGBAYWIDTHS = {
  MM770: 0.77,
  MM1050: 1.05,
  MM1250: 1.25,
  MM1655: 1.655,
};

export const BAYLENGTHS = {
  MM3050: 3.05,
  MM3000: 3.0,
  MM2450: 2.45,
  MM1960: 1.96,
  MM1650: 1.65,
  MM1250: 1.25,
  MM1050: 1.05,
  MM720: 0.72,
  MM550: 0.55,
};

export const LOADINGBAYLENGTHS = {
  MM3050: 3.05,
  MM3000: 3.0,
  MM2450: 2.45,
  MM1960: 1.96,
  MM1650: 1.65,
  MM1250: 1.25,
  MM1050: 1.05,
  MM720: 0.72,
  MM550: 0.55,
};

export const PLANKWIDTHS = {
  MM100: 0.1,
  MM170: 0.17,
  MM230: 0.23,
  MM490: 0.49,
};

export const CONSOLEWIDTHS = {
  MM240: 0.24,
  MM300: 0.3,
  MM470: 0.47,
  MM500: 0.5,
  MM700: 0.7,
  MM720: 0.72,
  MM1200: 1.2,
};

export const LOADINGBAYLOADCLASSES = {
  LOADCLASS1: 1,
  LOADCLASS2: 2,
  LOADCLASS3: 3,
  LOADCLASS4: 4,
  LOADCLASS5: 5,
  LOADCLASS6: 6,
};

export const STAIRWIDTHS = {
  MM800: 0.8,
  MM1000: 1.0,
  MM1200: 1.2,
  MM1600: 1.6,
  MM3000: 3.0,
};

export const STAIRLENGTHS = {
  MM1050: 1.05,
  MM1650: 1.65,
  MM1960: 1.96,
};
export const STANDARDLENGTHS = {
  M2: SCAFFOLD_STANDARDLENGTH.M2,
  M3: SCAFFOLD_STANDARDLENGTH.M3,
  M4: SCAFFOLD_STANDARDLENGTH.M4,
};

export const PLANKORIENTATIONS = {
  LONGITUDINAL: SCAFFOLD_PLANKORIENTATION.LONGITUDINAL,
};

export const PLANKTYPES = {
  STEEL: SCAFFOLD_PLANKTYPE.STEEL,
  ALUMINUM: SCAFFOLD_PLANKTYPE.ALUMINUM,
  COMPOSITE: SCAFFOLD_PLANKTYPE.COMPOSITE,
};
