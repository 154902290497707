import {
  AnalysisFormData,
  CreateCompanyRequestProps,
  CreateUserRequestProps,
} from "@scaffcalc/backends-firebase";
import { ApiService, ApiServiceProvider } from "./api.types";
import { initApiService } from "./api.utils";

class Api {
  /** The authService holds the abstracted authentication service */
  public apiService: ApiService;

  /** The authService can be initialized with any AuthenticationServiceProvider but default is .FIREBASE */
  public constructor(serviceProvider = ApiServiceProvider.FIREBASE) {
    /** A helper function is used to initialize the authServive  */
    this.apiService = initApiService(serviceProvider);
  }

  public createCompany = (company: CreateCompanyRequestProps) => {
    return this.apiService.createCompany(company);
  };

  public fetchMapboxAPIKey = (token: string) => {
    return this.apiService.fetchMapboxAPIKey(token);
  };

  public getAnalysis = (data: AnalysisFormData) => {
    return this.apiService.getAnalysis(data);
  };

  public getTrsCapacity = () => {
    return this.apiService.getTrsCapacity();
  };

  public createUser = (user: Omit<CreateUserRequestProps, "creatorEmail">) => {
    return this.apiService.createUser(user);
  };

  public sendPasswordResetLink = (email: string) => {
    return this.apiService.sendPasswordResetLink(email);
  };

  public generateResetPasswordLink = (email: string, uid: string) => {
    return this.apiService.generateResetPasswordLink(email, uid);
  };

  public deactivateUser = (id: string) => {
    return this.apiService.deactivateUser(id);
  };

  public activateUser = (id: string) => {
    return this.apiService.activateUser(id);
  };

  public getUsersData = (ids: string[]) => {
    return this.apiService.getUsersData(ids);
  };
}

/** Initialize a Api object */
const api = new Api();

export { api };
export default Api;
