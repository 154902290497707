import { createSvgIcon } from "@mui/material";

const KeyCIcon = createSvgIcon(
  <svg viewBox="0 0 24 24">
    <path
      d="M20.4211 2H3.57895C2.70692 2 2 2.70692 2 3.57895V20.4211C2 21.2931 2.70692 22 3.57895 22H20.4211C21.2931 22 22 21.2931 22 20.4211V3.57895C22 2.70692 21.2931 2 20.4211 2Z"
      stroke="currentColor"
      fill="none"
    />
    <path
      d="M13.8957 14.0388H15.7268C15.6682 14.7371 15.4729 15.3596 15.1409 15.9065C14.8088 16.4485 14.3425 16.8757 13.7419 17.1882C13.1413 17.5007 12.4114 17.657 11.552 17.657C10.8928 17.657 10.2996 17.5398 9.77221 17.3054C9.24487 17.0662 8.79321 16.7292 8.41723 16.2947C8.04125 15.8552 7.75317 15.3254 7.55297 14.7053C7.35766 14.0852 7.26 13.3918 7.26 12.6252V11.739C7.26 10.9724 7.3601 10.2791 7.5603 9.65894C7.76537 9.03882 8.05834 8.50903 8.4392 8.06958C8.82006 7.62524 9.2766 7.28589 9.80883 7.05151C10.3459 6.81714 10.949 6.69995 11.6179 6.69995C12.4675 6.69995 13.1853 6.8562 13.7712 7.1687C14.3572 7.4812 14.8113 7.91333 15.1335 8.46509C15.4607 9.01685 15.6609 9.64917 15.7341 10.3621H13.9031C13.8542 9.90308 13.7468 9.51001 13.5808 9.18286C13.4197 8.85571 13.1804 8.60669 12.863 8.43579C12.5456 8.26001 12.1306 8.17212 11.6179 8.17212C11.198 8.17212 10.8318 8.25024 10.5193 8.40649C10.2068 8.56274 9.94555 8.79224 9.73559 9.09497C9.52563 9.39771 9.36694 9.77124 9.25951 10.2156C9.15697 10.655 9.10571 11.158 9.10571 11.7244V12.6252C9.10571 13.1624 9.15209 13.6506 9.24487 14.0901C9.34252 14.5247 9.48901 14.8982 9.68432 15.2107C9.88451 15.5232 10.1384 15.7649 10.446 15.9358C10.7537 16.1067 11.1223 16.1921 11.552 16.1921C12.0745 16.1921 12.4968 16.1091 12.8191 15.9431C13.1462 15.7771 13.3928 15.5354 13.5588 15.218C13.7297 14.8958 13.842 14.5027 13.8957 14.0388Z"
      fill="currentColor"
    />
  </svg>,
  "KeyCIcon"
);

export default KeyCIcon;
