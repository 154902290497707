import { FC } from "react";
import { useMediaQuery } from "usehooks-ts";
import { MEDIA_QUERY } from "../../../../web/app/src/enums/mediaQueryEnums";
import { Stack, Typography } from "@mui/material";

export interface BannerCustomInterface {
  header: string;
  actions?: any;
  stackBelow?: boolean;
}

const BannerCustom: FC<BannerCustomInterface> = (props) => {
  const { header, actions, stackBelow } = props;

  const isMobileDevice = useMediaQuery(MEDIA_QUERY.MOBILE);

  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      gap={2}
      bgcolor={"secondary.main"}
      zIndex={10000}
      borderRadius={2}
      sx={{
        position: "fixed",
        top: !isMobileDevice ? (!stackBelow ? "10px" : "70px") : null,
        bottom: isMobileDevice ? (!stackBelow ? "20px" : "110px") : null,
        left: "50%",
        transform: "translate(-50%)",
        padding: "10px",
        width: isMobileDevice ? "90%" : null,
      }}
    >
      <Typography variant="body1" ml={1}>
        {header}
      </Typography>
      {actions && actions}
    </Stack>
  );
};

export default BannerCustom;
