import { FC } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { dark } from "./themes";
import CssBaseline from "@mui/material/CssBaseline";
import ThemeManagerProps from "./ThemeManager.types";

/** Import fonts */
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

const ThemeManager: FC<ThemeManagerProps> = (props) => {
  const { children } = props;

  return (
    <ThemeProvider theme={dark}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export default ThemeManager;
