import { createSvgIcon } from "@mui/material";

const GridIcon = createSvgIcon(
  <svg viewBox="0 0 24 24" fill="currentColor">
    <path
      d="M10,4v4h4V4H10 M16,4v4h4V4H16 M16,10v4h4v-4H16 M16,16v4h4v-4H16 M14,20v-4h-4v4H14 M8,20v-4H4v4H8 M8,14v-4H4v4H8 M8,8V4
	H4v4H8 M10,14h4v-4h-4V14 M4,2h16c1.1,0,2,0.9,2,2v16c0,1.1-0.9,2-2,2H4c-1.1,0-2-0.9-2-2V4C2,2.9,2.9,2,4,2z"
    />
  </svg>,
  "Grid"
);

export default GridIcon;
